import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

// Fetch modules
export const fetchModules = createAsyncThunk(
  "quanLyChuyen/fetchModules",
  async ({ index, pageSize, idSearch, codeSearch }) => {
    const params = { index, pageSize, idSearch, codeSearch };
    const response = await getRequestParams("/modules", params);
    return response.data;
  }
);

// Fetch a module by id
export const fetchModuleById = createAsyncThunk(
  "quanLyChuyen/fetchModuleById",
  async (id) => {
    const response = await getRequest(`/modules/${id}`);
    return response.data;
  }
);

// Create a new module
export const createModule = createAsyncThunk(
  "quanLyChuyen/createModule",
  async (moduleData, { rejectWithValue }) => {
    try {
      const response = await postRequest("/modules", moduleData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a module
export const updateModule = createAsyncThunk(
  "quanLyChuyen/updateModule",
  async ({ id, moduleData }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/modules/${id}`, moduleData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Delete a module
export const deleteModule = createAsyncThunk(
  "quanLyChuyen/deleteModule",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`/modules/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get users by role enum

export const fetchUsers = createAsyncThunk(
  "quanLyChuyen/fetchUsers",
  async (roleEnumValue) => {
    const response = await getRequest(
      `/roles/getusersbyroleenum/${roleEnumValue}`
    );
    return response.data;
  }
);

const quanLyChuyenSlice = createSlice({
  name: "quanLyChuyen",
  initialState: {
    modules: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    usersEnum3: [],
    usersEnum4: [],
    detailData: null,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchModules
      .addCase(fetchModules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.modules = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //fetchModuleById
      .addCase(fetchModuleById.fulfilled, (state, action) => {
        state.detailData = action.payload.data;
      })

      //createModule
      .addCase(createModule.fulfilled, (state, action) => {
        console.log("API Create Response:", action.payload);
        state.modules.push(action.payload);
      })
      .addCase(createModule.rejected, (state, action) => {
        state.error = action.payload;
      })
      //updateModule
      .addCase(updateModule.fulfilled, (state, action) => {
        const index = state.modules.findIndex(
          (module) => module.id === action.payload.id
        );
        if (index !== -1) {
          state.modules[index] = action.payload;
        }
      })
      .addCase(updateModule.rejected, (state, action) => {
        state.error = action.payload;
      })
      //deleteModule
      .addCase(deleteModule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        state.modules = state.modules.filter(
          (module) => module.id !== action.payload.id
        );
      })
      .addCase(deleteModule.rejected, (state, action) => {
        state.error = action.payload;
      })
      //fetchUsers
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const roleEnumValue = action.meta.arg;
        if (roleEnumValue === 3) {
          state.usersEnum3 = action.payload;
        } else if (roleEnumValue === 4) {
          state.usersEnum4 = action.payload;
        }
        state.status = "succeeded";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = quanLyChuyenSlice.actions;

export default quanLyChuyenSlice.reducer;
