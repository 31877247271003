import React, { useState } from "react";
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import { Avatar, Button, Dropdown, Layout, Space, theme } from "antd";
import { Link } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu/SidebarMenu";
import { useSelector } from "react-redux";
import { btnClickSidebarMenuSelector } from "../redux/selector";
import { primaryLogo } from "../assets";
import "../layouts/Defaultlayout.css";
const { Header, Sider, Content } = Layout;
const DefaultLayout = ({ currentPage, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const sidebarMenu = useSelector(btnClickSidebarMenuSelector);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const user = useSelector((state) => state.user.user);
  const fullName = user?.fullName || "user";

  //items for dropdown
  const items = [
    {
      label: <Link to="/profile">My Profile</Link>,
      key: "0",
      style: { textAlign: "center" },
    },
    {
      label: (
        <Button onClick={() => handleLogout()} style={{ width: "100%" }}>
          Logout
        </Button>
      ),
      key: "1",
      style: { textAlign: "center" },
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    window.location.href = "/";
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
        className="sidebar"
      >
        <img
          src={primaryLogo.logo}
          className="primary-logo"
          alt="logo-awater"
        />

        <SidebarMenu></SidebarMenu>
      </Sider>
      <Layout>
        <Header
          className="fixed-header"
          style={{
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
            height: "48px", // Thay đổi chiều cao ở đây
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 48, // Thay đổi chiều rộng ở đây
              height: 48, // Thay đổi chiều cao ở đây
            }}
          />
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()} style={{ color: "black" }}>
              <Space>
                <Avatar size="large" src="" />
                Hi{fullName}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Header>

        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {sidebarMenu === null &&
          currentPage === null &&
          currentPage !== "" ? (
            <h1 className="text-welcome">
              CHÀO MỪNG BẠN ĐẾN VỚI XƯỞNG MAY CỦA CHÚNG TÔI.
            </h1>
          ) : (
            children
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
