// Import các thư viện và component cần thiết
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import endPoints from "./routes/router";
import AdminDashboard from "./pages/dashboard/AdminDashboard";

import QuanTriHeThong from "./pages/quantrihethong/QuanTriHeThong";
import QuanLyCongViec from "./pages/quanlycongviec/QuanLyCongViec";
import QuanLyChuyen from "./pages/quanlychuyen/QuanLyChuyen";
import QuanLyKho from "./pages/quanlykho/QuanLyKho";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import QuanLyUser from "./pages/quanlyuser/QuanLyUser";
import DefaultLayout from "./layouts/DefaultLayout";
import AdminLayout from "./layouts/AdminLayout";
import MaLoiTrenSanPham from "./pages/quanlysanpham/ma-loi-tren-san-pham/MaLoiTrenSanPham";
import DanhSachLoi from "./pages/quanlysanpham/danh-sach-loi/DanhSachLoi";
import QuanLySanPham from "./pages/quanlysanpham/danh-sach-san-pham/QuanLySanPham";
import DashboardLoi from "./pages/dashboard/dashboard-loi/DashboardLoi";
import DashboardChuyen from "./pages/dashboard/dashboard-chuyen/DashboardChuyen";
import QuanLyDatHang from "./pages/quanlysanpham/quan-ly-dat-hang/QuanLyDatHang";
import QAQCInfo from "./pages/quanlysanpham/thong-tin-QAQC/QAQCInfo";
import Warehousev2 from "./pages/qlkho2/Warehousev2";

const PrivateRoute = ({ path, element }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return isLoggedIn ? element : <Navigate to={endPoints.LOGIN} replace />;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={endPoints.ALL} element={<AdminLayout />}>
          <Route
            path={endPoints.DASHBOARD}
            element={<PrivateRoute element={<AdminDashboard />} />}
          />
          <Route
            path={endPoints.QUANTRIHETHONG}
            element={<PrivateRoute element={<QuanTriHeThong />} />}
          />
          <Route
            path={endPoints.DASHBOARDCHUYEN}
            element={<PrivateRoute element={<DashboardChuyen />} />}
          />
          <Route
            path={endPoints.DASHBOARDLOI}
            element={<PrivateRoute element={<DashboardLoi />} />}
          />
          <Route
            path={endPoints.QUANLYSANPHAM}
            element={<PrivateRoute element={<QuanLySanPham />} />}
          />
          <Route
            path={endPoints.QAQCInfo}
            element={<PrivateRoute element={<QAQCInfo />} />}
          />
          <Route
            path={endPoints.QUANLYCONGVIEC}
            element={<PrivateRoute element={<QuanLyCongViec />} />}
          />
          <Route
            path={endPoints.QUANLYCHUYEN}
            element={<PrivateRoute element={<QuanLyChuyen />} />}
          />
          <Route
            path={endPoints.QUANLYKHO}
            element={<PrivateRoute element={<QuanLyKho />} />}
          />
          <Route
            path={endPoints.QUANLYKHOv2}
            element={<PrivateRoute element={<Warehousev2 />} />}
          />
          <Route
            path={endPoints.MALOITRENSANPHAM}
            element={<PrivateRoute element={<MaLoiTrenSanPham />} />}
          />
          <Route
            path={endPoints.DANHSACHLOI}
            element={<PrivateRoute element={<DanhSachLoi />} />}
          />
          <Route
            path={endPoints.QUANLYDATHANG}
            element={<PrivateRoute element={<QuanLyDatHang />} />}
          />
          <Route
            path={endPoints.QUANLYUSER}
            element={<PrivateRoute element={<QuanLyUser />} />}
          />
        </Route>
        <Route path={endPoints.LOGIN} element={<Login />} />
        <Route path={endPoints.REGISTER} element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
