import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";

const FormComponent = ({ visible, onCreate, onCancel, initialValues, form }) => {

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [visible, initialValues, form]);

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật lỗi" : "Thêm mới lỗi"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
      
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label="Mã lỗi"
          rules={[{ required: true, message: "Vui lòng nhập mã lỗi!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên lỗi"
          rules={[{ required: true, message: "Vui lòng nhập tên lỗi!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả"
          rules={[{ required: true, message: "Vui lòng nhập mô tả!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
