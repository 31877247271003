import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams, putRequest } from "../../../services/api";

//fetch qaqc Info

export const fetchQAQCInfo = createAsyncThunk(
  "qaqcInfo/fetchQaqcInfo",
  async ({ index, pageSize, codeSearch, orderProductId, taskProductId }) => {
    const params = {
      index,
      pageSize,
      codeSearch,
      orderProductId,
      taskProductId,
    };
    const response = await getRequestParams("/qaproducts", params);
    return response.data;
  }
);

//fetch task and order
export const fetchTaskAndOrder = createAsyncThunk(
  "qaqcInfo/fetchTaskAndOrder",
  async ({ date }) => {
    const params = { date };
    const response = await getRequestParams(
      "/qaproducts/gettaskandorder",
      params
    );
    return response.data;
  }
);

//update QA
export const updateQA = createAsyncThunk(
  "qaqcInfo/updateQA",
  async ({ qaProductId, data }) => {
    const response = await putRequest(
      `/qaproducts/updateqaproduct?qaProductId=${qaProductId}`,
      data
    );
    return response.data;
  }
);

const qaqcInfoSlice = createSlice({
  name: "qaqcInfo",
  initialState: {
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    qaqcInfo: [],
    taskAndOrder: {
      qaTaskModels: [],
      qaOrderModels: [],
    },
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQAQCInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQAQCInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qaqcInfo = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchQAQCInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //update QA
      .addCase(updateQA.fulfilled, (state, action) => {
        const updatedQA = action.payload;
        const existingQAIndex = state.qaqcInfo.findIndex(
          (qa) => qa.id === updatedQA.id
        );
        if (existingQAIndex) {
          state.qaqcInfo[existingQAIndex] = updatedQA;
        }
      })

      //fetch task and order
      .addCase(fetchTaskAndOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskAndOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskAndOrder = action.payload.data;
      })
      .addCase(fetchTaskAndOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = qaqcInfoSlice.actions;

export default qaqcInfoSlice.reducer;
