import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, message } from "antd";
import { fetchRoles } from "../../redux/Slice/roleSlice";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/Slice/userSlice"; // Adjust the path as necessary

const { Option } = Select;

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const FormComponent = ({ visible, onCreate, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
      setLoading(true);
      fetchRoles()
        .then((data) => {
          setRoles(data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to fetch roles: ${error.toString()}`);
          setLoading(false);
        });
    }
  }, [visible, initialValues, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.id = initialValues ? initialValues.id : undefined;
        form.resetFields();
        dispatch(updateUser(values))
          .unwrap()
          .then(() => {
            message.success("Cập nhật người dùng thành công!");
            onCreate(values);
          })
          .catch((error) => {
            message.error(`Failed to fetch roles: ${error.toString()}`);
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Cập nhật người dùng" : "Thêm mới người dùng"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValues}
      >
        <Form.Item
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: true, message: "Vui lòng nhập tên đăng nhập!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Vui lòng nhập email!" },
            { type: "email", message: "Vui lòng nhập email hợp lệ!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Họ và tên"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Số điện thoại"
          rules={[{ required: true, message: "Vui lòng nhập số điện thoại!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Vai trò"
          rules={[{ required: true, message: "Vui lòng chọn vai trò!" }]}
        >
          <Select
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            placeholder="Chọn vai trò"
            loading={loading}
            allowClear
            showSearch
            options={roles.map((role) => ({
              value: role.normalizedName,
              label: role.fullName,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
