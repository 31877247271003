import React, { useState } from "react";
import {
  Dropdown,
  Menu,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  message,
  Tooltip,
} from "antd";
import {
  CaretRightOutlined,
  DownOutlined,
  FileExcelOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBangKeTonKhoSanPham,
  fetchBangNhapHang,
  fetchBangViTriKhoang,
  fetchBangXuatHang,
  fetchTongHop,
  fetchTongHopNXT,
  fetchTongHopNXTChiTiet,
  fetchTruyVet,
} from "../../redux/Slice/Warehouse/ExportExcelSlice";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";

const { MonthPicker, RangePicker } = DatePicker;

const ExcelExportDropdown = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.exportExcel);

  const [disabledFields, setDisabledFields] = useState({});
  const [tooltipVisible, setTooltipVisible] = useState({
    year: false,
    month: false,
    week: false,
    day: false,
    dayStartEnd: false,
  });

  const handleMenuClick = ({ key }) => {
    setCurrentKey(key);
    if (
      key === "summary" ||
      key === "summaryDetail" ||
      key === "nxtSummary" ||
      key === "export" ||
      key === "import"
    ) {
      setModalVisible(true);
    } else {
      handleExport(key);
    }
  };

  const handleExport = (key) => {
    if (key === "inventory") {
      dispatch(fetchBangKeTonKhoSanPham());
    } else if (key === "trace") {
      dispatch(fetchTruyVet());
    } else if (key === "locationChamber") {
      dispatch(fetchBangViTriKhoang());
    }
  };

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { dayStartEnd, ...rest } = values;
      const params = {
        ...rest,
        dayStart: dayStartEnd
          ? dayjs(dayStartEnd[0]).format("YYYY-MM-DD")
          : undefined,
        dayEnd: dayStartEnd
          ? dayjs(dayStartEnd[1]).format("YYYY-MM-DD")
          : undefined,
      };

      if (currentKey === "summary") {
        dispatch(fetchTongHop(params));
      } else if (currentKey === "summaryDetail") {
        dispatch(fetchTongHopNXTChiTiet(params));
      } else if (currentKey === "nxtSummary") {
        dispatch(fetchTongHopNXT(params));
      } else if (currentKey === "export") {
        dispatch(fetchBangXuatHang(params));
      } else if (currentKey === "import") {
        dispatch(fetchBangNhapHang(params));
      }

      setModalVisible(false);
      form.resetFields();
      setDisabledFields({});
      setTooltipVisible({
        year: false,
        month: false,
        week: false,
        day: false,
        dayStartEnd: false,
      });
    } catch (error) {
      message.error("Validation Failed: " + error);
    }
  };

  const handleFieldChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];
    const fieldValue = changedValues[fieldName];

    if (fieldValue) {
      setDisabledFields({
        year: fieldName !== "year",
        month: fieldName !== "month",
        week: fieldName !== "week",
        day: fieldName !== "day",
        dayStartEnd: fieldName !== "dayStartEnd",
      });
    } else {
      setDisabledFields({});
    }

    setTooltipVisible({
      year: false,
      month: false,
      week: false,
      day: false,
      dayStartEnd: false,
    });
  };

  const handleMouseEnter = (field) => {
    if (disabledFields[field]) {
      setTooltipVisible((prev) => ({ ...prev, [field]: true }));
    }
  };

  const handleMouseLeave = (field) => {
    setTooltipVisible((prev) => ({ ...prev, [field]: false }));
  };

  const renderTooltipField = (field, component) => (
    <Tooltip
      title="Chỉ được chọn 1 trường để in"
      visible={tooltipVisible[field]}
      destroyTooltipOnHide
    >
      <div
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={() => handleMouseLeave(field)}
      >
        {component}
      </div>
    </Tooltip>
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="inventory" icon={<RightOutlined />}>
        Bảng tồn kho sản phẩm
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="summary" icon={<RightOutlined />}>
        Bảng tổng hợp tồn kho sản phẩm
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="trace" icon={<RightOutlined />}>
        Sổ theo dõi số lượng, truy vết sản phẩm
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="summaryDetail" icon={<RightOutlined />}>
        Bảng tổng hợp xuất nhập tồn (chi tiết)
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="nxtSummary" icon={<RightOutlined />}>
        Bảng tổng hợp xuất nhập tồn
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="locationChamber" icon={<RightOutlined />}>
        Bảng vị trí khoang
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="export" icon={<RightOutlined />}>
        Bảng xuất hàng
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="import" icon={<RightOutlined />}>
        Bảng nhập hàng
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} className="green-button">
        <Button icon={<FileExcelOutlined />} loading={loading}>
          Xuất Excel <DownOutlined />
        </Button>
      </Dropdown>

      <Modal
        title="Bảng tổng hợp tồn kho sản phẩm"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
          setDisabledFields({});
          setTooltipVisible({
            year: false,
            month: false,
            week: false,
            day: false,
            dayStartEnd: false,
          });
        }}
        onOk={handleModalSubmit}
        okText="Xuất Excel"
        cancelText="Hủy"
        okButtonProps={{ className: "green-button" }}
      >
        <Form form={form} layout="vertical" onValuesChange={handleFieldChange}>
          {renderTooltipField(
            "dayStartEnd",
            <Form.Item name="dayStartEnd" label="Ngày bắt đầu - Ngày kết thúc">
              <RangePicker
                format="DD-MM-YYYY"
                placeholder={["Chọn ngày bắt đầu", "Chọn ngày kết thúc"]}
                disabled={disabledFields.dayStartEnd}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}

          {renderTooltipField(
            "day",
            <Form.Item name="day" label="Ngày">
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Chọn ngày"
                disabled={disabledFields.day}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}

          {renderTooltipField(
            "week",
            <Form.Item name="week" label="Tuần">
              <Input
                placeholder="Chọn tuần (Ví dụ: 1/2024 - tuần 1 năm 2024)"
                disabled={disabledFields.week}
              />
            </Form.Item>
          )}

          {renderTooltipField(
            "month",
            <Form.Item name="month" label="Tháng">
              <MonthPicker
                format="MM/YYYY"
                placeholder="Chọn tháng"
                disabled={disabledFields.month}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}

          {renderTooltipField(
            "year",
            <Form.Item name="year" label="Năm">
              <Input placeholder="Chọn năm" disabled={disabledFields.year} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ExcelExportDropdown;
