import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { constants } from "../../utils/constant";
import sidebarSlice from "../../redux/Slice/sidebarMenuSlice";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  BarChartOutlined,
  CloseCircleOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FileExcelOutlined,
  InsertRowBelowOutlined,
  MailOutlined,
  ProductOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";

function SidebarMenu({ onCloseDrawer, isTabletOrMobile }) {
  const dispatch = useDispatch();

  return (
    <Menu
      theme="light"
      mode="inline"
      className="custom-sidebar-menu" // Add this line to apply the custom styles
      defaultSelectedKeys={[sessionStorage.getItem("currentPage")]}
      defaultOpenKeys={[
        constants.DASHBOARD.key,
        constants.DASHBOARD_ADMIN.key,
        constants.DASHBOARD_TV.key,
        constants.DASHBOARD_CHUYEN.key,
        constants.DASHBOARD_LOI.key,
        constants.SYSTEM_MANAGEMENT.key,
        constants.PRODUCT_MANAGEMENT.key,
        constants.PRODUCT_LIST.key,
        constants.CATEGORY_LIST.key,
        constants.PRODUCT_ERROR_CODE.key,
        constants.ERROR_LIST.key,
        constants.PASS_MANAGEMENT.key,
        constants.STORAGE_MANAGEMENT.key,
        constants.USER_MANAGEMENT.key,
        constants.PRODUCT_ORDER.key,
        constants.STORE_MANAGEMENTv2.key,
      ]}
      items={[
        {
          key: constants.DASHBOARD.key,
          label: constants.DASHBOARD.label,
          icon: <DashboardOutlined />,
          children: [
            {
              key: constants.DASHBOARD_ADMIN.key,
              label: constants.DASHBOARD_ADMIN.label,
              icon: <DashboardOutlined />,
            },
            {
              key: constants.DASHBOARD_TV.key,
              label: constants.DASHBOARD_TV.label,
              icon: <DashboardOutlined />,
            },
            {
              label: constants.DASHBOARD_CHUYEN.label,
              key: constants.DASHBOARD_CHUYEN.key,
              icon: <BarChartOutlined />,
            },
            {
              label: constants.DASHBOARD_LOI.label,
              key: constants.DASHBOARD_LOI.key,
              icon: <CloseCircleOutlined />,
            },
          ],
        },

        {
          key: constants.PRODUCT_MANAGEMENT.key,
          label: constants.PRODUCT_MANAGEMENT.label,
          icon: <ProductOutlined />,
          children: [
            {
              label: constants.PRODUCT_LIST.label,
              key: constants.PRODUCT_LIST.key,
              icon: <UnorderedListOutlined />,
            },
            {
              label: constants.CATEGORY_LIST.label,
              key: constants.CATEGORY_LIST.key,
              icon: <ContainerOutlined />,
            },
            {
              label: constants.PRODUCT_ERROR_CODE.label,
              key: constants.PRODUCT_ERROR_CODE.key,
              icon: <CloseCircleOutlined />,
            },
            {
              label: constants.ERROR_LIST.label,
              key: constants.ERROR_LIST.key,
              icon: <FileExcelOutlined />,
            },
            {
              label: constants.PRODUCT_ORDER.label,
              key: constants.PRODUCT_ORDER.key,
              icon: <ShoppingOutlined />,
            },
            {
              label: constants.QAQC_INFO.label,
              key: constants.QAQC_INFO.key,
              icon: <UserOutlined />,
            },
          ],
        },
        {
          key: constants.WORK_MANAGEMENT.key,
          label: constants.WORK_MANAGEMENT.label,
          icon: <MailOutlined />,
        },
        {
          key: constants.PASS_MANAGEMENT.key,
          label: constants.PASS_MANAGEMENT.label,
          icon: <ApartmentOutlined />,
        },
        {
          key: constants.STORAGE_MANAGEMENT.key,
          label: constants.STORAGE_MANAGEMENT.label,
          icon: <AppstoreOutlined />,
        },
        {
          key: constants.STORE_MANAGEMENTv2.key,
          label: constants.STORE_MANAGEMENTv2.label,
          icon: <SettingOutlined />,
        },
        {
          key: constants.USER_MANAGEMENT.key,
          label: constants.USER_MANAGEMENT.label,
          icon: <UserOutlined />,
        },
      ]}
      onSelect={(item) => {
        // menu 1
        if (item.key === constants.DASHBOARD_ADMIN.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.DASHBOARD_ADMIN.key);
          isTabletOrMobile && onCloseDrawer();
        }
        // sub menu 1.1
        else if (item.key === constants.DASHBOARD_TV.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.DASHBOARD_TV.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.SYSTEM_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem(
            "currentPage",
            constants.SYSTEM_MANAGEMENT.key
          );
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.PRODUCT_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem(
            "currentPage",
            constants.PRODUCT_MANAGEMENT.key
          );
          isTabletOrMobile && onCloseDrawer();
        }
        // menu 2
        else if (item.key === constants.PRODUCT_LIST.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.PRODUCT_LIST.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.CATEGORY_LIST.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.CATEGORY_LIST.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.PRODUCT_ERROR_CODE.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem(
            "currentPage",
            constants.PRODUCT_ERROR_CODE.key
          );
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.ERROR_LIST.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.ERROR_LIST.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.PRODUCT_ORDER.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.PRODUCT_ORDER.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.QAQC_INFO.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.QAQC_INFO.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.WORK_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.WORK_MANAGEMENT.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.PASS_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.PASS_MANAGEMENT.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.STORAGE_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem(
            "currentPage",
            constants.STORAGE_MANAGEMENT.key
          );
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.USER_MANAGEMENT.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.USER_MANAGEMENT.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.DASHBOARD_CHUYEN.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.DASHBOARD_CHUYEN.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.DASHBOARD_LOI.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem("currentPage", constants.DASHBOARD_LOI.key);
          isTabletOrMobile && onCloseDrawer();
        } else if (item.key === constants.STORE_MANAGEMENTv2.key) {
          dispatch(sidebarSlice.actions.btnClickSidebarMenu(item.key));
          sessionStorage.setItem(
            "currentPage",
            constants.STORE_MANAGEMENTv2.key
          );
          isTabletOrMobile && onCloseDrawer();
        }
      }}
    />
  );
}

export default SidebarMenu;
