// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .recharts-wrapper {
  width: 700px !important;
} */
/* .chart-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
} */

.chart-item {
  text-align: center;
  margin: 0 auto;
}
.chart-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px); /* Two charts per row */
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard-tv/TVDashboard.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;;;;;GAKG;;AAEH;EACE,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,uBAAuB;EACnD,sBAAsB;AACxB","sourcesContent":["/* .recharts-wrapper {\n  width: 700px !important;\n} */\n/* .chart-container {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n  width: 100%;\n} */\n\n.chart-item {\n  text-align: center;\n  margin: 0 auto;\n}\n.chart-item {\n  flex: 1 1 calc(50% - 20px); /* Two charts per row */\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
