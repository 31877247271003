import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams } from "../../../services/api";

//fetch defectproducts
export const fetchDefectProducts = createAsyncThunk(
  "danhSachLoi/fetchDefectProducts",
  async ({ index, pageSize, codeSearch, defectName, categoryId, moduleId }) => {
    const params = {
      index,
      pageSize,
      codeSearch,
      defectName,
      categoryId,
      moduleId,
    };
    const response = await getRequestParams("/defectproducts", params);
    return response.data;
  }
);

//fetch module list
export const fetchModuleList = createAsyncThunk(
  "danhSachLoi/fetchModuleList",
  async ({ index, pageSize }) => {
    const params = { index, pageSize };
    const response = await getRequestParams("/modules", params);
    return response.data;
  }
);

const danhSachLoiSlice = createSlice({
  name: "danhSachLoi",
  initialState: {
    defectProducts: [],
    modules: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefectProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDefectProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.defectProducts = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchDefectProducts.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchModuleList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModuleList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.modules = action.payload.data.items;
      })
      .addCase(fetchModuleList.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setPage, setPageSize } = danhSachLoiSlice.actions;

export default danhSachLoiSlice.reducer;
