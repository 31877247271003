import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Space, Spin } from "antd";
import TableComponent from "../../../components/TableComponent";
import SearchComponent from "../../../components/SearchComponent";
import {
  fetchDefectProducts,
  fetchModuleList,
  setPage,
  setPageSize,
} from "../../../redux/Slice/danhSachLoiSlice/danhSachLoiSlice";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Import CSS của Lightbox
import { fetchCategories } from "../../../redux/Slice/productSlice/productSlice";

const DanhSachLoi = () => {
  const dispatch = useDispatch();
  const defectProducts = useSelector(
    (state) => state.danhSachLoi.defectProducts
  );
  const categories = useSelector((state) => state.quanLySanPham.categories);
  const modules = useSelector((state) => state.danhSachLoi.modules);
  const currentPage = useSelector((state) => state.danhSachLoi.currentPage);
  const pageSize = useSelector((state) => state.danhSachLoi.pageSize);
  const totalCount = useSelector((state) => state.danhSachLoi.totalCount);
  const totalPages = useSelector((state) => state.danhSachLoi.totalPages);
  const status = useSelector((state) => state.danhSachLoi.status);
  const apiImageLink = process.env.REACT_APP_IMAGE;
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
    defectName: "",
    categoryId: [],
    moduleId: [],
  });

  useEffect(() => {
    dispatch(
      fetchDefectProducts({ index: currentPage, pageSize, ...searchParams })
    );
  }, [dispatch, currentPage, pageSize, searchParams]);

  const renderImage = (text, record, index) => {
    if (record) {
      const imageUrl = `${apiImageLink}${record.image}`;
      return text ? (
        <img
          src={imageUrl}
          alt="Image"
          style={{ width: "50px", height: "50px" }}
          onClick={() => {
            setCurrentImage(imageUrl);
            setIsOpen(true);
          }}
        />
      ) : null;
    }
    return null;
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  const handleResetTable = () => {
    dispatch(fetchDefectProducts({ index: currentPage, pageSize }));
    setSelectedRowKeys([]);
  };

  const handleSearch = (searchValues) => {
    console.log("Search", searchValues);
    setSearchParams(searchValues);
    dispatch(setPage(1));
    dispatch(fetchDefectProducts({ index: 1, pageSize, searchValues }));
  };

  const dataSource = defectProducts.map((item, index) => {
    return {
      key: item.id,
      id: item.id,
      codeDefect: item.codeDefect,
      name: item.name,
      description: item.description,
      createdBy: item.createdBy,
      image: item.imgUrl,
      productName: item.productName,
      productCode: item.productCode,
      stt: index + 1,
    };
  });

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 50,
      align: "center",
    },
    {
      title: "Mã lỗi",
      dataIndex: "codeDefect",
      key: "codeDefect",
    },
    {
      title: "Tên lỗi",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Tạo bởi",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      render: (text, record) => {
        if (record && record.image) {
          return renderImage(text, record);
        }
        return null;
      },
    },
  ];

  //fetch categories
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  //fetch modules
  useEffect(() => {
    dispatch(fetchModuleList({ index: 1, pageSize: 1000 }));
  }, [dispatch]);

  const categoriesOption = categories.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const modulesOption = modules.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            onSearch={handleSearch}
            searchFields={[
              { key: "codeSearch", placeholder: "Tìm kiếm theo mã lỗi" },
              { key: "defectName", placeholder: "Tìm kiếm theo tên lỗi" },
              {
                key: "categoryId",
                placeholder: "Chọn danh mục",
                type: "select",
                options: categoriesOption,
              },
              {
                key: "moduleId",
                placeholder: "Chọn chuyền",
                type: "select",
                options: modulesOption,
              },
            ]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
            />
          </Spin>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <Space>
        <Button
          type="primary"
          style={{ backgroundColor: "#52c41a" }}
          onClick={handleResetTable}
        >
          Làm mới
        </Button>
      </Space>
    </>
  );
};

export default DanhSachLoi;
