import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams, postRequest } from "../../../services/api";

//post xuat kho

export const postXuatKho = createAsyncThunk(
  "xuatKho/postXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest("/warehouse-management/xuatkho", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//fetch chamber by productId and idFloor
export const fetchChambers = createAsyncThunk(
  "chamber/fetchChambers",
  async ({ index, pageSize, codeSearch, idFloor }) => {
    const params = { index, pageSize, codeSearch, idFloor };
    const response = await getRequestParams("/warehouse/chamber", params);
    return response.data;
  }
);

const xuatKhoSlice = createSlice({
  name: "xuatKho",
  initialState: {
    product: [],
    chambers: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 1000,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postXuatKho.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postXuatKho.fulfilled, (state, action) => {
        state.status = "success";
        state.product.push(action.payload);
      })
      .addCase(postXuatKho.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.log(action.payload);
      })
      //chamber
      .addCase(fetchChambers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChambers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chambers = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchChambers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPage, setPageSize } = xuatKhoSlice.actions;

export default xuatKhoSlice.reducer;
