// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*btn export*/

.green-button {
  background-color: #52c41a;
  border-color: #52c41a;
  color: white;
}

.green-button:hover {
  background-color: #389e0d !important;
  border-color: #389e0d !important;
  color: white !important;
}

.green-button.ant-btn-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}

.green-button.ant-btn-disabled:hover {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}

.yellow-button {
  background-color: #faad14;
  border-color: #faad14;
  color: white;
}

.yellow-button:hover {
  background-color: #d48806 !important;
  border-color: #d48806 !important;
  color: white !important;
}

.yellow-button.ant-btn-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}

`, "",{"version":3,"sources":["webpack://./src/pages/quanlykho/index.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,0BAA0B;AAC5B","sourcesContent":["/*btn export*/\n\n.green-button {\n  background-color: #52c41a;\n  border-color: #52c41a;\n  color: white;\n}\n\n.green-button:hover {\n  background-color: #389e0d !important;\n  border-color: #389e0d !important;\n  color: white !important;\n}\n\n.green-button.ant-btn-disabled {\n  background-color: #f5f5f5;\n  border-color: #d9d9d9;\n  color: rgba(0, 0, 0, 0.25);\n}\n\n.green-button.ant-btn-disabled:hover {\n  background-color: #f5f5f5;\n  border-color: #d9d9d9;\n  color: rgba(0, 0, 0, 0.25);\n}\n\n.yellow-button {\n  background-color: #faad14;\n  border-color: #faad14;\n  color: white;\n}\n\n.yellow-button:hover {\n  background-color: #d48806 !important;\n  border-color: #d48806 !important;\n  color: white !important;\n}\n\n.yellow-button.ant-btn-disabled {\n  background-color: #f5f5f5;\n  border-color: #d9d9d9;\n  color: rgba(0, 0, 0, 0.25);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
