import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetch categories
export const fetchCategories = createAsyncThunk(
  "quanLySanPham/fetchCategories",
  async () => {
    const response = await getRequest("/categories");
    return response.data;
  }
);

// Create a new category
export const createCategory = createAsyncThunk(
  "quanLyLoaiSanPham/createCategory",
  async (categoryData, { rejectWithValue }) => {
    try {
      console.log("Pending", categoryData);
      const response = await postRequest("/categories", categoryData);
      console.log("Success", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a category
export const updateCategory = createAsyncThunk(
  "quanLyLoaiSanPham/updateCategory",
  async ({ id, categoryData }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/categories/${id}`, categoryData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a category
export const deleteCategory = createAsyncThunk(
  "quanLyLoaiSanPham/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/categories?id=${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const quanLyChuyenSlice = createSlice({
  name: "quanLyLoaiSanPham",
  initialState: {
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    categories: [],
    detailData: null,
    category: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create a new category
      .addCase(createCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.status = "success";
        state.category.push(action.payload.data);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update a category
      .addCase(updateCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = "success";
        const index = state.category.findIndex(
          (category) => category.id === action.payload.id
        );
        if (index !== -1) {
          state.category[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete a category
      .addCase(deleteCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = "success";
        state.category = state.category.filter(
          (category) => category.id !== action.payload
        );
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "success";
        state.categories = action.payload.data;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPage, setPageSize } = quanLyChuyenSlice.actions;

export default quanLyChuyenSlice.reducer;
