import { Form, Input, Modal, DatePicker, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/vi"; // Import Vietnamese locale for Moment.js
import locale from "antd/es/date-picker/locale/vi_VN"; // Import Ant Design Vietnamese locale
import { fetchProducts } from "../../../redux/Slice/productSlice/productSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
const { Option } = Select;

// Set Moment.js locale to Vietnamese globally
moment.locale("vi");

const FormComponent = ({ visible, onCreate, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        const convertedValues = {
          ...initialValues,
          startAct: initialValues.startAct
            ? dayjs(initialValues.startAct)
            : null,
          endAct: initialValues.endAct ? dayjs(initialValues.endAct) : null,
          timeTransport: initialValues.timeTransport
            ? dayjs(initialValues.timeTransport)
            : null,
        };
        form.setFieldsValue(convertedValues);
      }
    }
  }, [visible, initialValues, form]);

  useEffect(() => {
    if (visible) {
      dispatch(fetchProducts({ index: 1, pageSize: 100 }));
    }
  }, [visible, dispatch]);

  const { products } = useSelector((state) => state.quanLySanPham);

  useEffect(() => {
    if (products.length > 0) {
      setProductOptions(
        products.map((product) => ({
          id: product.id,
          name: product.name,
        }))
      );
    }
  }, [products]);

  const onFinish = (values) => {
    const formatWithTimezone = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss") + "+07:00" : null;
    };

    const convertedValues = {
      ...values,
      startAct: formatWithTimezone(values.startAct),
      endAct: formatWithTimezone(values.endAct),
      timeTransport: formatWithTimezone(values.timeTransport),
    };
    onCreate(convertedValues);
  };

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật đơn hàng" : "Thêm mới đơn hàng"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={initialValues}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label="Mã đơn hàng"
          rules={[{ required: true, message: "Vui lòng nhập mã đơn hàng!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên đơn hàng"
          rules={[{ required: true, message: "Vui lòng nhập tên đơn hàng!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả"
          rules={[{ required: true, message: "Vui lòng nhập mô tả!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="totalQuantity"
          label="Số lượng tổng"
          rules={[{ required: true, message: "Vui lòng nhập số lượng tổng!" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="productId"
          label="Sản phẩm"
          rules={[{ required: true, message: "Vui lòng chọn sản phẩm!" }]}
        >
          <Select
            placeholder="Chọn sản phẩm"
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {productOptions.map((product) => (
              <Option key={product.id} value={product.id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="startAct"
          label="Ngày bắt đầu dự kiến"
          rules={[{ required: true, message: "Vui lòng chọn ngày bắt đầu!" }]}
        >
          <DatePicker
            locale={locale}
            style={{ width: "100%" }}
            showTime={false}
            value={form.getFieldValue("startAct") || null} // Use form value or null for empty state
          />
        </Form.Item>
        <Form.Item
          name="endAct"
          label="Ngày kết thúc dự kiến"
          rules={[{ required: true, message: "Vui lòng chọn ngày kết thúc!" }]}
        >
          <DatePicker
            locale={locale}
            style={{ width: "100%" }}
            showTime={false}
            value={form.getFieldValue("endAct") || null} // Use form value or null for empty state
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
