import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

// fetch order products
export const fetchOrderProducts = createAsyncThunk(
  "quanLyDatHang/fetchOrderProducts",
  async ({
    index,
    pageSize,
    codeSearch,
    productName,
    productCode,
    orderName,
  }) => {
    const params = {
      index,
      pageSize,
      codeSearch,
      productName,
      productCode,
      orderName,
    };
    const response = await getRequestParams("/orderproducts", params);
    return response.data;
  }
);

// post order product
export const postOrderProduct = createAsyncThunk(
  "quanLyDatHang/postOrderProduct",
  async (orderProductData, { rejectWithValue }) => {
    try {
      const response = await postRequest("/orderproducts", orderProductData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update order product
export const updateOrderProduct = createAsyncThunk(
  "quanLyDatHang/updateOrderProduct",
  async ({ id, orderProductData }, { rejectWithValue }) => {
    try {
      const response = await putRequest(
        `/orderproducts/${id}`,
        orderProductData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete order product
export const deleteOrderProduct = createAsyncThunk(
  "quanLyDatHang/deleteOrderProduct",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/orderproducts/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// view detail
export const viewDetail = createAsyncThunk(
  "quanLyDatHang/viewDetail",
  async (orderProductId) => {
    const response = await getRequestParams(
      `/orderproducts/mergeorder?orderProductId=${orderProductId}`
    );
    return response.data;
  }
);

const quanLyDatHangSlice = createSlice({
  name: "quanLyDatHang",
  initialState: {
    status: "idle",
    error: null,
    orderProducts: [],
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    detailOrderProduct: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch order products
      .addCase(fetchOrderProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data) {
          state.orderProducts = action.payload.data.items || [];
          state.totalCount = action.payload.data.totalCount || 0;
          state.totalPages = action.payload.data.totalPages || 0;
        } else {
          state.orderProducts = [];
          state.totalCount = 0;
          state.totalPages = 0;
        }
      })
      .addCase(fetchOrderProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // post order product
      .addCase(postOrderProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postOrderProduct.fulfilled, (state, action) => {
        state.orderProducts.push(action.payload);
      })
      .addCase(postOrderProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // update order product
      .addCase(updateOrderProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOrderProduct.fulfilled, (state, action) => {
        const { id, orderProductData } = action.payload;
        const existingOrderProduct = state.orderProducts.find(
          (orderProduct) => orderProduct.id === id
        );
        if (existingOrderProduct) {
          Object.assign(existingOrderProduct, orderProductData);
        }
      })
      .addCase(updateOrderProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // delete order product
      .addCase(deleteOrderProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteOrderProduct.fulfilled, (state, action) => {
        const id = action.payload;
        state.orderProducts = state.orderProducts.filter(
          (orderProduct) => orderProduct.id !== id
        );
      })
      .addCase(deleteOrderProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // view detail
      .addCase(viewDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(viewDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.detailOrderProduct = action.payload.data;
      })
      .addCase(viewDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = quanLyDatHangSlice.actions;

export default quanLyDatHangSlice.reducer;
