import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest, getRequestParams } from "../../../services/api";
import axios from "axios";
import moment from "moment";
//OWE chart
export const fetchOWEChart = createAsyncThunk(
  "dashBoardTV/fetchOWEChart",
  async (searchParams) => {
    console.log("searchParams2", searchParams);

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      console.log("Date");
      const currentDate = moment().format("YYYY/MM/DD");
      console.log(currentDate);

      if (searchParams.dateValue != null) {
        const moduleIdParams = searchParams.idChuyen
          .map((id) => `moduleId=${id}`)
          .join("&");
        const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
        const url = `${API_URL}dashboards/getowe?${moduleIdParams}&date=${formattedDate}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return response.data;
      } else {
        const url = `${API_URL}dashboards/getowe?moduleId=${searchParams.idChuyen}&date=${currentDate}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return response.data;
      }
    } catch (error) {
      console.error("Error fetching OWE chart data:", error);
      throw error;
    }
  }
);

export const fetchOWETivi = createAsyncThunk(
  "dashBoardTV/fetchOWETivi",
  async (searchParams) => {
    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const currentDate = moment().format("YYYY/MM/DD");
      console.log(currentDate);

      let url = `${API_URL}dashboards/getowetivi`;

      if (searchParams.dateValue != null) {
        const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");

        if (Array.isArray(searchParams.idChuyen)) {
          const moduleIdParams = searchParams.idChuyen
            .map((id) => `moduleId=${id}`)
            .join("&");
          url = `${url}?${moduleIdParams}&date=${formattedDate}`;
        } else {
          url = `${url}?moduleId=${searchParams.idChuyen}&date=${formattedDate}`;
        }
      } else {
        if (Array.isArray(searchParams.idChuyen)) {
          const moduleIdParams = searchParams.idChuyen
            .map((id) => `moduleId=${id}`)
            .join("&");
          url = `${url}?${moduleIdParams}&date=${currentDate}`;
        } else {
          url = `${url}?moduleId=${searchParams.idChuyen}&date=${currentDate}`;
        }
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('owe',response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching OWE TVI data:", error);
      throw error;
    }
  }
);

export const fetchAverageProductivity = createAsyncThunk(
  "dashBoardTV/fetchAverageProductivity",
  async (searchParams) => {
    console.log("fetchAverageProductivity", searchParams);
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    const moduleIdParams = searchParams.idChuyen
      .map((id) => `moduleId=${id}`)
      .join("&");
    console.log("formattedDate", formattedDate);
    console.log(
      `/dashboards/getaverageproductivity?${moduleIdParams}&date=${formattedDate}`
    );

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const url =
        searchParams.idChuyen === null || formattedDate === undefined
          ? `${API_URL}dashboards/getaverageproductivity`
          : `${API_URL}dashboards/getaverageproductivity?${moduleIdParams}&date=${formattedDate}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Data fetchAverageProductivity", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching Average Productivity data:", error);
      throw error;
    }
  }
);

// fetchQualityChart
export const fetchQualityChartTivi = createAsyncThunk(
  "dashBoardTV/fetchQualityChart",
  async (searchParams) => {
    console.log("fetchGetQuality", searchParams);
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    let moduleIdParams = "";

    if (Array.isArray(searchParams.idChuyen)) {
      moduleIdParams = searchParams.idChuyen
        .map((id) => `moduleId=${id}`)
        .join("&");
    } else {
      moduleIdParams = `moduleId=${searchParams.idChuyen}`;
    }

    console.log("formattedDate", formattedDate);
    console.log(
      `dashboards/getqualitytivi?${moduleIdParams}&date=${formattedDate}`
    );

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const url =
        searchParams.idChuyen === null || formattedDate === undefined
          ? `${API_URL}dashboards/getqualitytivi`
          : `${API_URL}dashboards/getqualitytivi?${moduleIdParams}&date=${formattedDate}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Data fetchQualityChart", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching Quality Chart data:", error);
      throw error;
    }
  }
);

// fetchProductivity
export const fetchProductivityTivi = createAsyncThunk(
  "dashBoardTV/fetchProductivity",
  async (searchParams) => {
    console.log("fetchProductivity", searchParams);
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    let moduleIdParams = "";

    if (Array.isArray(searchParams.idChuyen)) {
      moduleIdParams = searchParams.idChuyen
        .map((id) => `moduleId=${id}`)
        .join("&");
    } else {
      moduleIdParams = `moduleId=${searchParams.idChuyen}`;
    }

    console.log("formattedDate", formattedDate);
    console.log(
      `/dashboards/getproductivitytivi?moduleId=${searchParams.idChuyen}&date=${formattedDate}`
    );

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const url =
        !Array.isArray(searchParams.idChuyen) || formattedDate === undefined
          ? `${API_URL}dashboards/getproductivitytivi?moduleId=${searchParams.idChuyen}&date=${formattedDate}`
          : `${API_URL}dashboards/getproductivitytivi?moduleId=${searchParams.idChuyen}&date=${formattedDate}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Data getproductivitytivi", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching getproductivitytivi data:", error);
      throw error;
    }
  }
);

// Fetch Daily Defect Code Percent
export const fetchDailyDefectCodePercent = createAsyncThunk(
  "dashBoardTV/fetchDailyDefectCodePercent",
  async (searchParams) => {
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;
    console.log("formattedDate", formattedDate);
    try {
      let combinedDateTime = formattedDate;
      console.log("time1", searchParams.time);
      if (searchParams.time != null) {
        const time = searchParams.time;
        console.log("time", time);
        const dateTimeString = `${formattedDate}&hour=${time}`;
        console.log("dateTimeString", dateTimeString);
        combinedDateTime = dateTimeString;
        console.log("combinedDateTime", combinedDateTime);
      }

      const url =
        searchParams.idChuyen === null
          ? `${API_URL}dashboards/getdailydefectcodepercenttivi`
          : `${API_URL}dashboards/getdailydefectcodepercenttivi?moduleId=${searchParams.idChuyen}&date=${combinedDateTime}`;
      console.log("url", url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Data fetchDailyDefectCodePercent", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching Daily Defect Code Percent data:", error);
      throw error;
    }
  }
);

// export const fetchHourHasDefectProduct1 = createAsyncThunk(
//   "dashBoardTV/fetchHourHasDefectProduct",
//   async ({ index, pageSize }) => {
//     const response = await getRequestParams(
//       "dashboards/gethourhasdefectproduct?moduleId=6532f5392a7f4ca383a2f51c939ced5f&date=2024-06-30"
//     );
//     console.log("Time picker respone", response.data);
//     return response.data;
//   }
// );

export const fetchHourHasDefectProduct = createAsyncThunk(
  "dashBoardTV/fetchHourHasDefectProduct",
  async (params) => {
    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;
    const formattedDate = params?.date?.format("YYYY-MM-DD");
    try {
      const url = `${API_URL}dashboards/gethourhasdefectproduct?moduleId=${params.idChuyen}&date=${formattedDate}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Time picker respone", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching Average Productivity data:", error);
      throw error;
    }
  }
);

const dashBoardTVSlice = createSlice({
  name: "dashBoardTV",
  initialState: {
    status: "idle",
    error: null,
    dataOWEChart: [],
    statusOWEChartTV: "idle",
    errorOWEChartTV: null,
    dataOWEChartTV: [],
    statusProductivityChart: "idle",
    errorProductivityChart: null,
    dataProductivityChart: [],
    statusQualityChart: "idle",
    errorQualityChart: null,
    dataQualityChart: [],
    statusFetchProductivity: "idle",
    errorFetchProductivity: null,
    dataFetchProductivity: [],
    statusDailyDefectCodePercent: "idle",
    errorDailyDefectCodePercent: null,
    dataDailyDefectCodePercent: [],
    statusHourHasDefectProduct: "idle",
    errorHourHasDefectProduct: null,
    dataHourHasDefectProduct: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch OWE chart
      .addCase(fetchOWEChart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOWEChart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataOWEChart = action.payload.data;
      })
      .addCase(fetchOWEChart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOWETivi.pending, (state) => {
        state.statusOWEChartTV = "loading";
      })
      .addCase(fetchOWETivi.fulfilled, (state, action) => {
        state.statusOWEChartTV = "succeeded";
        state.dataOWEChartTV = action.payload.data;
      })
      .addCase(fetchOWETivi.rejected, (state, action) => {
        state.statusOWEChartTV = "failed";
        state.errorOWEChartTV = action.error.message;
      })
      // fetch average productivity chart
      .addCase(fetchAverageProductivity.pending, (state) => {
        state.statusProductivityChart = "loading";
      })
      .addCase(fetchAverageProductivity.fulfilled, (state, action) => {
        state.statusProductivityChart = "succeeded";
        state.dataProductivityChart = action.payload.data;
      })
      .addCase(fetchAverageProductivity.rejected, (state, action) => {
        state.statusProductivityChart = "failed";
        state.errorProductivityChart = action.error.message;
      })
      // fetch quality chart
      .addCase(fetchQualityChartTivi.pending, (state) => {
        state.statusQualityChart = "loading";
      })
      .addCase(fetchQualityChartTivi.fulfilled, (state, action) => {
        state.statusQualityChart = "succeeded";
        state.dataQualityChart = action.payload.data;
      })
      .addCase(fetchQualityChartTivi.rejected, (state, action) => {
        state.statusQualityChart = "failed";
        state.errorQualityChart = action.error.message;
      })
      // fetch productivity chart
      .addCase(fetchProductivityTivi.pending, (state) => {
        state.statusFetchProductivity = "loading";
      })
      .addCase(fetchProductivityTivi.fulfilled, (state, action) => {
        state.statusFetchProductivity = "succeeded";
        state.dataFetchProductivity = action.payload.data;
      })
      .addCase(fetchProductivityTivi.rejected, (state, action) => {
        state.statusFetchProductivity = "failed";
        state.errorFetchProductivity = action.error.message;
      })
      // fetch daily defect code percent
      .addCase(fetchDailyDefectCodePercent.pending, (state) => {
        state.statusDailyDefectCodePercent = "loading";
      })
      .addCase(fetchDailyDefectCodePercent.fulfilled, (state, action) => {
        state.statusDailyDefectCodePercent = "succeeded";
        state.dataDailyDefectCodePercent = action.payload.data;
      })
      .addCase(fetchDailyDefectCodePercent.rejected, (state, action) => {
        state.statusDailyDefectCodePercent = "failed";
        state.errorDailyDefectCodePercent = action.error.message;
      })
      .addCase(fetchHourHasDefectProduct.pending, (state) => {
        state.statusHourHasDefectProduct = "loading";
      })
      .addCase(fetchHourHasDefectProduct.fulfilled, (state, action) => {
        state.statusHourHasDefectProduct = "succeeded";
        state.dataHourHasDefectProduct = action.payload.data;
      })
      .addCase(fetchHourHasDefectProduct.rejected, (state, action) => {
        state.statusHourHasDefectProduct = "failed";
        state.errorHourHasDefectProduct = action.error.message;
      });
  },
});

export default dashBoardTVSlice.reducer;
