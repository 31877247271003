import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getRequest,
  getRequestParams,
  postRequest,
  putRequestParams,
} from "../../../services/api";

//fetch list of warehouse

export const fetchWarehouse = createAsyncThunk(
  "warehousev2/fetchWarehouse",
  async () => {
    const response = await getRequest("/warehouse-management");
    return response.data;
  }
);

//fetching products
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ index, pageSize, codeSearch }) => {
    const params = { index, pageSize, codeSearch };
    const response = await getRequestParams("/products", params);
    return response.data;
  }
);

//post product into Warehouse
export const postProductIntoWarehouse = createAsyncThunk(
  "products/postProductIntoWarehouse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest("/warehouse-management/nhapkho", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// fetch chamber by id
export const fetchChamberById = createAsyncThunk(
  "chamber/fetchChamberById",
  async ({ chamberProductId, index, pageSize, nameProduct, codeProduct }) => {
    const params = { index, pageSize, nameProduct, codeProduct };
    const response = await getRequestParams(
      `/warehouses/${chamberProductId}/product`,
      params
    );
    return response.data;
  }
);

//update product
export const updateProduct = createAsyncThunk(
  "viewProductDetail/updateProduct",
  async (
    { ChamberId, ItemPerBox, ProductId, productIdNew },
    { rejectWithValue }
  ) => {
    try {
      const params = { ChamberId, ItemPerBox, ProductId, productIdNew };
      const response = await putRequestParams(
        "/warehouses/productinchamber",
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//move product to another chamber
export const moveProduct = createAsyncThunk(
  "viewProductDetail/moveProduct",
  async (
    { chamberId, productId, chamberIdNew, ItemPerBox },
    { rejectWithValue }
  ) => {
    try {
      const params = { chamberId, productId, chamberIdNew, ItemPerBox };
      const response = await putRequestParams(
        "/warehouses/moveproductinotherchamber",
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post xuat kho

export const postXuatKho = createAsyncThunk(
  "xuatKho/postXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest("/warehouse-management/xuatkho", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const warehousev2Slice = createSlice({
  name: "warehousev2",
  initialState: {
    error: null,
    status: "idle",
    warehouse: [],
    products: [],
    product: [],
    chambersDetail: [],
    // Products pagination
    currentPageProduct: 1,
    pageSizeProduct: 20,
    totalCountProduct: 0,
    // Chambers detail pagination
    currentPageDetail: 1,
    pageSizeDetail: 1,
    totalCountDetail: 0,
  },
  reducers: {
    // Product pagination
    setPageProduct: (state, action) => {
      state.currentPageProduct = action.payload;
    },
    setPageSizeProduct: (state, action) => {
      state.pageSizeProduct = action.payload;
    },
    // Chamber detail pagination
    setPageChamberDetail: (state, action) => {
      state.currentPageDetail = action.payload;
    },
    setPageSizeChamberDetail: (state, action) => {
      state.pageSizeDetail = action.payload;
    },
    clearChamberDetail: (state) => {
      state.chambersDetail = [];
      state.currentPageDetail = 1;
      state.pageSizeDetail = 10;
      state.totalCountDetail = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.warehouse = Array.isArray(action.payload.data)
          ? action.payload.data
          : [];
      })
      .addCase(fetchWarehouse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // fetch products
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data.items;
        state.totalCountProduct = action.payload.data.total;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // post product into warehouse
      .addCase(postProductIntoWarehouse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postProductIntoWarehouse.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products.push(action.payload);
      })
      .addCase(postProductIntoWarehouse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // fetch chamber by id
      .addCase(fetchChamberById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChamberById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chambersDetail = action.payload.data.items;
        state.totalCountDetail = action.payload.data.totalCount;
      })
      .addCase(fetchChamberById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //update product
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //move product
      .addCase(moveProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(moveProduct.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(moveProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //post xuat kho
      .addCase(postXuatKho.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postXuatKho.fulfilled, (state, action) => {
        state.status = "success";
        state.product.push(action.payload);
      })
      .addCase(postXuatKho.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.log(action.payload);
      });
  },
});

export const {
  setPageProduct,
  setPageSizeProduct,
  setPageChamberDetail,
  setPageSizeChamberDetail,
  clearChamberDetail,
} = warehousev2Slice.actions;

export default warehousev2Slice.reducer;
