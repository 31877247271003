import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetch categories
export const fetchCategories = createAsyncThunk(
  "quanLySanPham/fetchCategories",
  async () => {
    const response = await getRequest("/categories");
    return response.data;
  }
);

// Fetch products
export const fetchProducts = createAsyncThunk(
  "quanLySanPham/fetchProducts",
  async ({
    index,
    pageSize,
    searchByName,
    searchByCode,
    searchByCategoryId,
  }) => {
    const params = {
      index,
      pageSize,
      searchByName,
      searchByCategoryId,
      searchByCode,
    };
    const response = await getRequestParams("/products", params);
    return response.data;
  }
);

// Fetch a product by id
export const fetchProductById = createAsyncThunk(
  "quanLySanPham/fetchProductById",
  async (id) => {
    const response = await getRequest(`/products/${id}`);
    return response.data;
  }
);

// Create a new product
export const createProduct = createAsyncThunk(
  "quanLySanPham/createProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await postRequest("/products", productData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update a product
export const updateProduct = createAsyncThunk(
  "quanLySanPham/updateProduct",
  async ({ id, productData }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/products/${id}`, productData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a product
export const deleteProduct = createAsyncThunk(
  "quanLySanPham/deleteProduct",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/products/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const quanLyChuyenSlice = createSlice({
  name: "quanLySanPham",
  initialState: {
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    products: [],
    categories: [],
    detailData: null,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch products
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "success";
        state.products = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch a product by id
      .addCase(fetchProductById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.status = "success";
        state.detailData = action.payload.data;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Create a new product
      .addCase(createProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update a product
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "success";
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete a product
      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = "success";
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "success";
        state.categories = action.payload.data;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPage, setPageSize } = quanLyChuyenSlice.actions;

export default quanLyChuyenSlice.reducer;
