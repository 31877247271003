import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ReferenceLine,
  LabelList,
  Label,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  Collapse,
  Space,
  Select,
  DatePicker,
  Alert,
  message,
} from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/vi_VN";
import * as XLSX from "xlsx";
import "dayjs/locale/vi";
import "../AdminDashboard.css";
import "./DashboardChuyen.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend as ChartLegend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOWEChart,
  fetchQualityChart,
  fetchProductivity,
  fetchTaskProductByDayandModule,
} from "../../../redux/Slice/dashBoardChuyen/dashBoardChuyenSlice";
import { fetchModules } from "../../../redux/Slice/dashboardTong/dashboardTongSlice";
import { StackedChart } from "../../../components/StackedChart/StackedChart";
ChartJS.register(ArcElement, Tooltip, ChartLegend, ChartDataLabels);
const { Option } = Select;

const DashboardChuyen = () => {
  const dispatch = useDispatch();
  const [chartsToShow, setChartsToShow] = useState([]);
  const [searchParams, setSearchParams] = useState({
    idChuyen: [],
    dateValue: null,
    timeValue: null,
    chartValue: null,
    taskValue: null,
  });
  const [idChuyenError, setIdChuyenError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  //(2)Owe
  const dataOWEChart = useSelector(
    (state) => state.dashBoardChuyen.dataOWEChart
  );

  //(3) biểu đồ năng suất (sản lượng) || (4) biểu đồ năng suất (%)
  const productivityData = useSelector(
    (state) => state.dashBoardChuyen.dataFetchProductivity
  );

  //(5) Biểu đồ chất lượng sản phẩm (số lượng)
  const dataQualityChart = useSelector(
    (state) => state.dashBoardChuyen.dataQualityChart || []
  );

  const modules = useSelector((state) => state.dashboardTong.modules);

  const taskProductByDay = useSelector(
    (state) => state.dashBoardChuyen.taskProductByDay
  );

  //fetch modules, tasks
  useEffect(() => {
    dispatch(fetchModules({ index: 1, pageSize: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedDate && searchParams.idChuyen.length > 0) {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const moduleIdParams = searchParams.idChuyen.join("&moduleId=");
      dispatch(
        fetchTaskProductByDayandModule({
          date: formattedDate,
          moduleId: moduleIdParams,
        })
      );
    }
  }, [selectedDate, searchParams.idChuyen, dispatch]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTask(null); // Reset task selection when date changes
    setSearchParams((prev) => ({
      ...prev,
      dateValue: date,
    }));
  };

  const handleTaskChange = (taskId) => {
    setSelectedTask(taskId);
    setSearchParams((prev) => ({
      ...prev,
      taskValue: taskId,
    }));
  };
  //chọn chart
  const chartMapping = {
    "Biểu đồ OWE (Số lượng)": 1,
    "Biểu đồ OWE (%)": 2,
    "Biểu đồ năng suất (Số lượng)": 3,
    "Biểu đồ năng suất (%)": 4,
    "Biểu đồ chất lượng sản phẩm (Số lượng)": 5,

    "Tất cả": [1, 2, 3, 4, 5],
  };

  const getChartsToShow = (chartValue) => {
    return chartMapping[chartValue] || chartMapping["Biểu đồ OWE (%)"];
  };

  const onSearch = () => {
    const charts = getChartsToShow(searchParams?.chartValue);

    if (!searchParams.idChuyen.length || !searchParams.dateValue) {
      setIdChuyenError(
        !searchParams.idChuyen.length
          ? "Bạn chưa chọn chuyền!"
          : "Bạn chưa chọn ngày!"
      );
      return;
    }
    const params = { ...searchParams };
    if (params.taskValue === "Tất cả") {
      delete params.taskValue;
    }

    const moduleIdString = params.idChuyen.join("&moduleId=");
    const finalParams = {
      ...params,
      moduleId: moduleIdString,
    };

    dispatch(fetchOWEChart(finalParams));
    dispatch(fetchQualityChart(finalParams));
    dispatch(fetchProductivity(finalParams));

    setChartsToShow(Array.isArray(charts) ? charts : [charts]);
  };

  //clear search
  const onClear = () => {
    setSearchParams({
      idChuyen: [],
      dateValue: null,
      timeValue: null,
      chartValue: null,
      taskValue: null,
    });
    setSelectedDate(null);
    setSelectedTask(null);
    setIdChuyenError(null);
    setChartsToShow([]);
  };

  //get bar color
  const getBarColor = (entry) =>
    entry.quantity >= productivityData[0]?.taskProductsDetail[0].target
      ? "green"
      : "red";

  const getBarColor2 = (entry) =>
    entry.percentQuantity >= productivityData[0]?.taskProductsDetail[0].target
      ? "green"
      : "red";

  const convertOweFrameToProductionData = (oweFrame) => {
    const targetValue = dataOWEChart[0]?.taskProductsDetail[0].owe;

    return oweFrame.map((item) => {
      const hour = item.time;
      const resultOWE = parseFloat(item?.resultOWE?.toFixed(2));
      const percentage = `${resultOWE.toFixed(2)}%`;
      return {
        name: `${hour}`,
        value: resultOWE,
        target: 200,
        fill: resultOWE >= targetValue ? "green" : "red",
        percentage,
      };
    });
  };

  const allProductionData =
    dataOWEChart?.map((item) => ({
      moduleName: item.moduleName,
      productionData: convertOweFrameToProductionData(item.oweFrame),
    })) || [];

  const handleChange = (key, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div>
      <div>
        <Collapse defaultActiveKey={["7"]} size="small">
          <Collapse.Panel key="7" header="Tìm kiếm">
            <Space align="center">
              <Select
                mode="multiple"
                placeholder="Chọn Chuyền"
                style={{ width: 200 }}
                value={searchParams.idChuyen}
                onChange={(value) => handleChange("idChuyen", value)}
                className={idChuyenError ? "select-error" : ""}
              >
                {modules.map((module) => (
                  <Option key={module.id} value={module.id}>
                    {module.name}
                  </Option>
                ))}
              </Select>

              <DatePicker
                placeholder="Chọn ngày"
                style={{ width: 150 }}
                value={selectedDate}
                locale={locale}
                onChange={handleDateChange}
                format="DD-MM-YYYY"
              />
              <Select
                value={selectedTask}
                onChange={handleTaskChange}
                disabled={searchParams.idChuyen.length === 0 || !selectedDate}
                placeholder="Chọn Công việc"
                style={{ width: 200, marginLeft: 10 }}
              >
                <Option value="Tất cả">Tất cả</Option>
                {taskProductByDay.map((task) => (
                  <Option key={task.id} value={task.id}>
                    {task.code}
                  </Option>
                ))}
              </Select>

              <Select
                placeholder="Chọn biểu đồ"
                style={{ width: 300 }}
                value={searchParams.chartValue}
                onChange={(value) => handleChange("chartValue", value)}
              >
                <Option value="Biểu đồ OWE (%)">Biểu đồ OWE (%)</Option>
                <Option value="Biểu đồ năng suất (Số lượng)">
                  Biểu đồ năng suất (Số lượng)
                </Option>
                <Option value="Biểu đồ năng suất (%)">
                  Biểu đồ năng suất (%)
                </Option>
                <Option value="Biểu đồ chất lượng sản phẩm (Số lượng)">
                  Biểu đồ chất lượng sản phẩm (Số lượng)
                </Option>

                <Option value="Tất cả">Tất cả</Option>
              </Select>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => onSearch(searchParams)}
              >
                Hiển thị
              </Button>
              <Button
                type="default"
                danger
                icon={<CloseOutlined />}
                onClick={onClear}
              >
                Hủy bỏ
              </Button>
            </Space>
            {idChuyenError && <Alert type="error" message={idChuyenError} />}
          </Collapse.Panel>
        </Collapse>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            {chartsToShow.includes(2) &&
              dataOWEChart?.length > 0 &&
              allProductionData.map((productionDataItem, index) => {
                const maxProductionValue = Math.ceil(
                  Math.max(
                    ...productionDataItem.productionData.map(
                      (item) => item.value
                    )
                  )
                );

                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                  >
                    <h3>Biểu đồ OWE {productionDataItem.moduleName}(%)</h3>
                    <div className="chart-legend">
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "green" }}
                        ></div>
                        <span>Đã đạt</span>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "red" }}
                        ></div>
                        <span>Chưa đạt</span>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: 300,
                        paddingRight: 300,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <BarChart
                        width={1000}
                        height={350}
                        data={productionDataItem.productionData}
                        margin={{ top: 5, right: 100, left: 100, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, maxProductionValue + 50]} />
                        <RechartsTooltip />
                        {dataOWEChart.map((chart, chartIndex) =>
                          chart.taskProductsDetail?.map((item, index) => (
                            <ReferenceLine
                              key={`${chartIndex}-${index}`}
                              y={item?.owe}
                              stroke="black"
                              strokeDasharray="2 2"
                              strokeWidth={1}
                            >
                              <Label
                                value={`Mục tiêu: ${item.owe}`}
                                position={index === 0 ? "left" : "right"}
                                fill={index === 0 ? "#18857D" : "black"}
                                fontWeight="bold"
                                dy={-10}
                                dx={index === 0 ? -25 : 3}
                              />
                              <Label
                                value={`(${item.dateStart} - ${item.dateEnd})`}
                                position={index === 0 ? "left" : "right"}
                                fill={index === 0 ? "#18857D" : "black"}
                                fontWeight="bold"
                                dy={10}
                                dx={index === 0 ? -25 : 3}
                              />
                            </ReferenceLine>
                          ))
                        )}
                        <Bar
                          dataKey="value"
                          fill={(entry) => entry.fill}
                          barSize={40}
                        >
                          <LabelList dataKey="percentage" position="top" />
                        </Bar>
                      </BarChart>
                    </div>
                    <div className="info-panel">
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ marginTop: "0", marginBottom: "0" }}>
                          Biểu đồ OWE
                        </h1>
                        <h2>{dataOWEChart[index]?.moduleName}</h2>
                      </div>
                      <div className="info-header">
                        <div className="info-title">Tên sản phẩm</div>
                        <div className="info-value">
                          {dataOWEChart[index]?.products?.categoryName}
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-cell">
                          <div className="info-subtitle">SAM của sản phẩm</div>
                          <div className="info-detail">
                            {dataOWEChart[index]?.products?.sam}
                          </div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">
                            OWE trung bình hiện tại
                          </div>
                          <div className="info-detail">
                            {parseFloat(
                              dataOWEChart[index]?.avgOWE?.toFixed(1)
                            )}
                            %
                          </div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">
                            Số lượng nhân công hiện tại
                          </div>
                          <div className="info-detail">
                            {dataOWEChart[index]?.totalNumOfStaff}
                          </div>
                        </div>
                      </div>
                      <div className="info-footer">
                        <div className="info-box">
                          <div className="info-box-title">
                            Số lượng hàng đạt
                          </div>
                          <div className="info-box-value">
                            {dataOWEChart[index]?.totalQuantityGood}
                          </div>
                        </div>
                        <div className="info-box">
                          <div className="info-box-title">
                            Số lượng hiện tại
                          </div>
                          <div className="info-box-value">
                            {dataOWEChart[index]?.currentQuantity}
                          </div>
                        </div>
                        <div className="info-box">
                          <div className="info-box-title">
                            Thời gian làm việc thực tế
                          </div>
                          <div className="info-box-value">
                            {dataOWEChart[index]?.currentWorkingHour}/
                            {dataOWEChart[index]?.totalWorkingHour}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>

          <div style={{ width: "60%", textAlign: "center", margin: "0 auto" }}>
            {chartsToShow.includes(5) && (
              <div>
                {dataQualityChart.map((data, index) => (
                  <div key={index} style={{ marginBottom: "20px" }}>
                    <h3>Biểu đồ chất lượng sản phẩm (số lượng)</h3>
                    <StackedChart dataQualityChart={data} />
                    <div
                      style={{
                        width: "100%",
                        border: " 1px solid #ccc",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ marginTop: "0", marginBottom: "0" }}>
                          Biểu đồ chất lượng sản phẩm
                        </h1>
                        <h2>{data.products.name}</h2>
                      </div>
                      <div className="info-header">
                        <div className="info-title">Tên sản phẩm</div>
                        <div className="info-value">
                          {data.products.categoryName}
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-cell">
                          <div className="info-subtitle">Tổng kiểm</div>
                          <div className="info-detail">{data.totalQA}</div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">Tổng lỗi</div>
                          <div className="info-detail">{data.totalBad}</div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">Tổng đạt</div>
                          <div className="info-detail">{data.totalGood}</div>
                        </div>
                      </div>
                      <div className="info-footer">
                        <div className="info-box">
                          <div className="info-box-title">Tổng Sản Lượng</div>
                          <div className="info-box-value">
                            {data.totalQuantity}
                          </div>
                        </div>
                        <div className="info-box">
                          <div className="info-box-title">
                            Mục Tiêu Tỷ lệ chất lượng
                          </div>
                          <div className="info-box-value">
                            {data.targetQuality}%
                          </div>
                        </div>
                        <div className="info-box">
                          <div className="info-box-title">
                            Tỷ lệ chất lượng hiện tại
                          </div>
                          <div className="info-box-value">
                            {parseFloat(data.currentQuality?.toFixed(1))}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* (4) Biểu đồ năng suất (%) */}
          <>
            {chartsToShow.includes(4) &&
              productivityData.length > 0 &&
              productivityData.map((item, index) => {
                let maxPercent = 0;
                item.details.forEach((entry) => {
                  if (entry.percentQuantity > maxPercent) {
                    maxPercent = entry.percentQuantity;
                  }
                });

                // Tính toán giá trị YAxis mới
                let yAxisMax = Math.ceil(maxPercent) + 50;

                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                  >
                    <h3>Biểu đồ năng suất (%)</h3>
                    <div className="chart-legend">
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "green" }}
                        ></div>
                        <span>Đã đạt target</span>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "red" }}
                        ></div>
                        <span>Chưa đạt target</span>
                      </div>
                    </div>
                    <div style={{ paddingLeft: 300, paddingRight: 300 }}>
                      <BarChart
                        width={1000}
                        height={350}
                        data={item?.details}
                        margin={{ top: 5, bottom: 5, right: 120, left: 80 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <RechartsTooltip />
                        <XAxis dataKey="time" />
                        <YAxis domain={[0, yAxisMax]} />
                        <Tooltip />
                        <ReferenceLine
                          y={item.percentTarget}
                          stroke="black"
                          strokeDasharray="2 2"
                          strokeWidth={1}
                        >
                          <Label
                            value={`Mục tiêu: ${item.percentTarget}%`}
                            position={index === 0 ? "left" : "right"}
                            fill={index === 0 ? "#18857D" : "black"}
                            fontWeight="bold"
                            dy={-10}
                            dx={index === 0 ? -25 : 3}
                          />
                          <Label
                            value={`(${item.taskProductsDetail[0].dateStart} - ${item.taskProductsDetail[0].dateEnd})`}
                            position={index === 0 ? "left" : "right"}
                            fill={index === 0 ? "#18857D" : "black"}
                            fontWeight="bold"
                            dy={10}
                            dx={index === 0 ? -25 : 3}
                          />
                        </ReferenceLine>
                        <Bar
                          dataKey="percentQuantity"
                          fill="#8884d8"
                          barSize={40}
                        >
                          {item?.details.map((entry, index) => (
                            <>
                              <Cell
                                key={`cell-${index}`}
                                fill={getBarColor2(entry)}
                              />
                              <LabelList
                                dataKey="percentQuantity"
                                position="top"
                                formatter={(value) => `${value}%`}
                              />
                            </>
                          ))}
                        </Bar>
                      </BarChart>
                    </div>

                    <div className="info-panel">
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ marginTop: "0", marginBottom: "0" }}>
                          Biểu đồ Tỷ lệ năng Suất Sản lượng
                        </h1>
                        <h2>{item?.products.name}</h2>
                      </div>
                      <div className="info-header">
                        <div className="info-title">Tên sản phẩm</div>
                        <div className="info-value">
                          {item?.products.categoryName}
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-cell">
                          <div className="info-subtitle">
                            Mục tiêu trong ngày
                          </div>
                          <div className="info-detail">
                            {item?.percentTarget}%
                          </div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">Mục tiêu hiện tại</div>
                          <div className="info-detail">
                            {item?.currentTargetPercent}%
                          </div>
                        </div>
                        <div className="info-cell">
                          <div className="info-subtitle">Sản xuất hiện tại</div>
                          <div className="info-detail">
                            {item?.currentQuantityPercent}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>

          {/* (3) Biểu đồ năng suất (Số lượng) */}
          <>
            {chartsToShow.includes(3) &&
              productivityData.length > 0 &&
              productivityData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  <h3>Biểu đồ năng suất (Số lượng)</h3>
                  <div className="chart-legend">
                    <div className="legend-item">
                      <div
                        className="legend-color"
                        style={{ backgroundColor: "green" }}
                      ></div>
                      <span>Đã đạt target</span>
                    </div>
                    <div className="legend-item">
                      <div
                        className="legend-color"
                        style={{ backgroundColor: "red" }}
                      ></div>
                      <span>Chưa đạt target</span>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 300, paddingRight: 300 }}>
                    <BarChart
                      width={1000}
                      height={350}
                      data={item?.details}
                      margin={{ top: 5, bottom: 5, right: 120, left: 80 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis />
                      <Tooltip />
                      <RechartsTooltip />
                      {item.taskProductsDetail?.map((item, index) => (
                        <ReferenceLine
                          key={`${index}`}
                          y={item?.target}
                          stroke="black"
                          strokeDasharray="2 2"
                          strokeWidth={1}
                        >
                          <Label
                            value={`Mục tiêu: ${item.target}`}
                            position={index === 0 ? "left" : "right"}
                            fill={index === 0 ? "#18857D" : "black"}
                            fontWeight="bold"
                            dy={-10}
                            dx={index === 0 ? -25 : 3}
                          />
                          <Label
                            value={`(${item.dateStart} - ${item.dateEnd})`}
                            position={index === 0 ? "left" : "right"}
                            fill={index === 0 ? "#18857D" : "black"}
                            fontWeight="bold"
                            dy={10}
                            dx={index === 0 ? -25 : 3}
                          />
                        </ReferenceLine>
                      ))}
                      <Bar dataKey="quantity" fill="#8884d8" barSize={40}>
                        {item?.details.map((entry, index) => (
                          <>
                            <Cell
                              key={`cell-${index}`}
                              fill={getBarColor(entry)}
                            />
                            <LabelList dataKey="quantity" position="top" />
                          </>
                        ))}
                      </Bar>
                    </BarChart>
                  </div>

                  <div className="info-panel">
                    <div style={{ textAlign: "center" }}>
                      <h1 style={{ marginTop: "0", marginBottom: "0" }}>
                        Biểu đồ Năng Suất Sản lượng
                      </h1>
                      <h2>{item?.products.name}</h2>
                    </div>
                    <div className="info-header">
                      <div className="info-title">Tên sản phẩm</div>
                      <div className="info-value">
                        {item?.products.categoryName}
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info-cell">
                        <div className="info-subtitle">Mục tiêu trong ngày</div>
                        <div className="info-detail">{item?.dayTarget}</div>
                      </div>
                      <div className="info-cell">
                        <div className="info-subtitle">Mục tiêu hiện tại</div>
                        <div className="info-detail">{item?.currentTarget}</div>
                      </div>
                      <div className="info-cell">
                        <div className="info-subtitle">
                          Tổng sản lượng hiện tại
                        </div>
                        <div className="info-detail">
                          {item?.currentQuantity}
                        </div>
                      </div>
                    </div>
                    <div className="info-footer">
                      <div className="info-box">
                        <div className="info-box-title">Mục tiêu theo giờ</div>
                        <div className="info-box-value">{item?.hourTarget}</div>
                      </div>
                      <div className="info-box">
                        <div className="info-box-title">
                          Sản lượng theo giờ hiện tại
                        </div>
                        <div className="info-box-value">
                          {item?.hourQuantity}
                        </div>
                      </div>
                      <div className="info-box">
                        <div className="info-box-title">
                          Thời gian làm việc thực tế
                        </div>
                        <div className="info-box-value">
                          {item?.currentWorkingHour}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>

          {/* {chartsToShow.includes(6) && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "40px",
                }}
              >
                <div style={{ width: "200px", height: "200px" }}>
                  <Doughnut data={donutData} options={options} />
                </div>
                <CustomLegend legendData={legendData} />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DashboardChuyen;
