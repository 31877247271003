// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.controls {
  margin-bottom: 20px;
}

.warehouse-content {
  overflow-x: auto;
  white-space: nowrap;
}

.row-container {
  margin-bottom: 20px;
}

.floor-container {
  display: flex;
  flex-direction: column;
}

.floor-card {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.chamber-grid {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc((100% - (24 * 8px)) / 25);
  text-align: center;
  box-sizing: border-box;
  min-width: 65px;
  max-width: 65px;
  border-radius: 10% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/qlkho2/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,QAAQ;EACR,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAwC;EAAxC,cAAwC;EAAxC,0CAAwC;EACxC,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".controls {\n  margin-bottom: 20px;\n}\n\n.warehouse-content {\n  overflow-x: auto;\n  white-space: nowrap;\n}\n\n.row-container {\n  margin-bottom: 20px;\n}\n\n.floor-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.floor-card {\n  display: flex;\n  justify-content: flex-start;\n  gap: 8px;\n  overflow-x: auto;\n  white-space: nowrap;\n  width: 100%;\n}\n\n.chamber-grid {\n  flex: 0 0 calc((100% - (24 * 8px)) / 25);\n  text-align: center;\n  box-sizing: border-box;\n  min-width: 65px;\n  max-width: 65px;\n  border-radius: 10% !important;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-right: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
