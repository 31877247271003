import React, { useEffect } from "react";
import { Modal, Form, Input, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/Slice/Warehouse/nhapSanPhamVaoKhoSlice";
import {
  updateProduct,
  fetchChamberById,
} from "../../redux/Slice/Warehouse/viewProductDetailSlice";

const { Option } = Select;

const UpdateProductModal = ({
  visible,
  onCancel,
  product,
  chamberId,
  currentPage,
  pageSize,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.nhapSanPhamVaoKho.products);

  useEffect(() => {
    if (visible) {
      dispatch(fetchProducts({ index: 1, pageSize: 1000, codeSearch: "" }));
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if (product && visible) {
      form.setFieldsValue({
        productName: product.name,
        productId: product.id,
        itemPerBox: product.itemPerBox,
        productIdNew: null,
      });
    }
  }, [product, visible, form]);

  const handleSubmit = (values) => {
    dispatch(
      updateProduct({
        ChamberId: product.chamberId,
        ItemPerBox: values.itemPerBox,
        ProductId: values.productId,
        productIdNew: values.productIdNew,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Cập nhật sản phẩm thành công");
        dispatch(
          fetchChamberById({
            chamberProductId: chamberId,
            index: currentPage,
            pageSize,
          })
        );
        onCancel();
        form.resetFields();
      })
      .catch((error) => {});
  };

  return (
    <Modal
      visible={visible}
      centered
      title="Cập nhật sản phẩm"
      width={600}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.submit();
      }}
      okText="Xác nhận"
      cancelText="Hủy"
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        labelAlign="left"
      >
        <Form.Item
          label="Tên sản phẩm hiện tại"
          name="productName"
          rules={[
            { required: true, message: "Tên sản phẩm hiện tại là bắt buộc" },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="productId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Số sản phẩm mỗi thùng"
          name="itemPerBox"
          rules={[
            { required: true, message: "Số sản phẩm mỗi thùng là bắt buộc" },
          ]}
        >
          <Input type="number" disabled />
        </Form.Item>
        <Form.Item
          label="Sản phẩm mới"
          name="productIdNew"
          rules={[{ required: true, message: "Vui lòng chọn sản phẩm mới" }]}
        >
          <Select
            placeholder="Chọn sản phẩm mới"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {products
              .filter((prod) => prod.id !== product.id)
              .map((prod) => (
                <Option key={prod.id} value={prod.id}>
                  {prod.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProductModal;
