import { Modal, Form, Input, Button, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";

const ModalForm = ({
  visible,
  onSubmit,
  onCancel,
  initialValues,
  currentStep,
  parentName,
}) => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log("Form Values Submitted:", values); // Debugging log
    form.resetFields();
    onSubmit(values);
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      console.log("Initial Values Set:", initialValues); // Debugging log
    }
  }, [initialValues, form]);

  const getLabel = (field) => {
    switch (currentStep) {
      case 0:
        return field === "code" ? "Mã Dãy" : "Tên Dãy";
      case 1:
        return field === "code" ? "Mã Tầng" : "Tên Tầng";
      case 2:
        return field === "code" ? "Mã Khoang" : "Tên Khoang";
      default:
        return field === "code" ? "Mã" : "Tên";
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật thông tin" : "Thêm mới"}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
        >
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {initialValues ? "Cập nhật" : "Thêm mới"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label={getLabel("code")}
          rules={[{ required: true, message: "Vui lòng nhập mã" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label={getLabel("name")}
          rules={[{ required: true, message: "Vui lòng nhập tên" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả"
          rules={[{ required: true, message: "Vui lòng nhập mô tả" }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="capacity"
          label="Sức chứa"
          rules={[{ required: true, message: "Vui lòng nhập sức chứa" }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        {currentStep > 0 && (
          <Form.Item label={`Tên ${currentStep === 1 ? "Dãy" : "Tầng"}`}>
            <Input value={parentName} disabled />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ModalForm;
