import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";

export const fetchBangKeTonKhoSanPham = createAsyncThunk(
  "exportExcel/fetchBangKeTonKhoSanPham",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/khoangtong`,
        { responseType: "blob" }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTruyVet = createAsyncThunk(
  "exportExcel/fetchTruyVet",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/truyvet`,
        { responseType: "blob" }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTongHop = createAsyncThunk(
  "exportExcel/fetchTongHop",
  async (params, { rejectWithValue }) => {
    try {
      const formattedParams = {
        ...params,
        month: params.month ? dayjs(params.month).format("MM/YYYY") : undefined,
        day: params.day ? dayjs(params.day).format("YYYY-MM-DD") : undefined,
        dayStart: params.dayStart
          ? dayjs(params.dayStart).format("YYYY-MM-DD")
          : undefined,
        dayEnd: params.dayEnd
          ? dayjs(params.dayEnd).format("YYYY-MM-DD")
          : undefined,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/tonghop`,
        {
          params: formattedParams,
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//bảng tổng hợp nhập xuất tồn (chi tiết)

export const fetchTongHopNXTChiTiet = createAsyncThunk(
  "exportExcel/fetchTongHopNXTChiTiet",
  async (params, { rejectWithValue }) => {
    try {
      const formattedParams = {
        ...params,
        month: params.month ? dayjs(params.month).format("MM/YYYY") : undefined,
        day: params.day ? dayjs(params.day).format("YYYY-MM-DD") : undefined,
        dayStart: params.dayStart
          ? dayjs(params.dayStart).format("YYYY-MM-DD")
          : undefined,
        dayEnd: params.dayEnd
          ? dayjs(params.dayEnd).format("YYYY-MM-DD")
          : undefined,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/bangtonghopnxtchitiet`,
        {
          params: formattedParams,
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//bảng tổng hợp nhập xuất tồn
export const fetchTongHopNXT = createAsyncThunk(
  "exportExcel/fetchTongHopNXT",
  async (params, { rejectWithValue }) => {
    try {
      const formattedParams = {
        ...params,
        month: params.month ? dayjs(params.month).format("MM/YYYY") : undefined,
        day: params.day ? dayjs(params.day).format("YYYY-MM-DD") : undefined,
        dayStart: params.dayStart
          ? dayjs(params.dayStart).format("YYYY-MM-DD")
          : undefined,
        dayEnd: params.dayEnd
          ? dayjs(params.dayEnd).format("YYYY-MM-DD")
          : undefined,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/bangtonghopnxt`,
        {
          params: formattedParams,
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//bảng vị trí khoang
export const fetchBangViTriKhoang = createAsyncThunk(
  "exportExcel/fetchBangViTriKhoang",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/vitrikhoang`,
        { responseType: "blob" }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// bangxuathang
export const fetchBangXuatHang = createAsyncThunk(
  "exportExcel/fetchBangXuatHang",
  async (params, { rejectWithValue }) => {
    try {
      const formattedParams = {
        ...params,
        month: params.month ? dayjs(params.month).format("MM/YYYY") : undefined,
        day: params.day ? dayjs(params.day).format("YYYY-MM-DD") : undefined,
        dayStart: params.dayStart
          ? dayjs(params.dayStart).format("YYYY-MM-DD")
          : undefined,
        dayEnd: params.dayEnd
          ? dayjs(params.dayEnd).format("YYYY-MM-DD")
          : undefined,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/xuathang`,
        { responseType: "blob", params: formattedParams }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// bangnhaphang
export const fetchBangNhapHang = createAsyncThunk(
  "exportExcel/fetchBangNhapHang",
  async (params, { rejectWithValue }) => {
    try {
      const formattedParams = {
        ...params,
        month: params.month ? dayjs(params.month).format("MM/YYYY") : undefined,
        day: params.day ? dayjs(params.day).format("YYYY-MM-DD") : undefined,
        dayStart: params.dayStart
          ? dayjs(params.dayStart).format("YYYY-MM-DD")
          : undefined,
        dayEnd: params.dayEnd
          ? dayjs(params.dayEnd).format("YYYY-MM-DD")
          : undefined,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}excelexport/nhaphang`,
        { params: formattedParams, responseType: "blob" }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ExportExcelSlice = createSlice({
  name: "exportExcel",
  initialState: {
    khoangTong: [],
    truyVet: [],
    tongHop: [],
    tongHopNXTChiTiet: [],
    tongHopNXT: [],
    vitriKhoang: [],
    xuatHang: [],
    nhapHang: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //bảng kê tồn kho sản phẩm
      .addCase(fetchBangKeTonKhoSanPham.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBangKeTonKhoSanPham.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "KhoangTong.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchBangKeTonKhoSanPham.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng truy vết
      .addCase(fetchTruyVet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTruyVet.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TruyVet.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchTruyVet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng tổng hợp
      .addCase(fetchTongHop.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTongHop.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TongHop.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchTongHop.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng tổng hợp nhập xuất tồn (chi tiết)
      .addCase(fetchTongHopNXTChiTiet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTongHopNXTChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TongHopNXTChiTiet.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchTongHopNXTChiTiet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng tổng hợp nhập xuất tồn
      .addCase(fetchTongHopNXT.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTongHopNXT.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TongHopNXT.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchTongHopNXT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng vị trí khoang
      .addCase(fetchBangViTriKhoang.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBangViTriKhoang.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ViTriKhoang.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchBangViTriKhoang.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng xuất hàng
      .addCase(fetchBangXuatHang.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBangXuatHang.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "XuatHang.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchBangXuatHang.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //bảng nhập hàng
      .addCase(fetchBangNhapHang.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBangNhapHang.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NhapHang.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .addCase(fetchBangNhapHang.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ExportExcelSlice.reducer;
