import React, { useState } from "react";
import TableComponent from "../../components/TableComponent";
import SearchComponent from "../../components/SearchComponent";

const QuanTriHeThong = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];
  return (
    <div>
      <div>
        <SearchComponent />
      </div>
      <div style={{ marginTop: "5px" }}>
        <TableComponent selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} dataSource={dataSource} columns={columns} />
      </div>
    </div>
  );
};

export default QuanTriHeThong;
