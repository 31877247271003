import { getRequest } from '../../services/api';

export const fetchRoles = () => {
  return getRequest('roles')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching roles:', error);
      return [];
    });
};
