const endPoints = {
  ALL: "/home",
  // DASHBOARD: "/dashboard",
  // QUANTRIHETHONG: "/he-thong",
  // QUANLYSANPHAM: "/san-pham",
  // QUANLYCHUYEN: "/chuyen",
  // QUANLYCONGVIEC: "/cong-viec",
  // QUANLYKHO: "/kho",
  // QUANLYUSER:"/user",
  LOGIN: "",
  REGISTER: "/register",
  MALOITRENSANPHAM: "san-pham/ma-loi-tren-san-pham",
  DANHSACHLOI: "san-pham/danh-sach-loi",
  // QUANLYDATHANG: "san-pham/quan-ly-dat-hang"
};
export default endPoints;
