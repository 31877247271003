import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined, ControlOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/Slice/loginSlice"; 
import "./index.css";
import sidebarSlice from "../../redux/Slice/sidebarMenuSlice";
import { constants } from "../../utils/constant";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isLoggedIn, error } = useSelector((state) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(sidebarSlice.actions.btnClickSidebarMenu(constants.DASHBOARD_ADMIN.key));
      sessionStorage.setItem("currentPage", constants.DASHBOARD_ADMIN.key);
      navigate("/home");
    }
  }, [isLoggedIn, navigate]);

  const onFinish = (values) => {
    dispatch(login({ username: values.username, password: values.password }));
  };

  return (
    <div className="login-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <div className="login-logo">
          <ControlOutlined style={{ fontSize: "40px" }} />
        </div>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link className="login-form-forgot" to="">
            Forgot password?
          </Link>
        </Form.Item>


        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={isLoading}
          >
            Log in
          </Button>
          {/* <div className="register-link">
            Chưa có tài khoản? <Link to="/register">Đăng kí ngay</Link>
          </div> */}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;