import { Button, Form, Popconfirm, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDefectCode,
  fetchDefectCodes,
  postDefectCode,
  setPage,
  setPageSize,
  updateDefectCode,
} from "../../../redux/Slice/maLoiTrenSanPhamSlice/maLoiTrenSanPhamSlice";
import FormComponent from "./MLTSPForm";
import SearchComponent from "../../../components/SearchComponent";

const MaLoiTrenSanPham = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const defectCodes = useSelector(
    (state) => state.maLoiTrenSanPham.defectCodes
  );
  const currentPage = useSelector(
    (state) => state.maLoiTrenSanPham.currentPage
  );
  const pageSize = useSelector((state) => state.maLoiTrenSanPham.pageSize);
  const totalCount = useSelector((state) => state.maLoiTrenSanPham.totalCount);
  const status = useSelector((state) => state.maLoiTrenSanPham.status);

  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchKey, setSearchKey] = useState(0);
  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
  });

  // Fetch data
  useEffect(() => {
    dispatch(
      fetchDefectCodes({ index: currentPage, pageSize, ...searchParams })
    );
  }, [dispatch, currentPage, pageSize, searchParams]);

  //open modal
  const handleAdd = () => {
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  //open modal with data to edit
  const handleEdit = () => {
    const record = defectCodes.find((item) => item.id === selectedRowKeys[0]);
    setEditingRecord(record);
    setIsModalVisible(true);
  };

  //delete data
  const handleDelete = () => {
    dispatch(deleteDefectCode(selectedRowKeys[0]))
      .unwrap()
      .then(() => {
        message.success("Xóa mã lỗi thành công!");
        dispatch(
          fetchDefectCodes({ index: currentPage, pageSize, ...searchParams })
        );
      })
      .catch((error) => {});
  };

  //create or update data
  const handleCreate = (values) => {
    setLoading(true);
    const handleError = (response) => {
      if (response.error || response.payload.errorCode) {
      }
    };

    if (editingRecord) {
      dispatch(
        updateDefectCode({ id: editingRecord.id, defectCodeData: values })
      )
        .unwrap()
        .then(() => {
          message.success("Cập nhật thành công!");
          setIsModalVisible(false);
          form.resetFields();
          dispatch(
            fetchDefectCodes({
              index: currentPage,
              pageSize,
              ...searchParams,
            })
          );
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(postDefectCode(values))
        .unwrap()
        .then(() => {
          form.resetFields();
          message.success("Thêm mới thành công!");
          setIsModalVisible(false);
          dispatch(
            fetchDefectCodes({
              index: currentPage,
              pageSize,
              ...searchParams,
            })
          );
          resetSearch();
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  //close modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedRowKeys([]);
  };

  //pagination
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  //reset table
  const handleResetTable = () => {
    setSearchParams({
      codeSearch: "",
      nameSearch: "",
    });
    dispatch(fetchDefectCodes({ index: 1, pageSize }));
    setSelectedRowKeys([]);
  };

  //search
  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };

  // Reset search parameters and fetch all products after create new
  const resetSearch = () => {
    setSearchParams({
      codeSearch: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  //table
  const dataSource = defectCodes.map((item, index) => ({
    key: item.id,
    id: item.id,
    code: item.code,
    name: item.name,
    description: item.description,
    stt: index + 1,
  }));

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 50,
      align: "center",
    },
    {
      title: "Mã lỗi",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên lỗi",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            key={searchKey}
            onSearch={handleSearch}
            searchFields={[{ key: "codeSearch", placeholder: "Mã lỗi" }]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
            />
          </Spin>
        </div>
        <Space>
          <Button
            type="primary"
            onClick={handleResetTable}
            style={{ backgroundColor: "#52c41a" }}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Thêm lỗi
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa mã lỗi không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa
            </Button>
          </Popconfirm>
        </Space>

        <FormComponent
          form={form}
          visible={isModalVisible}
          onCreate={handleCreate}
          onCancel={handleCancel}
          initialValues={editingRecord} // Pass initialValues only for editing
        />
      </div>
    </>
  );
};

export default MaLoiTrenSanPham;
