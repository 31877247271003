import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetching chambers

export const fetchChambers = createAsyncThunk(
  "chamber/fetchChambers",
  async ({ index, pageSize, codeSearch, nameProduct, idFloor }) => {
    const params = { index, pageSize, codeSearch, nameProduct, idFloor };
    console.log("Params sent to API:", params);

    const response = await getRequestParams(
      "/warehouse-management/chambers",
      params
    );
    return response.data;
  }
);

//post chamber
export const postChamber = createAsyncThunk(
  "chamber/postChamber",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest(
        "/warehouse-management/chambers",
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update chamber
export const updateChamber = createAsyncThunk(
  "chamber/updateChamber",
  async ({ idChamber, data }, { rejectWithValue }) => {
    try {
      const response = await putRequest(
        `/warehouse-management/chambers/${idChamber}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete chamber
export const deleteChamber = createAsyncThunk(
  "chamber/deleteChamber",
  async (idChamber, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(
        `/warehouse-management/chambers/${idChamber}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// fetch chamber by id
export const fetchChamberById = createAsyncThunk(
  "chamber/fetchChamberById",
  async ({ chamberProductId, index, pageSize, nameProduct, codeProduct }) => {
    const params = { index, pageSize, nameProduct, codeProduct };
    const response = await getRequestParams(
      `/warehouses/${chamberProductId}/product`,
      params
    );
    return response.data;
  }
);

const chambersSlice = createSlice({
  name: "chambers",
  initialState: {
    currentPage: 1,
    pageSize: 15,
    totalCount: 0,
    chambers: [],
    loading: false,
    error: null,
    status: "idle",
  },
  reducers: {
    setPageChamber: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSizeChamber: (state, action) => {
      state.pageSize = action.payload;
    },
    clearChambers: (state) => {
      state.chambers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChambers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChambers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chambers = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchChambers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(postChamber.fulfilled, (state, action) => {
        state.chambers.push(action.payload);
      })
      .addCase(updateChamber.fulfilled, (state, action) => {
        const index = state.chambers.findIndex(
          (chamber) => chamber.id === action.payload.id
        );
        if (index !== -1) {
          state.chambers[index] = action.payload;
        }
      })
      .addCase(deleteChamber.fulfilled, (state, action) => {
        state.chambers = state.chambers.filter(
          (chamber) => chamber.id !== action.payload
        );
      })
      .addCase(deleteChamber.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});
export const { setPageChamber, setPageSizeChamber, clearChambers } =
  chambersSlice.actions;

export default chambersSlice.reducer;
