import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Space, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "../../../components/SearchComponent";
import TableComponent from "../../../components/TableComponent";
import FormComponent from "./DSLSPForm";
import {
  createCategory,
  deleteCategory,
  updateCategory,
  fetchCategories,
  setPage,
} from "../../../redux/Slice/categorySlice/categorySlice";

const normalizeString = (str) => {
  return str
    ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    : "";
};

const QuanLyLoaiSanPham = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.quanLySanPham.status);
  const categories = useSelector((state) => state.quanLySanPham.categories);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState(0);

  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
    nameSearch: "",
  });

  // Fetch category list
  useEffect(() => {
    fetchCategoryList();
  }, [dispatch, searchParams]);

  const fetchCategoryList = () => {
    dispatch(fetchCategories({ ...searchParams }));
  };

  // Open modal to add a new category
  const handleAdd = () => {
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  // Select a category to edit
  const handleEdit = () => {
    const record = categories.find((item) => item.id === selectedRowKeys[0]);
    if (!record) {
      message.error("Sản phẩm đã chọn không tồn tại!");
      return;
    }
    setEditingRecord(record);
    setIsModalVisible(true);
  };

  // Delete a category
  const handleDelete = () => {
    dispatch(deleteCategory(selectedRowKeys[0]))
      .unwrap()
      .then(() => {
        message.success("Xóa sản phẩm thành công!");
        fetchCategoryList();
      })
      .catch(() => {});
  };

  // Create or update a category
  const handleCreateOrUpdate = (values, operationType) => {
    setLoading(true);

    if (operationType === "edit" && editingRecord) {
      dispatch(updateCategory({ id: editingRecord.id, categoryData: values }))
        .unwrap()
        .then(() => {
          message.success("Cập nhật sản phẩm thành công!");
          closeModal();
          fetchCategoryList();
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(createCategory(values))
        .unwrap()
        .then(() => {
          message.success("Thêm mới loại sản phẩm thành công!");
          closeModal();
          fetchCategoryList();
          resetSearch();
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Close modal
  const closeModal = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedRowKeys([]);
  };

  // Search
  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };

  // Reset table
  const handleResetTable = () => {
    fetchCategoryList();
    setSelectedRowKeys([]);
  };

  // Reset search after add a new category
  const resetSearch = () => {
    setSearchParams({
      codeSearch: "",
      nameSearch: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  // Table columns
  const filteredDataSource = categories
    .filter((item) => {
      if (!item || !item.code || !item.name) return false;
      const { codeSearch, nameSearch } = searchParams;
      return (
        normalizeString(item.code).includes(normalizeString(codeSearch)) &&
        normalizeString(item.name).includes(normalizeString(nameSearch))
      );
    })
    .map((item, index) => {
      if (!item || !item.id) {
        console.error("Invalid product item", item);
        return null;
      }
      return {
        key: item.id,
        id: item.id,
        code: item.code,
        name: item.name,
        description: item.description,
        stt: index + 1,
      };
    })
    .filter((item) => item !== null);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 50,
      align: "center",
    },
    {
      title: "Mã danh mục",
      dataIndex: "code",
      key: "code",
      width: 180,
      align: "center",
      filters: [...new Set(categories.map((item) => item.code))].map(
        (code) => ({ text: code, value: code })
      ),
      onFilter: (value, record) =>
        normalizeString(record.code).includes(normalizeString(value)),
      filterSearch: true,
    },
    {
      title: "Tên danh mục",
      dataIndex: "name",
      key: "name",
      filters: [...new Set(categories.map((item) => item.name))].map(
        (name) => ({ text: name, value: name })
      ),
      onFilter: (value, record) =>
        normalizeString(record.name).includes(normalizeString(value)),
      filterSearch: true,
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            key={searchKey}
            onSearch={handleSearch}
            searchFields={[
              { key: "codeSearch", placeholder: "Mã danh mục" },
              { key: "nameSearch", placeholder: "Tên dạnh mục" },
            ]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={filteredDataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </Spin>
        </div>

        <Space style={{ marginTop: "10px" }}>
          <Button
            type="primary"
            style={{ backgroundColor: "#52c41a" }}
            onClick={handleResetTable}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Thêm mới
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật sản phẩm
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa loại sản phẩm không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa
            </Button>
          </Popconfirm>
        </Space>

        <FormComponent
          key={editingRecord ? editingRecord.id : "new"}
          visible={isModalVisible}
          onCreate={(values) =>
            handleCreateOrUpdate(values, editingRecord ? "edit" : "create")
          }
          onCancel={closeModal}
          initialValues={editingRecord}
        />
      </div>
    </>
  );
};

export default QuanLyLoaiSanPham;
