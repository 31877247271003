import { EyeOutlined } from "@ant-design/icons";
import { Card, Row, Col, Button, Spin } from "antd";
import React, { useState } from "react";
import ChamberDetailModal from "./ChamberDetail";

const ListCard = ({
  data,
  onViewDetails,
  onSelect,
  currentStep,
  selectedItem,
  loading,
  onCloseModal,
  idFloor,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [chamberId, setChamberId] = useState(null);

  const handleViewDetails = (id) => {
    setChamberId(id);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setChamberId(null);
    onCloseModal();
  };

  return (
    <>
      {" "}
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          {data.map((item) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} key={item.id}>
              <Card
                hoverable
                title={`Mã: ${item.code}`}
                onClick={() => onSelect(item)}
                style={{
                  border:
                    selectedItem?.id === item.id
                      ? "2px solid #1890ff"
                      : "1px solid #ccc",
                  cursor: "pointer",
                }}
                bodyStyle={{ paddingTop: "0px" }}
              >
                {currentStep === 0 && <h2>Tên dãy: {item.name}</h2>}
                {currentStep === 1 && <h2>Tên tầng: {item.name}</h2>}
                {currentStep === 2 && <h2>Tên khoang: {item.name}</h2>}

                <p>- Nội dung: {item.description}</p>
                <p>
                  - Sức chứa: <b>{item.capacity}</b>
                </p>

                {currentStep === 0 && (
                  <p>
                    - Tổng số tầng: <b>{item.totalFloorProducts}</b>
                  </p>
                )}
                {currentStep === 1 && (
                  <p>
                    - Tổng số khoang: <b>{item.totalChamberProducts}</b>
                  </p>
                )}
                {currentStep === 2 && (
                  <>
                    <p>
                      - Tổng số lượng: <b>{item.quantity}</b>
                    </p>
                  </>
                )}
                <p>
                  - Tạo bởi: <b>{item.createdBy}</b>
                </p>
                {currentStep < 2 && (
                  <Button
                    size={{
                      xs: "small",
                      sm: "middle",
                      md: "large",
                      lg: "large",
                      xl: "large",
                      xxl: "large",
                    }}
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onViewDetails(item.id);
                    }}
                  >
                    {currentStep === 0 ? "Xem tầng" : "Xem khoang"}
                  </Button>
                )}
                {currentStep === 2 && (
                  <Button
                    size={{
                      xs: "small",
                      sm: "middle",
                      md: "large",
                      lg: "large",
                      xl: "large",
                      xxl: "large",
                    }}
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewDetails(item.id);
                    }}
                  >
                    Chi tiết
                  </Button>
                )}
              </Card>
            </Col>
          ))}
        </Row>
        {modalVisible && (
          <ChamberDetailModal
            visible={modalVisible}
            onCancel={handleModalCancel}
            chamberId={chamberId}
            idFloor={idFloor}
          />
        )}
      </Spin>
    </>
  );
};

export default ListCard;
