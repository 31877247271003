import React from "react";
import { Modal, Form, Input, InputNumber, Button, DatePicker } from "antd";
import moment from "moment";

const QAQCModal = ({ visible, onCancel, onOk, loading, initialValues }) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...initialValues,
        dateStart: initialValues.dateStart
          ? moment(initialValues.dateStart)
          : null,
        dateEnd: initialValues.dateEnd ? moment(initialValues.dateEnd) : null,
      });
    }
  }, [visible, initialValues, form]);

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      dateStart: values.dateStart ? values.dateStart.toISOString() : null,
      dateEnd: values.dateEnd ? values.dateEnd.toISOString() : null,
    };
    onOk(formattedValues);
  };

  return (
    <Modal
      visible={visible}
      title="Cập nhật sản phẩm"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
        >
          Đóng
        </Button>,
        <Button
          key="ok"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          Xác nhận
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        labelAlign="left"
        wrapperCol={{ span: "18" }}
        labelCol={{ span: "6" }}
      >
        <Form.Item name="code" label="Mã QA/QC">
          <Input disabled />
        </Form.Item>
        <Form.Item name="name" label="Tên QA/QC">
          <Input disabled />
        </Form.Item>
        <Form.Item name="quantityGood" label="Số lượng tốt">
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="quantityBad" label="Số lượng lỗi">
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="dateStart" label="Ngày tạo">
          <DatePicker
            showTime
            format="DD-MM-YYYY HH:mm"
            style={{ width: "100%" }}
            disabled
          />
        </Form.Item>
        <Form.Item name="dateEnd" label="Ngày kết thúc">
          <DatePicker
            showTime
            format="DD-MM-YYYY HH:mm"
            style={{ width: "100%" }}
            disabled
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QAQCModal;
