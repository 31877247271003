import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Space, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import ExcelExportDropdown from "../quanlykho/ExportExcel";
import {
  fetchWarehouse,
  postProductIntoWarehouse,
  clearChamberDetail,
} from "../../redux/Slice/Warehousev2/warehousev2Slice";
import ImportProductModal from "./ImportProduct";
import ViewDetailModal from "./ViewDetail";
import "./index.css";
import ViewInventory from "../quanlykho/ViewInventory";

const WarehouseLayout = () => {
  const dispatch = useDispatch();
  const { warehouse } = useSelector((state) => state.warehousev2);
  const [modalVisible, setModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [inventoryModalVisible, setInventoryModalVisible] = useState(false); // State for ViewInventory modal
  const [selectedChambers, setSelectedChambers] = useState([]);
  const [selectedChamber, setSelectedChamber] = useState({
    id: null,
    name: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchWarehouse());
  }, [dispatch]);

  if (!Array.isArray(warehouse)) {
    console.error("Warehouse data is not an array:", warehouse);
    return <p>Error: Warehouse data is not an array</p>;
  }

  const handleCardClick = (chamberId, chamberName) => {
    const newSelectedChambers = selectedChambers.includes(chamberId)
      ? selectedChambers.filter((id) => id !== chamberId)
      : [...selectedChambers, chamberId];

    setSelectedChambers(newSelectedChambers);

    if (newSelectedChambers.length === 1) {
      setSelectedChamber({ id: newSelectedChambers[0], name: chamberName });
    } else if (newSelectedChambers.length === 0) {
      setSelectedChamber({ id: null, name: "" });
    } else {
      const lastSelectedId =
        newSelectedChambers[newSelectedChambers.length - 1];
      const lastSelectedChamber = warehouse
        .flatMap((floor) => floor.responseFloorBasicModels)
        .flatMap((floor) => floor.responseChamberBasicModels)
        .find((chamber) => chamber.id === lastSelectedId);

      setSelectedChamber({
        id: lastSelectedId,
        name: lastSelectedChamber?.code || "",
      });
    }
  };

  const handleCancelSelection = () => {
    setSelectedChambers([]);
    setSelectedChamber({ id: null, name: "" });
  };

  const handleModalSubmit = async (values) => {
    setLoading(true);
    const payload = { ...values, listChamberId: selectedChambers };
    try {
      await dispatch(postProductIntoWarehouse(payload)).unwrap();
      message.success("Nhập sản phẩm vào kho thành công");
      dispatch(fetchWarehouse());
      setModalVisible(false);
      handleCancelSelection();
    } catch (error) {
      message.error("Đã xảy ra lỗi khi nhập sản phẩm");
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetail = () => {
    if (selectedChamber.id) {
      dispatch(clearChamberDetail());
      setDetailModalVisible(true);
    }
  };

  const handleViewInventory = () => {
    if (selectedChamber.id) {
      setInventoryModalVisible(true);
    }
  };

  const handleUnselect = () => {
    dispatch(fetchWarehouse());
    setSelectedChambers([]);
    setDetailModalVisible(false);
    setSelectedChamber({ id: null, name: "" });
    setInventoryModalVisible(false);
  };

  return (
    <div className="warehouse-layout">
      <Space className="controls">
        <ExcelExportDropdown />
        <Button
          type="primary"
          onClick={() => setModalVisible(true)}
          disabled={selectedChambers.length === 0}
        >
          Nhập sản phẩm vào kho
        </Button>
        <Button
          type="default"
          onClick={handleViewDetail}
          disabled={selectedChambers.length !== 1}
        >
          Xem chi tiết
        </Button>
        <Button
          type="dashed"
          onClick={handleViewInventory}
          disabled={selectedChambers.length !== 1}
        >
          Xem lịch sử
        </Button>
        {selectedChambers.length > 0 && (
          <Button onClick={handleCancelSelection} icon={<ReloadOutlined />}>
            Hủy chọn
          </Button>
        )}
      </Space>
      <div className="warehouse-content">
        {warehouse.map((row) => (
          <div className="row-container" key={row.code}>
            <h3>{row.code}</h3>
            <div className="floor-container">
              {row.responseFloorBasicModels.map((floor) => (
                <div key={floor.code} className="floor">
                  <Card className="floor-card">
                    {floor.responseChamberBasicModels
                      .slice(0, 25)
                      .map((chamber) => (
                        <Card.Grid
                          key={chamber.id}
                          style={{
                            backgroundColor: chamber.color,
                            border: selectedChambers.includes(chamber.id)
                              ? "3px solid #1890ff"
                              : "1px solid #999999",
                          }}
                          className="chamber-grid"
                          onClick={() =>
                            handleCardClick(chamber.id, chamber.code)
                          }
                        >
                          {chamber.code}
                        </Card.Grid>
                      ))}
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ImportProductModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSubmit={handleModalSubmit}
        selectedChambers={selectedChambers}
      />
      {selectedChamber.id && (
        <>
          <ViewDetailModal
            visible={detailModalVisible}
            onCancel={handleUnselect}
            idChamber={selectedChamber.id}
            chamberName={selectedChamber.name}
          />
          <ViewInventory
            visible={inventoryModalVisible}
            onCancel={handleUnselect}
            idChamber={{ id: selectedChamber.id }}
          />
        </>
      )}
    </div>
  );
};

export default WarehouseLayout;
