import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { UserOutlined, LockOutlined, MailOutlined, IdcardOutlined, ControlOutlined, PhoneOutlined } from "@ant-design/icons"; // Include PhoneOutlined icon
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "../../redux/Slice/roleSlice";
import { signUp } from "../../redux/Slice/loginSlice"; // Adjust the path as necessary
import "./index.css";

const { Option } = Select;

const Register = () => {
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.user.isLoading);
  const error = useSelector((state) => state.user.error);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchRoles().then(data => {
      setRoles(data);
    });
  }, []);

  const onFinish = (values) => {
    dispatch(signUp(values))
      .unwrap()
      .then(() => {
        message.success("Đăng ký thành công!");
        navigate("/"); // Navigate to the home page after successful registration
      })
      .catch((err) => {
        if (typeof err === 'object') {
          form.setFields(
            Object.keys(err).map((key) => ({
              name: key,
              errors: [err[key]]
            }))
          );
        } else {
          message.error(err || "Registration failed. Please try again.");
        }
      });
  };

  return (
    <div className="register-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
      <Form
        form={form}
        name="register"
        className="register-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <div className="register-logo">
          <ControlOutlined style={{ fontSize: "40px" }} />
        </div>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your Email!" },
            { type: "email", message: "Please input a valid email!" }
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="fullname"
          rules={[{ required: true, message: "Please input your Full Name!" }]}
        >
          <Input
            prefix={<IdcardOutlined className="site-form-item-icon" />}
            placeholder="Full Name"
          />
        </Form.Item>
        <Form.Item
          name="phoneNumber" // Include the 'phoneNumber' field
          rules={[{ required: true, message: "Please input your Phone Number!" }]}
        >
          <Input
            prefix={<PhoneOutlined className="site-form-item-icon" />}
            placeholder="Phone Number"
          />
        </Form.Item>
        <Form.Item
          name="role"
          rules={[{ required: true, message: "Please select your Role!" }]}
        >
          <Select placeholder="Select your Role">
            {roles.map(role => (
              <Option key={role.id} value={role.name}>{role.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="register-form-button"
            loading={isLoading}
          >
            Sign Up
          </Button>
          <div className="login-link">
            Đã có tài khoản? <Link to="/">Đăng nhập</Link>
          </div>
        </Form.Item>
        {error && <div className="error-message">{error}</div>}
      </Form>
    </div>
  );
};

export default Register;
