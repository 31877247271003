import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/api";
export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      // Gọi API đăng nhập
      const response = await postRequest("auth/login", {
        username,
        password,
      });

      if (response.status === 200) {
        console.log("Login successful");
        console.log("Response:", response);
        const userData = response.data.data.tokenResponse.user;

        // Lưu access token và refresh token vào localStorage
        localStorage.setItem(
          "accessToken",
          response.data.data.tokenResponse.accessToken
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.tokenResponse.refreshToken
        );
        localStorage.setItem("user", JSON.stringify(userData));

        // Trả về dữ liệu user từ phản hồi API
        return response.data;
      } else {
        throw new Error(response.data.message || "Login failed");
      }
    } catch (error) {
      // Trả về một custom error message hoặc error từ server
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (
    { username, password, email, fullname, role, phoneNumber }, // Add phoneNumber to the parameters
    { rejectWithValue }
  ) => {
    try {
      const response = await postRequest("auth/register", {
        username,
        password,
        email,
        fullname,
        role,
        phoneNumber, // Include phoneNumber in the request body
      });

      if (response.status === 200) {
        return response?.data;
      } else {
        // Trả về các lỗi field cụ thể từ API
        return rejectWithValue(response?.data?.data || "Đăng ký thất bại");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.data || error.message);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "user/signUp",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await postRequest("users/forgot-password", {
        email,
      });

      if (response.status === 200) {
        console.log("oke");

        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const changePassword = createAsyncThunk(
  "users/update-password-forgot",
  async ({ authorization, newPassword }, { rejectWithValue }) => {
    try {
      const response = await postRequest("users/update-password-forgot", {
        authorization,
        newPassword,
      });

      if (response.status === 200) {
        console.log("Password changed successfully");
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  isLoading: false,
  isLoggedIn: !!localStorage.getItem("accessToken"), // Check if user is logged in
  user: JSON.parse(localStorage.getItem("user")) || null, // Load user data from local storage
  error: null,
};

export const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.data.tokenResponse.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Could not log in. Please try again.";
      });
  },
});

export default loginSlice.reducer;
