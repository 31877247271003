import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/SearchComponent";
import TableComponent from "../../components/TableComponent";
import { Button, Form, Popconfirm, Space, Spin, message } from "antd";
import FormComponent from "./QLCForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createModule,
  deleteModule,
  fetchModuleById,
  fetchModules,
  setPage,
  setPageSize,
  updateModule,
} from "../../redux/Slice/modulesSlice/quanLyChuyenSlice";
import QLCDetail from "./QLCDetail";

const QuanLyChuyen = () => {
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.quanLyChuyen.modules);
  const currentPage = useSelector((state) => state.quanLyChuyen.currentPage);
  const pageSize = useSelector((state) => state.quanLyChuyen.pageSize);
  const totalCount = useSelector((state) => state.quanLyChuyen.totalCount);
  const detailData = useSelector((state) => state.quanLyChuyen.detailData);
  const status = useSelector((state) => state.quanLyChuyen.status);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState(0);
  const [searchParams, setSearchParams] = useState({
    idSearch: "",
    codeSearch: "",
  });

  useEffect(() => {
    dispatch(fetchModules({ index: currentPage, pageSize, ...searchParams }));
  }, [dispatch, currentPage, pageSize, searchParams]);

  // Open modal to add new module
  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  // Select a module to edit
  const handleEdit = () => {
    const record = modules.find((item) => item.id === selectedRowKeys[0]);
    setEditingRecord(record);
    setIsModalVisible(true);
  };

  // Delete a module
  const handleDelete = () => {
    dispatch(deleteModule(selectedRowKeys[0]))
      .unwrap()
      .then(() => {
        message.success("Xóa thành công!");
        dispatch(
          fetchModules({ index: currentPage, pageSize, ...searchParams })
        );
      })
      .catch((error) => {});
    resetSearch();
  };

  // Reset search parameters and fetch all products after create new
  const resetSearch = () => {
    setSearchParams({
      codeSearch: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  // Create or update a module
  const handleCreate = (values) => {
    setLoading(true);
    if (editingRecord) {
      dispatch(updateModule({ id: editingRecord.id, moduleData: values }))
        .then((response) => {
          if (response.error || response.errorCode) {
          } else {
            form.resetFields();
            message.success("Cập nhật thành công!");
            setIsModalVisible(false);
            dispatch(
              fetchModules({ index: currentPage, pageSize, ...searchParams })
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(createModule(values))
        .then((response) => {
          if (response.error || response.errorCode) {
          } else {
            message.success("Thêm mới thành công!");
            setIsModalVisible(false);
            dispatch(
              fetchModules({ index: currentPage, pageSize, ...searchParams })
            );
            resetSearch();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Close modal
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedRowKeys([]);
  };

  // Search module
  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };

  // Change pagination
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  // View detail of a module
  const handleViewDetails = () => {
    const selectedId = selectedRowKeys[0];
    dispatch(fetchModuleById(selectedId)).then(() => {
      setIsDetailVisible(true);
    });
  };

  // Reset table
  const handleResetTable = () => {
    dispatch(fetchModules({ index: currentPage, pageSize, ...searchParams }));
    setSelectedRowKeys([]);
  };

  const dataSource = modules.map((item, index) => {
    return {
      key: item.id,
      id: item.id,
      code: item.code,
      numOfStaff: item.numOfStaff,
      leadModuleName: item.leadModuleName,
      qcLeadName: item.qcLeadName,
      name: item.name,
      description: item.description,
      stt: index + 1,
    };
  });

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
      align: "center",
    },
    {
      title: "Mã chuyền",
      dataIndex: "code",
      key: "code",
      width: 120,
      align: "center",
    },
    {
      title: "Tên chuyền",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chuyền trưởng",
      dataIndex: "leadModuleName",
      key: "leadModuleName",
    },
    {
      title: "Trưởng QC",
      dataIndex: "qcLeadName",
      key: "qcLeadName",
    },
    {
      title: "Số lượng người",
      dataIndex: "numOfStaff",
      key: "numOfStaff",
      width: 120,
      align: "center",
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            key={searchKey}
            onSearch={handleSearch}
            searchFields={[{ key: "codeSearch", placeholder: "Mã Chuyền" }]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
            />
          </Spin>
        </div>

        <Space>
          <Button
            type="primary"
            style={{ backgroundColor: "#52c41a" }}
            onClick={handleResetTable}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Thêm mới
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật chuyền
          </Button>

          <Button
            type="primary"
            disabled={selectedRowKeys.length === 0}
            onClick={handleViewDetails}
          >
            Xem chi tiết
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa chuyền không không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              Q
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa chuyền
            </Button>
          </Popconfirm>
        </Space>
        {/* Form to add or update a module */}
        <FormComponent
          form={form}
          visible={isModalVisible}
          onCreate={handleCreate}
          onCancel={handleCancel}
          initialValues={editingRecord || null} // Only set initialValues if editing
        />
        {/* Detail of a module */}
        <QLCDetail
          visible={isDetailVisible}
          onCancel={() => setIsDetailVisible(false)}
          taskProducts={detailData ? detailData.taskProducts : []}
        />
      </div>
    </>
  );
};

export default QuanLyChuyen;
