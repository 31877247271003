import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  message,
  Select,
  InputNumber,
  Radio,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import {
  fetchModule,
  fetchOrderProducts,
} from "../../redux/Slice/TaskSlice/taskSlice";
import { useDispatch, useSelector } from "react-redux";

dayjs.extend(utc);
dayjs.extend(timezone);

const EditForm = ({ visible, onUpdate, onCancel, initialValues }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const module = useSelector((state) => state.task.module);
  const orderProducts = useSelector((state) => state.task.orderproducts);

  useEffect(() => {
    if (visible) {
      dispatch(fetchModule({ index: 1, pageSize: 1000 }));
      dispatch(fetchOrderProducts({ index: 1, pageSize: 1000 }));
    }
  }, []);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue({
          ...initialValues,
          dateStart: initialValues.dateStart
            ? dayjs(initialValues.dateStart).tz("Asia/Ho_Chi_Minh")
            : null,
          dateEnd: initialValues.dateEnd
            ? dayjs(initialValues.dateEnd).tz("Asia/Ho_Chi_Minh")
            : null,
          hasBreakTime: initialValues.hasBreakTime,
        });
      }
    }
  }, [visible, initialValues, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedValues = {
        ...values,
        dateStart: values.dateStart
          ? dayjs(values.dateStart).tz("Asia/Ho_Chi_Minh").format()
          : null,
        dateEnd: values.dateEnd
          ? dayjs(values.dateEnd).tz("Asia/Ho_Chi_Minh").format()
          : null,
      };
      onUpdate(updatedValues);
    } catch (errorInfo) {
      console.error("Failed to update:", errorInfo);
    }
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields when modal is closed
    onCancel();
  };

  return (
    <Modal
      centered
      title="Chỉnh sửa Công việc"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Cập nhật
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label="Mã"
          rules={[{ required: true, message: "Vui lòng nhập mã" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Vui lòng nhập tên" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="target" label="Mục tiêu">
          <Input />
        </Form.Item>

        <Form.Item name="owe" label="OWE">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="hasBreakTime"
          label="Giờ nghỉ"
          rules={[
            {
              required: true,
              message: "Xin chọn có hoặc không",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Có</Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="dateStart" label="Ngày bắt đầu">
          <DatePicker showTime format="YYYY-MM-DD | HH:mm:ss" locale={locale} />
        </Form.Item>
        <Form.Item name="dateEnd" label="Ngày kết thúc">
          <DatePicker showTime format="YYYY-MM-DD | HH:mm:ss" locale={locale} />
        </Form.Item>
        <Form.Item
          name="moduleId"
          label="Mã chuyền"
          rules={[{ required: true, message: "Xin nhập mã chuyền!" }]}
        >
          <Select
            placeholder="Chọn chuyền"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {module.map((module) => (
              <Select.Option key={module.id} value={module.id}>
                {module.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="orderProductId"
          label="Mã đặt hàng"
          rules={[{ required: true, message: "Xin nhập mã sản phẩm!" }]}
        >
          <Select
            placeholder="Chọn sản phẩm"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {orderProducts.map((product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Ghi chú">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditForm;
