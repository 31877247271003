import { useSelector } from "react-redux";
import { btnClickSidebarMenuSelector } from "../redux/selector";
import DefaultLayout from "./DefaultLayout";
import { constants } from "../utils/constant";
import AdminDashboard from "../pages/dashboard/AdminDashboard.jsx";
import QuanTriHeThong from "../pages/quantrihethong/QuanTriHeThong.jsx";
import QuanLySanPham from "../pages/quanlysanpham/danh-sach-san-pham/QuanLySanPham.jsx";
import MaLoiTrenSanPham from "../pages/quanlysanpham/ma-loi-tren-san-pham/MaLoiTrenSanPham.jsx";
import DanhSachLoi from "../pages/quanlysanpham/danh-sach-loi/DanhSachLoi.jsx";
import QuanLyTask from "../pages/quanlycongviec/QuanLyCongViec.jsx";
import QuanLyChuyen from "../pages/quanlychuyen/QuanLyChuyen.jsx";
import QuanLyKho from "../pages/quanlykho/QuanLyKho.jsx";
import QuanLyUser from "../pages/quanlyuser/QuanLyUser.jsx";
import DashboardLoi from "../pages/dashboard/dashboard-loi/DashboardLoi.jsx";
import DashboardChuyen from "../pages/dashboard/dashboard-chuyen/DashboardChuyen.jsx";
import QuanLyDatHang from "../pages/quanlysanpham/quan-ly-dat-hang/QuanLyDatHang.jsx";
import QuanLyLoaiSanPham from "../pages/quanlysanpham/danh-sach-loai-san-pham/QuanLyLoaiSanPham";
import TVDashboard from "../pages/dashboard/dashboard-tv/TVDashboard";
import QAQCInfo from "../pages/quanlysanpham/thong-tin-QAQC/QAQCInfo.jsx";
import Warehousev2 from "../pages/qlkho2/Warehousev2.jsx";
function AdminLayout() {
  const currentPage = sessionStorage.getItem("currentPage");

  // change layout
  const sidebarMenu = useSelector(btnClickSidebarMenuSelector);

  return (
    <DefaultLayout currentPage={currentPage}>
      {sidebarMenu === constants.DASHBOARD_ADMIN.key || // menu 1
      currentPage === constants.DASHBOARD_ADMIN.key ? (
        <AdminDashboard />
      ) : sidebarMenu === constants.DASHBOARD_TV.key ||
        currentPage === constants.DASHBOARD_TV.key ? (
        <TVDashboard />
      ) : sidebarMenu === constants.DASHBOARD_LOI.key ||
        currentPage === constants.DASHBOARD_LOI.key ? (
        <DashboardLoi />
      ) : sidebarMenu === constants.DASHBOARD_CHUYEN.key ||
        currentPage === constants.DASHBOARD_CHUYEN.key ? (
        <DashboardChuyen />
      ) : sidebarMenu === constants.SYSTEM_MANAGEMENT.key ||
        currentPage === constants.SYSTEM_MANAGEMENT.key ? (
        <QuanTriHeThong />
      ) : sidebarMenu === constants.PRODUCT_LIST.key ||
        currentPage === constants.PRODUCT_LIST.key ? (
        <QuanLySanPham />
      ) : sidebarMenu === constants.CATEGORY_LIST.key ||
        currentPage === constants.CATEGORY_LIST.key ? (
        <QuanLyLoaiSanPham />
      ) : sidebarMenu === constants.PRODUCT_ERROR_CODE.key ||
        currentPage === constants.PRODUCT_ERROR_CODE.key ? ( // menu 2
        <MaLoiTrenSanPham />
      ) : sidebarMenu === constants.PRODUCT_ORDER.key ||
        currentPage === constants.PRODUCT_ORDER.key ? (
        <QuanLyDatHang />
      ) : sidebarMenu === constants.QAQC_INFO.key ||
        currentPage === constants.QAQC_INFO.key ? (
        <QAQCInfo />
      ) : sidebarMenu === constants.ERROR_LIST.key ||
        currentPage === constants.ERROR_LIST.key ? (
        <DanhSachLoi />
      ) : sidebarMenu === constants.WORK_MANAGEMENT.key ||
        currentPage === constants.WORK_MANAGEMENT.key ? (
        <QuanLyTask />
      ) : sidebarMenu === constants.PASS_MANAGEMENT.key ||
        currentPage === constants.PASS_MANAGEMENT.key ? (
        <QuanLyChuyen />
      ) : sidebarMenu === constants.STORAGE_MANAGEMENT.key ||
        currentPage === constants.STORAGE_MANAGEMENT.key ? (
        <QuanLyKho />
      ) : sidebarMenu === constants.STORE_MANAGEMENTv2.key ||
        currentPage === constants.STORE_MANAGEMENTv2.key ? (
        <Warehousev2 />
      ) : sidebarMenu === constants.USER_MANAGEMENT.key ||
        currentPage === constants.USER_MANAGEMENT.key ? (
        <QuanLyUser />
      ) : null}
    </DefaultLayout>
  );
}
export default AdminLayout;
