import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest, getRequestParams } from "../../../services/api";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
//fetch categories
export const fetchCategories = createAsyncThunk(
  "dashboardTong/fetchCategories",
  async () => {
    let accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;
    try {
      const response = await axios.get(`${API_URL}categories`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }
);

export const fetchtasks = createAsyncThunk(
  "/taskproduct",
  async () => {
    let accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;
    try {
      const response = await axios.get(`${API_URL}taskproduct`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching tasks:", error);
      throw error;
    }
  }
);

// fetch modules
export const fetchModules = createAsyncThunk(
  "dashboardTong/fetchModules",
  async ({ index, pageSize }) => {
    const params = { index, pageSize };
    const response = await getRequestParams("/modules", params);
    return response.data;
  }
);


//fetch quantit-module-chart
export const fetchQuantityModuleChart = createAsyncThunk(
  "dashboardTong/fetchQuantityModuleChart",
  async (params) => {
    const { moduleId, categoryId, date } = params;
    let accessToken = localStorage.getItem("accessToken");
    let url =
      process.env.REACT_APP_BASE_URL + "dashboards/quantity-module-chart";

    if (moduleId.length > 0 || categoryId.length > 0 || date) {
      url += "?";
      if (moduleId.length > 0) {
        moduleId.forEach((id) => {
          url += `moduleIds=${id}&`;
        });
      }
      if (categoryId.length > 0) {
        url += `categoryId=${categoryId}&`;
      }
      if (date) {
        url += `date=${dayjs(date).format("YYYY-MM-DD")}&`;
      }
      url = url.slice(0, -1); // Remove trailing '&'
    } else {
      url += `?date=${dayjs().format("YYYY-MM-DD")}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

const dashboardTongSlice = createSlice({
  name: "dashboardTong",
  initialState: {
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 1000,
    categories: [],
    modules: [],
    taskProducts: [],

    quantityModuleChart: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload.data;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      //fetch tasks
      .addCase(fetchtasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchtasks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskProducts = action.payload.data.result.items;
      })
      .addCase(fetchtasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch modules
      .addCase(fetchModules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.modules = action.payload.data.items;
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch quantity-module-chart
      .addCase(fetchQuantityModuleChart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuantityModuleChart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quantityModuleChart = action.payload.data;
      })
      .addCase(fetchQuantityModuleChart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = dashboardTongSlice.actions;

export default dashboardTongSlice.reducer;
