import React, { useEffect } from "react";
import { Modal, Form, Input, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchChambers } from "../../redux/Slice/Warehouse/chambersSlice";
import {
  fetchChamberById,
  moveProduct,
} from "../../redux/Slice/Warehouse/viewProductDetailSlice";

const MoveProduct = ({
  visible,
  onCancel,
  product,
  chamberId,
  currentPage,
  pageSize,
  idFloor,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const chambers = useSelector((state) => state.chambers.chambers);
  const loading = useSelector((state) => state.chambers.loading);

  console.log(chambers);
  useEffect(() => {
    if (visible && idFloor) {
      dispatch(
        fetchChambers({
          index: 1,
          pageSize: 100,
          codeSearch: "",
          nameProduct: "",
          idFloor,
        })
      );
    }
  }, [visible, idFloor, dispatch]);

  const handleSubmit = (values) => {
    const params = {
      chamberId: product.chamberId,
      productId: product.id,
      chamberIdNew: values.chamberIdNew,
      ItemPerBox: product.itemPerBox,
    };

    dispatch(moveProduct(params))
      .unwrap()
      .then(() => {
        message.success("Chuyển sản phẩm thành công");
        dispatch(
          fetchChamberById({
            chamberProductId: chamberId,
            index: currentPage,
            pageSize,
            nameProduct: "",
            codeProduct: "",
          })
        );
        onCancel();
      })
      .catch((error) => {});
  };

  const filteredChambers = chambers.filter(
    (chamber) => chamber.id !== chamberId
  );
  return (
    <Modal
      visible={visible}
      centered
      title={`Chuyển sản phẩm "${product.name}" sang khoang khác`}
      width={600}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.submit();
      }}
      okText="Xác nhận"
      cancelText="Hủy"
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item label="Tên sản phẩm" name="name" initialValue={product.name}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Số lượng mỗi thùng"
          name="itemPerBox"
          initialValue={product.itemPerBox}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="chamberIdNew"
          label="Chọn khoang mới"
          rules={[{ required: true, message: "Vui lòng chọn khoang mới" }]}
        >
          <Select
            loading={loading}
            placeholder="Chọn khoang mới"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredChambers.map((chamber) => (
              <Select.Option key={chamber.id} value={chamber.id}>
                {chamber.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MoveProduct;
