import React, { useEffect, useState } from "react";
import { Button, Empty, message, Modal, Table } from "antd";
import { postXuatKho } from "../../redux/Slice/Warehouse/xuatKhoSlice";

import { useDispatch, useSelector } from "react-redux";
import ExportModal from "./ExportModal";
import {
  fetchChamberById,
  setPageChamberDetail,
  setPageSizeChamberDetail,
} from "../../redux/Slice/Warehouse/viewProductDetailSlice";
import UpdateProductModal from "./UpdateProductModal";
import MoveProduct from "./MoveProduct";

const CustomEmpty = () => (
  <div>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<p>Không có sản phẩm nào trong khoang</p>}
    />
  </div>
);

const ChamberDetailModal = ({ visible, onCancel, chamberId, idFloor }) => {
  const dispatch = useDispatch();
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const chamberData = useSelector(
    (state) => state.viewProductDetail.chambersDetail
  );
  const currentPage = useSelector(
    (state) => state.viewProductDetail.currentPageDetail
  );
  const pageSize = useSelector(
    (state) => state.viewProductDetail.pageSizeDetail
  );
  const totalCount = useSelector(
    (state) => state.viewProductDetail.totalCountDetail
  );

  useEffect(() => {
    if (visible) {
      dispatch(
        fetchChamberById({
          chamberProductId: chamberId,
          index: currentPage,
          pageSize,
          nameProduct: "",
          codeProduct: "",
        })
      );
    }
  }, [visible, currentPage, pageSize, dispatch, chamberId]);

  //mở modal xuất kho
  const handleExport = (product) => {
    setSelectedProduct(product);
    setExportModalVisible(true);
  };

  //handle export submit -> xuất kho
  const handleExportSubmit = (values) => {
    const data = {
      ...values,
      productId: selectedProduct.id,
      chamberId: selectedProduct.chamberId,
    };

    dispatch(postXuatKho(data))
      .unwrap()
      .then(() => {
        message.success("Xuất kho thành công");
        setExportModalVisible(false);
        setSelectedProduct(null);
        dispatch(
          fetchChamberById({
            chamberProductId: chamberId,
            index: currentPage,
            pageSize,
          })
        );
      })
      .catch(() => {});
  };

  //update product
  const handleUpdate = (product) => {
    setSelectedProduct(product);
    setUpdateModalVisible(true);
  };

  //handel move product to another chamber
  const handleTransfer = (product) => {
    setSelectedProduct(product);
    setMoveModalVisible(true);
  };

  //panigation
  const handlePageChange = (page, pageSize) => {
    dispatch(setPageChamberDetail(page));
    dispatch(setPageSizeChamberDetail(pageSize));
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: 50,
      align: "center",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    { title: "Mã sản phẩm", dataIndex: "code", key: "code", width: 110 },
    { title: "Tên sản phẩm", dataIndex: "name", key: "name" },
    {
      title: "Số sản phẩm mỗi thùng",
      dataIndex: "itemPerBox",
      key: "itemPerBox",
      width: 180,
      render: (text) => `${text} cái/thùng`,
    },
    {
      title: "Số lượng thùng",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
    },
    {
      title: "Ngày nhập kho",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (text) => {
        const formattedDate = new Date(text).toLocaleDateString("vi-VN");
        return formattedDate;
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      width: 150,
    },
    {
      title: "Xuất kho",
      align: "center",
      render: (record) => (
        <Button
          className="green-button"
          type="primary"
          onClick={() => handleExport(record)}
        >
          Xuất kho
        </Button>
      ),
    },
    {
      title: "Cập nhật sản phẩm",
      align: "center",
      render: (record) => (
        <Button type="primary" onClick={() => handleUpdate(record)}>
          Cập nhật
        </Button>
      ),
    },
    {
      title: "Chuyển sản phẩm",
      align: "center",
      render: (record) => (
        <Button type="primary" danger onClick={() => handleTransfer(record)}>
          Chuyển sản phẩm
        </Button>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      centered
      width={1500}
      title={`Chi tiết sản phẩm khoang`}
      footer={null}
      onCancel={onCancel}
    >
      <Table
        bordered
        dataSource={chamberData}
        columns={columns}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
          onChange: handlePageChange,
        }}
        size="small"
        scroll={{ y: 400 }}
        locale={{ emptyText: <CustomEmpty /> }}
      />

      {selectedProduct && (
        <ExportModal
          visible={exportModalVisible}
          onCancel={() => setExportModalVisible(false)}
          product={selectedProduct}
          onSubmit={handleExportSubmit}
        />
      )}
      {selectedProduct && (
        <UpdateProductModal
          visible={updateModalVisible}
          onCancel={() => setUpdateModalVisible(false)}
          product={selectedProduct}
          chamberId={chamberId}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      )}

      {selectedProduct && (
        <MoveProduct
          visible={moveModalVisible}
          onCancel={() => setMoveModalVisible(false)}
          product={selectedProduct}
          chamberId={chamberId}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      )}
    </Modal>
  );
};

export default ChamberDetailModal;
