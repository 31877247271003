import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetch defectcodes
export const fetchDefectCodes = createAsyncThunk(
  "maLoiTrenSanPham/fetchDefectCodes",
  async ({ index, pageSize, codeSearch }) => {
    const params = { index, pageSize, codeSearch };
    const response = await getRequestParams("/defectcodes", params);
    return response.data;
  }
);

//post defectcode
export const postDefectCode = createAsyncThunk(
  "maLoiTrenSanPham/postDefectCode",
  async (defectCodeData, { rejectWithValue }) => {
    try {
      const response = await postRequest("/defectcodes", defectCodeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update defectcode
export const updateDefectCode = createAsyncThunk(
  "maLoiTrenSanPham/updateDefectCode",
  async ({ id, defectCodeData }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/defectcodes/${id}`, defectCodeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete defectcode
export const deleteDefectCode = createAsyncThunk(
  "maLoiTrenSanPham/deleteDefectCode",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/defectcodes/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const maLoiTrenSanPhamSlice = createSlice({
  name: "maLoiTrenSanPhamSlice",
  initialState: {
    status: "idle",
    error: null,
    defectCodes: [],
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //fetch defectcodes
      .addCase(fetchDefectCodes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDefectCodes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.defectCodes = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchDefectCodes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //post defectcode
      .addCase(postDefectCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postDefectCode.fulfilled, (state, action) => {
        state.defectCodes.push(action.payload);
      })
      .addCase(postDefectCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //update defectcode
      .addCase(updateDefectCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDefectCode.fulfilled, (state, action) => {
        const { id, defectCodeData } = action.payload;
        const existingDefectCode = state.defectCodes.find(
          (defectCode) => defectCode.id === id
        );
        if (existingDefectCode) {
          existingDefectCode.defectCodeData = defectCodeData;
        }
      })
      .addCase(updateDefectCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //delete defectcode
      .addCase(deleteDefectCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDefectCode.fulfilled, (state, action) => {
        const id = action.payload;
        state.defectCodes = state.defectCodes.filter(
          (defectCode) => defectCode.id !== id
        );
      })
      .addCase(deleteDefectCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = maLoiTrenSanPhamSlice.actions;

export default maLoiTrenSanPhamSlice.reducer;
