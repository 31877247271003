import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  LabelList,
} from "recharts";
import {
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { Button, Collapse, Space, Select, DatePicker, message } from "antd";
import * as XLSX from "xlsx";
import "./AdminDashboard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  fetchModules,
  fetchQuantityModuleChart,
} from "../../redux/Slice/dashboardTong/dashboardTongSlice";
import dayjs from "dayjs";
import { set } from "react-hook-form";

const { Option } = Select;

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [chartData, setChartData] = useState([]);
  const [moduleInfos, setModuleInfos] = useState([]);

  const categories = useSelector((state) => state.dashboardTong.categories);
  const modules = useSelector((state) => state.dashboardTong.modules);
  const quantityModuleChart = useSelector(
    (state) => state.dashboardTong.quantityModuleChart
  );
  const loading = useSelector(
    (state) => state.dashboardTong.status === "loading"
  );
  const error = useSelector((state) => state.dashboardTong.error);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchModules({ index: 1, pageSize: 10000 }));

    const today = dayjs();
    dispatch(
      fetchQuantityModuleChart({ moduleId: [], categoryId: [], date: today })
    );
  }, [dispatch]);

  const onClear = () => {
    setSelectedCategory([]);
    setSelectedModule([]);
    setChartData([]);
    setModuleInfos([]);
    setSelectedDate(null);
    // Fetch data with today's date
    const today = dayjs();
    dispatch(
      fetchQuantityModuleChart({ moduleId: [], categoryId: [], date: today })
    );
  };
  const handleCategoryChange = (value) => {
    console.log("category", value);
    setSelectedCategory(value);
  };

  const handleModuleChange = (value) => {
    setSelectedModule(value);
  };
  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  // const handleFetchData = () => {
  //   console.log("moduleId2", selectedCategory);
  //   dispatch(
  //     fetchQuantityModuleChart({
  //       moduleId: selectedModule,
  //       categoryId: selectedCategory,
  //       date: selectedDate,
  //     })
  //   );
  // };

  const handleFetchData = () => {
    const params = {
      moduleId: selectedModule,
      categoryId: selectedCategory.includes("all") ? [] : selectedCategory,
      date: selectedDate,
    };

    dispatch(fetchQuantityModuleChart(params));
  };

  useEffect(() => {
    const intervalId = setInterval(handleFetchData, 60000); // Gọi handleFetchData mỗi 60 giây

    return () => clearInterval(intervalId); // Dọn dẹp interval khi component unmount
  }, [dispatch, selectedCategory, selectedModule, selectedDate]);

  const exportToExcel = () => {
    // const worksheet = XLSX.utils.json_to_sheet(productionData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    // XLSX.writeFile(workbook, "data.xlsx");
  };

  useEffect(() => {
    if (quantityModuleChart?.length > 0) {
      const chartData = quantityModuleChart.map((module) => ({
        chartCols: module.chartCols.map((item) => ({
          name: item.time,
          value: item.quantity,
          target: module.target,
          fill:
            item.quantity >= module.taskProductsDetail[0].target
              ? "green"
              : "red",
        })),
        moduleInfo: module,
      }));
      setChartData(chartData);
    } else {
      setChartData([]);
      setModuleInfos([]);
    }
  }, [quantityModuleChart]);

  return (
    <div>
      <Collapse
        defaultActiveKey={["7"]}
        size="small"
        items={[
          {
            key: "7",
            label: "Tìm kiếm",
            children: (
              <Space align="center">
                <Select
                  placeholder="Chọn danh mục"
                  style={{ width: 200 }}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <Option key="all" value="all">
                    Tất cả
                  </Option>
                  {Array.isArray(categories) &&
                    categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                </Select>
                <Select
                  mode="multiple"
                  placeholder="Chọn chuyền"
                  style={{ width: 200 }}
                  value={selectedModule}
                  onChange={handleModuleChange}
                >
                  {modules.map((module) => (
                    <Option key={module.id} value={module.id}>
                      {module.name}
                    </Option>
                  ))}
                </Select>
                <DatePicker
                  placeholder="Chọn ngày"
                  style={{ width: 150 }}
                  value={selectedDate}
                  locale={locale}
                  format="DD-MM-YYYY"
                  onChange={handleDateChange}
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={handleFetchData}
                  loading={loading}
                >
                  Hiển thị
                </Button>
                <Button
                  type="default"
                  danger
                  icon={<CloseOutlined />}
                  onClick={onClear}
                >
                  Hủy bỏ
                </Button>
                {/* <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                >
                  Xuất file Excel
                </Button> */}
              </Space>
            ),
          },
        ]}
      />
      <div className="chart-container">
        {chartData?.length > 0 &&
          chartData.map(({ chartCols, moduleInfo }, index) => (
            <div key={index} className="chart-wrapper">
              <div className="chart-header">
                <h3>Sản lượng {moduleInfo.moduleName}</h3>
                <p>
                  Giờ làm việc {moduleInfo.dateStart} - {moduleInfo.dateEnd}
                </p>
              </div>
              <div className="chart-header">
                <BarChart
                  width={1000}
                  height={300}
                  data={chartCols}
                  margin={{ top: 5, right: 150, left: 80, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[
                      0,
                      Math.max(
                        ...moduleInfo.chartCols.map((item) => item.quantity)
                      ) + 50,
                    ]}
                  />
                  <Tooltip />
                  {moduleInfo.taskProductsDetail.map((item, index) => (
                    <ReferenceLine

                      key={item.target}
                      y={item.target}
                      stroke={index === 0 ? "#18857D" : "black"}
                      strokeDasharray="2 2"
                      strokeWidth={1}
                    >
                    
                      <Label
                        value={`Mục tiêu: ${item.target}`}
                        position={index === 0 ? "left" : "right"}
                        fill={index === 0 ? "#18857D" : "black"}
                        fontWeight="bold"
                        dy={-10}
                        dx={index === 1 ? 3 : -25}
                      />
                      <Label
                        value={`(${item.dateStart} - ${item.dateEnd})`}
                        position={index === 0 ? "left" : "right"}
                        fill={index === 0 ? "#18857D" : "black"}
                        fontWeight="bold"
                        dy={10}
                        dx={index === 1 ? 3 : -25}
                      />
                    </ReferenceLine>
                  ))}

                  <Bar dataKey="value" fill={(entry) => entry.fill}>
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </div>

              <div className="chart-legend">
                <div className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: "green" }}
                  ></div>
                  <span>Đã đạt target</span>
                </div>
                <div className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: "red" }}
                  ></div>
                  <span>Chưa đạt target</span>
                </div>
              </div>
              {moduleInfo.moduleName && (
                <div className="info-panel">
                  <div className="info-header">
                    <div className="info-title">Tên sản phẩm</div>
                    <div className="info-value">{moduleInfo.name}</div>
                  </div>
                  <div className="info-row">
                    <div className="info-cell">
                      <div className="info-subtitle">Sản lượng theo giờ</div>
                      <div className="info-detail">
                        {moduleInfo.currentQuantityByHour}/
                        {moduleInfo.totalQuantityByHour}
                      </div>
                    </div>
                    <div className="info-cell">
                      <div className="info-subtitle">
                        Sản lượng trong ngày hiện tại
                      </div>
                      <div className="info-detail">
                        {moduleInfo.currentQuantityByDay}/
                        {moduleInfo.totalQuantityByDay}
                      </div>
                    </div>
                    {/* <div className="info-cell">
                      <div className="info-subtitle">Thời gian làm</div>
                      <div className="info-detail">
                        {moduleInfo.neededTime}/{moduleInfo.totalNeededTime}{" "}
                        phút
                      </div>
                    </div> */}
                  </div>
                  <div className="info-footer">
                    <div className="info-box">
                      <div className="info-box-title">Ngày hôm nay</div>
                      <div className="info-box-value">
                        {moduleInfo.statisticTable.todayTarget || 0}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
