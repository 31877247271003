import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Cascader, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { moveProduct, fetchWarehouse } from "../../redux/Slice/Warehousev2/warehousev2Slice";

const MoveProductModal = ({ visible, onCancel, product, onMoveSuccess }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { warehouse } = useSelector((state) => state.warehousev2);

  const [cascaderOptions, setCascaderOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      dispatch(fetchWarehouse());
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if (Array.isArray(warehouse)) {
      const options = warehouse.map((row) => ({
        value: row.code,
        label: row.code,
        children: row.responseFloorBasicModels.map((floor) => ({
          value: floor.code,
          label: floor.code,
          children: floor.responseChamberBasicModels.map((chamber) => ({
            value: chamber.id,
            label: chamber.code,
          })),
        })),
      }));
      setCascaderOptions(options);
    }
  }, [warehouse]);

  const handleMoveProduct = async (values) => {
    try {
      await dispatch(moveProduct({
        chamberId: product.chamberId,
        productId: product.id,
        chamberIdNew: values.chamberIdNew[2], // Select the chamber ID from the last level
        ItemPerBox: product.itemPerBox,
      })).unwrap();
      onMoveSuccess();
      form.resetFields();
      onCancel();
    } catch (error) {
      console.error("Failed to move product:", error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={`Chuyển sản phẩm: ${product.name}`}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Hủy bỏ
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Chuyển
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          chamberId: product.chamberId,
          productId: product.id,
          itemPerBox: product.itemPerBox,
        }}
        onFinish={handleMoveProduct}
      >
        <Form.Item name="chamberId" label="Chamber ID" hidden>
          <Input disabled />
        </Form.Item>

        <Form.Item name="productId" label="Product ID" hidden>
          <Input disabled />
        </Form.Item>

        <Form.Item name="itemPerBox" label="Số sản phẩm mỗi thùng">
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="chamberIdNew"
          label="Chọn khoang mới"
          rules={[{ required: true, message: "Vui lòng chọn khoang" }]}
        >
          <Cascader
            options={cascaderOptions}
            placeholder="Chọn dãy, tầng, khoang"
            displayRender={(label) => label.join(' / ')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MoveProductModal;
