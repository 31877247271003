import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams, putRequestParams } from "../../../services/api";

// fetch chamber by id
export const fetchChamberById = createAsyncThunk(
  "chamber/fetchChamberById",
  async ({ chamberProductId, index, pageSize, nameProduct, codeProduct }) => {
    const params = { index, pageSize, nameProduct, codeProduct };
    const response = await getRequestParams(
      `/warehouses/${chamberProductId}/product`,
      params
    );
    return response.data;
  }
);

//update product
export const updateProduct = createAsyncThunk(
  "viewProductDetail/updateProduct",
  async (
    { ChamberId, ItemPerBox, ProductId, productIdNew },
    { rejectWithValue }
  ) => {
    try {
      const params = { ChamberId, ItemPerBox, ProductId, productIdNew };
      const response = await putRequestParams(
        "/warehouses/productinchamber",
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//move product to another chamber
export const moveProduct = createAsyncThunk(
  "viewProductDetail/moveProduct",
  async (
    { chamberId, productId, chamberIdNew, ItemPerBox },
    { rejectWithValue }
  ) => {
    try {
      const params = { chamberId, productId, chamberIdNew, ItemPerBox };
      const response = await putRequestParams(
        "/warehouses/moveproductinotherchamber",
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const viewProductDetailSlice = createSlice({
  name: "viewProductDetail",
  initialState: {
    chambersDetail: [],
    loading: false,
    error: null,
    status: "idle",
    currentPageDetail: 1,
    pageSizeDetail: 20,
    totalCountDetail: 0,
  },
  reducers: {
    setPageChamberDetail: (state, action) => {
      state.currentPageDetail = action.payload;
    },

    setPageSizeChamberDetail: (state, action) => {
      state.pageSizeDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetch chamber by id
      .addCase(fetchChamberById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChamberById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chambersDetail = action.payload.data.items;
        state.totalCountDetail = action.payload.data.totalCount;
      })
      .addCase(fetchChamberById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //update product
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //move product
      .addCase(moveProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(moveProduct.fulfilled, (state) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(moveProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPageChamberDetail, setPageSizeChamberDetail } =
  viewProductDetailSlice.actions;

export default viewProductDetailSlice.reducer;
