import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  getRequestParams,
  deleteRequest,
  postRequest,
  putRequest,
} from "../../services/api";

// Async Thunk for fetching users

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ pageIndex, pageSize,idSearch,codeSearch }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await getRequestParams(
        `/users`,
        { index: pageIndex, PageSize: pageSize, FullName: codeSearch, Username: idSearch },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return {
        data: response.data.data,
        totalPages: response.data.totalPages, // Ensure totalPages is included in the response
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// Async Thunk for deleting a user
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await deleteRequest(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for adding a user
export const addUser = createAsyncThunk(
  "users/addUser",
  async (userData, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await postRequest(`/users`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to add user");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for updating a user
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (userData, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await putRequest(`/users/${userData.id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (userData, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await postRequest(`/auth/changePassword`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to add user");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    error: null,
    data: [], // Ensure data is an array
    totalPages: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data; // Ensure this matches your API response structure
        state.totalPages = action.payload.totalPages; // Ensure totalPages is set correctly
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (Array.isArray(state.data)) {
          state.data = state.data.filter((item) => item.id !== action.payload);
        }
      })
      .addCase(addUser.fulfilled, (state, action) => {
        if (Array.isArray(state.data)) {
          state.data.push(action.payload);
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (Array.isArray(state.data)) {
          const updatedUserIndex = state.data.findIndex((item) => item.id === action.payload.id);
          if (updatedUserIndex !== -1) {
            state.data[updatedUserIndex] = action.payload;
          }
        }
      });
  },
});


export default userSlice.reducer;
