// src/components/ProductFormModal.js

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, message, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChambers,
  fetchProducts,
  postProductIntoWarehouse,
} from "../../redux/Slice/Warehouse/nhapSanPhamVaoKhoSlice";
import TextArea from "antd/es/input/TextArea";
import FormItem from "antd/es/form/FormItem";

const { Option } = Select;

const ProductFormModal = ({ visible, onCancel, onSubmit, idFloor }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const products = useSelector((state) => state.nhapSanPhamVaoKho.products);
  const chambers = useSelector((state) => state.nhapSanPhamVaoKho.chambers);

  useEffect(() => {
    if (visible) {
      dispatch(fetchProducts({ pageNo: 1, pageSize: 100, codeSearch: "" }));
      dispatch(
        fetchChambers({ index: 1, pageSize: 1000, codeSearch: "", idFloor })
      );
    }
  }, [visible, dispatch, idFloor]);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(postProductIntoWarehouse({ ...values }))
      .unwrap()
      .then(() => {
        message.success("Nhập kho thành công");
        form.resetFields();
        onSubmit();
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      centered
      visible={visible}
      title="Nhập sản phẩm vào kho"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      cancelText="Hủy"
      okText="Thêm"
      width={800}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="product_form_modal"
        labelAlign="left"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name="code"
          label="Mã nhập kho"
          rules={[{ required: true, message: "Vui lòng nhập mã nhập kho" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên nhập kho"
          rules={[{ required: true, message: "Vui lòng nhập tên nhập kho" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Số lượng thùng"
          rules={[{ required: true, message: "Vui lòng nhập số lượng thùng" }]}
        >
          <InputNumber min={0} style={{ width: "50%" }} />
        </Form.Item>
        <Form.Item
          name="itemPerBox"
          label="Số lượng sản phẩm/thùng"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số lượng hàng mỗi thùng",
            },
          ]}
        >
          <InputNumber style={{ width: "50%" }} min={1} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Nội dung"
          rules={[{ required: true, message: "Vui lòng nhập nội dung" }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="listProductId"
          label="Loại sản phẩm"
          rules={[{ required: true, message: "Vui lòng chọn sản phẩm" }]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Chọn sản phẩm"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {products.map((product) => (
              <Option key={product.id} value={product.id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="listChamberId"
          label="Khoang"
          rules={[{ required: true, message: "Vui lòng chọn khoang" }]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Chọn khoang"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {chambers.map((chamber) => (
              <Option key={chamber.id} value={chamber.id}>
                {chamber.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductFormModal;
