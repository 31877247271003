import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequestParams,
  deleteRequest,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetching line products
export const fetchLineProducts = createAsyncThunk(
  "lineProducts/fetchLineProducts",
  async ({ index, pageSize, codeSearch }) => {
    const params = { index, pageSize, codeSearch };
    const response = await getRequestParams(
      "/warehouse-management/lineproducts",
      params
    );
    return response.data;
  }
);

//post line product
export const postLineProduct = createAsyncThunk(
  "lineProducts/postLineProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest(
        "/warehouse-management/lineproducts",
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update line product
export const updateLineProduct = createAsyncThunk(
  "lineProducts/updateLineProduct",
  async ({ idLineProduct, data }, { rejectWithValue }) => {
    try {
      const response = await putRequest(
        `/warehouse-management/lineproducts/${idLineProduct}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete line product
export const deleteLineProduct = createAsyncThunk(
  "lineProducts/deleteLineProduct",
  async (idLineProduct, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(
        `/warehouse-management/lineproducts?idLineProduct=${idLineProduct}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const lineProductSlice = createSlice({
  name: "lineProducts",
  initialState: {
    loading: false,
    error: null,
    lineProducts: [],
    currentPage: 1,
    pageSize: 15,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetching line products
      .addCase(fetchLineProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLineProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.lineProducts = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(fetchLineProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //post line product
      .addCase(postLineProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLineProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.lineProducts.push(action.payload);
      })
      .addCase(postLineProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //update line product
      .addCase(updateLineProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLineProduct.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.lineProducts.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.lineProducts[index] = action.payload;
        }
      })
      .addCase(updateLineProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //delete line product
      .addCase(deleteLineProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLineProduct.fulfilled, (state, action) => {
        state.lineProducts = state.lineProducts.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteLineProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setPage, setPageSize } = lineProductSlice.actions;

export default lineProductSlice.reducer;
