import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, InputNumber, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import {
  fetchChambers,
  fetchProducts,
  postXuatKho,
} from "../../redux/Slice/Warehouse/xuatKhoSlice";
const { Option } = Select;

const ExportProduct = ({
  visible,
  onCancel,
  onSubmit,
  idFloor,
  chamberId,
  chamberName,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const chambers = useSelector((state) => state.xuatKho.chambers);

  useEffect(() => {
    if (chamberId) {
      const selectedChamber = chambers.find(
        (chamber) => chamber.id === chamberId
      );
      if (selectedChamber) {
        setProducts(selectedChamber.listProductInfo || []);
      } else {
        setProducts([]);
      }
    }
  }, [chamberId, chambers]);

  useEffect(() => {
    if (chamberId && chamberName) {
      form.setFieldsValue({
        chamberName: chamberName,
      });
    }
  }, [chamberId, chamberName, form]);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(postXuatKho({ ...values, chamberId }))
      .unwrap()
      .then(() => {
        // Nếu thành công
        message.success("Xuất kho thành công");
        form.resetFields();
        onSubmit(); // Đóng modal
      })
      .catch((error) => {})

      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Modal
        centered
        visible={visible}
        title="Xuất sản phẩm"
        width={800}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        cancelText="Hủy"
        okText="Xuất"
        confirmLoading={loading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleSubmit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="horizontal"
          name="product_form_modal"
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="code"
            label="Mã xuất kho"
            rules={[{ required: true, message: "Vui lòng nhập mã xuất kho" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Tên xuất kho"
            rules={[{ required: true, message: "Vui lòng nhập tên xuất kho" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Số lượng"
            rules={[
              { required: true, message: "Vui lòng nhập số lượng thùng" },
            ]}
          >
            <InputNumber style={{ width: "50%" }} min={1} />
          </Form.Item>
          <Form.Item
            name="itemPerBox"
            label="Số lượng sản phẩm/thùng"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số lượng hàng mỗi thùng",
              },
            ]}
          >
            <InputNumber style={{ width: "50%" }} min={1} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Nội dung"
            rules={[{ required: true, message: "Vui lòng nhập nội dung" }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="productId"
            label="Chọn sản phẩm"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn sản phẩm cần xuất kho",
              },
            ]}
          >
            <Select placeholder="Chọn sản phẩm">
              {products.length > 0 ? (
                products.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))
              ) : (
                <Option key="no-product" disabled>
                  Không có sản phẩm trong khoang
                </Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="chamberId"
            label="Khoang"
            initialValue={chamberName}
            rules={[{ required: true, message: "Vui lòng chọn khoang" }]}
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ExportProduct;
