import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams } from "../../../services/api";

//fetch modules
export const fetchModules = createAsyncThunk(
  "dashboardLoi/fetchModules",
  async ({ index, pageSize }) => {
    const params = { index, pageSize };
    const response = await getRequestParams("/modules", params);
    return response.data;
  }
);

//Fetch kpi defect code
export const getKPIDefectCode = createAsyncThunk(
  "dashboardLoi/getKPIDefectCode",
  async ({ moduleId, dateStart, dateEnd, monthYear }) => {
    const params = { moduleId, dateStart, dateEnd, monthYear };
    const response = await getRequestParams(
      "/dashboards/getkpidefectcode",
      params
    );
    return response.data;
  }
);

//fetch daily defectcodepercent
export const getDailyDefectCodePercent = createAsyncThunk(
  "dashboardLoi/getDailyDefectCodePercent",
  async ({ moduleId, dateStart, dateEnd, monthYear }) => {
    const params = { moduleId, dateStart, dateEnd, monthYear };
    const response = await getRequestParams(
      "/dashboards/getdailydefectcodepercent",
      params
    );
    return response.data;
  }
);

//fetch hourly defect code percent
export const getHourlyDefectCodePercent = createAsyncThunk(
  "dashboardLoi/getHourlyDefectCodePercent",
  async ({ moduleId, dateStart, dateEnd, monthYear }) => {
    const params = { moduleId, dateStart, dateEnd, monthYear };
    const response = await getRequestParams(
      "/dashboards/gethourlydefectcodepercent",
      params
    );
    return response.data;
  }
);

const dashboardLoiSlice = createSlice({
  name: "dashboardLoi",
  initialState: {
    status: "idle",
    error: null,
    modules: [],
    kpiDefectCode: [],
    dailyDefectCodePercent: [],
    hourlyDefectCodePercent: [],
    currentPage: 1,
    pageSize: 1000,
    totalCount: 0,
    dateStart: null,
    dateEnd: null,
    monthYear: null,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetch modules
      .addCase(fetchModules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.modules = action.payload?.data?.items || [];
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch kpi defect code
      .addCase(getKPIDefectCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKPIDefectCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.kpiDefectCode = action.payload?.data || [];
      })
      .addCase(getKPIDefectCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch daily defect code percent
      .addCase(getDailyDefectCodePercent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDailyDefectCodePercent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dailyDefectCodePercent = action.payload?.data || [];
      })
      .addCase(getDailyDefectCodePercent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch hourly defect code percent
      .addCase(getHourlyDefectCodePercent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHourlyDefectCodePercent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hourlyDefectCodePercent = action.payload?.data || [];
      })
      .addCase(getHourlyDefectCodePercent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = dashboardLoiSlice.actions;

export default dashboardLoiSlice.reducer;
