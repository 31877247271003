import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestParams, postRequest } from "../../../services/api";

//fetching products

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ index, pageSize, codeSearch }) => {
    const params = { index, pageSize, codeSearch };
    const response = await getRequestParams("/products", params);
    return response.data;
  }
);

//post product into Warehouse
export const postProductIntoWarehouse = createAsyncThunk(
  "products/postProductIntoWarehouse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest("/warehouse-management/nhapkho", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//fetch chamber
export const fetchChambers = createAsyncThunk(
  "chamber/fetchChambers",
  async ({ index, pageSize, codeSearch, idFloor }) => {
    const params = { index, pageSize, codeSearch, idFloor };
    const response = await getRequestParams(
      "/warehouse-management/chambers",
      params
    );
    return response.data;
  }
);

const nhapSanPhamVaoKhoSlice = createSlice({
  name: "nhapSanPhamVaoKho",
  initialState: {
    chambers: [],
    products: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 1000,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "success";
        state.products = action.payload.data.items;
        state.totalCount = action.payload.total;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      //post product into warehouse
      .addCase(postProductIntoWarehouse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postProductIntoWarehouse.fulfilled, (state, action) => {
        state.status = "success";
        state.products.push(action.payload);
      })
      .addCase(postProductIntoWarehouse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      //fetching chambers
      .addCase(fetchChambers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChambers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chambers = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchChambers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPage, setPageSize } = nhapSanPhamVaoKhoSlice.actions;

export default nhapSanPhamVaoKhoSlice.reducer;
