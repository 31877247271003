import React, { useEffect, useState } from "react";
import { Modal, Table, Spin, Empty, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChamberById,
  setPageChamberDetail,
  setPageSizeChamberDetail,
  clearChamberDetail,
  postXuatKho,
} from "../../redux/Slice/Warehousev2/warehousev2Slice";
import ExportModal from "../quanlykho/ExportModal";
import UpdateProductModal from "../quanlykho/UpdateProductModal";
import MoveProductModal from "./MoveProductModal";

const CustomEmpty = () => (
  <div>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<p>Không có sản phẩm nào trong khoang</p>}
    />
  </div>
);

const ViewDetailModal = ({ visible, onCancel, idChamber, chamberName }) => {
  const dispatch = useDispatch();
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {
    chambersDetail,
    status,
    currentPageDetail,
    pageSizeDetail,
    totalCountDetail,
  } = useSelector((state) => state.warehousev2);

  const getChamberDetail = () => {
    dispatch(
      fetchChamberById({
        chamberProductId: idChamber,
        index: currentPageDetail,
        pageSize: pageSizeDetail,
      })
    );
  };

  useEffect(() => {
    if (idChamber && visible) {
      getChamberDetail();
    }
  }, [dispatch, idChamber, visible, currentPageDetail, pageSizeDetail]);

  const handlePageChange = (page, pageSize) => {
    dispatch(setPageChamberDetail(page));
    dispatch(setPageSizeChamberDetail(pageSize));
  };

  const handleClose = () => {
    dispatch(clearChamberDetail());
    onCancel();
  };

  // Handle export modal open
  const handleExportClick = (product) => {
    setSelectedProduct(product);
    setExportModalVisible(true);
  };

  // Handle export submit
  const handleExportSubmit = (values) => {
    const exportPayload = {
      ...values,
      productId: selectedProduct.id,
      chamberId: idChamber,
      itemPerBox: selectedProduct.itemPerBox,
    };

    dispatch(postXuatKho(exportPayload))
      .unwrap()
      .then(() => {
        message.success("Xuất kho thành công");
        setExportModalVisible(false);
        setSelectedProduct(null);
        getChamberDetail();
      })
      .catch(() => {
        message.error("Xuất kho thất bại");
      });
  };

  // Handle export modal close
  const handleExportModalClose = () => {
    setExportModalVisible(false);
    setSelectedProduct(null);
  };

  // Handle update modal open
  const handleUpdateClick = (product) => {
    setSelectedProduct(product);
    setUpdateModalVisible(true);
  };

  // Handle update modal close
  const handleUpdateModalClose = () => {
    setUpdateModalVisible(false);
    setSelectedProduct(null);
  };

  // Handle move modal open
  const handleMoveClick = (product) => {
    setSelectedProduct(product);
    setMoveModalVisible(true);
  };
  const handleMoveSuccess = () => {
    message.success("Chuyển sản phẩm thành công");
    getChamberDetail();
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Số sản phẩm mỗi thùng",
      dataIndex: "itemPerBox",
      key: "itemPerBox",
      align: "center",
    },
    {
      title: "Ngày nhập",
      dataIndex: "date",
      key: "date",
      render: (text) => new Date(text).toLocaleDateString("vi-VN"),
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Xuất kho",
      align: "center",
      render: (_, record) => (
        <Button
          type="primary"
          className="green-button"
          onClick={() => handleExportClick(record)}
        >
          Xuất kho
        </Button>
      ),
    },
    {
      title: "Cập nhật sản phẩm",
      align: "center",
      render: (record) => (
        <Button type="primary" onClick={() => handleUpdateClick(record)}>
          Cập nhật
        </Button>
      ),
    },
    {
      title: "Chuyển sản phẩm",
      align: "center",
      render: (record) => (
        <Button type="primary" danger onClick={() => handleMoveClick(record)}>
          Chuyển sản phẩm
        </Button>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title={`Chi tiết khoang: ${chamberName}`}
      width={1500}
      centered
      footer={null}
    >
      {status === "loading" ? (
        <Spin />
      ) : (
        <Table
          dataSource={chambersDetail}
          columns={columns}
          pagination={{
            current: currentPageDetail,
            pageSize: pageSizeDetail,
            total: totalCountDetail,
            onChange: handlePageChange,
          }}
          rowKey="id"
          locale={{ emptyText: <CustomEmpty /> }}
        />
      )}
      {selectedProduct && (
        <>
          <ExportModal
            visible={exportModalVisible}
            onCancel={handleExportModalClose}
            product={selectedProduct}
            onSubmit={handleExportSubmit}
          />
          <UpdateProductModal
            visible={updateModalVisible}
            onCancel={handleUpdateModalClose}
            product={selectedProduct}
            chamberId={idChamber}
            currentPage={currentPageDetail}
            pageSize={pageSizeDetail}
          />
          <MoveProductModal
            visible={moveModalVisible}
            onCancel={() => setMoveModalVisible(false)}
            product={selectedProduct}
            onMoveSuccess={handleMoveSuccess}
          />
        </>
      )}
    </Modal>
  );
};

export default ViewDetailModal;
