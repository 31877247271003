import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  InputNumber,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/Slice/Warehousev2/warehousev2Slice";

const { Option } = Select;

const ImportProductModal = ({
  visible,
  onCancel,
  onSubmit,
  selectedChambers,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { products, warehouse } = useSelector((state) => state.warehousev2);

  useEffect(() => {
    if (visible) {
      dispatch(fetchProducts({ index: 1, pageSize: 100 }));
    }
  }, [visible, dispatch]);

  const handleFinish = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  // Find selected chambers details
  const selectedChamberDetails = warehouse
    .flatMap((floor) => floor.responseFloorBasicModels)
    .flatMap((floor) => floor.responseChamberBasicModels)
    .filter((chamber) => selectedChambers.includes(chamber.id));

  // Split selected chambers into columns, 5 per column
  const chambersPerColumn = 5;
  const columns = [];
  for (let i = 0; i < selectedChamberDetails.length; i += chambersPerColumn) {
    columns.push(selectedChamberDetails.slice(i, i + chambersPerColumn));
  }

  return (
    <Modal
      open={visible}
      centered
      title="Nhập sản phẩm vào kho"
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Lưu
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 6 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label="Mã nhập kho"
          rules={[{ required: true, message: "Vui lòng nhập mã nhập kho" }]}
        >
          <Input placeholder="Nhập mã nhập kho" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên nhập kho"
          rules={[{ required: true, message: "Vui lòng nhập tên nhập kho" }]}
        >
          <Input placeholder="Nhập tên nhập kho" />
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Số lượng"
          rules={[{ required: true, message: "Vui lòng nhập số lượng" }]}
        >
          <InputNumber
            placeholder="Nhập số lượng"
            min={0}
            style={{ width: "50%" }}
          />
        </Form.Item>
        <Form.Item
          name="itemPerBox"
          label="Số sản phẩm mỗi thùng"
          rules={[
            { required: true, message: "Vui lòng nhập Số sản phẩm mỗi thùng" },
          ]}
        >
          <InputNumber
            min={0}
            placeholder="Nhập Số sản phẩm mỗi thùng"
            style={{ width: "50%" }}
          />
        </Form.Item>
        <Form.Item name="description" label="Mô tả">
          <Input.TextArea placeholder="Nhập mô tả" />
        </Form.Item>
        <Form.Item
          name="listProductId"
          label="Sản phẩm"
          rules={[{ required: true, message: "Vui lòng chọn sản phẩm" }]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Chọn sản phẩm"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {products.map((product) => (
              <Option key={product.id} value={product.id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Khoang đã chọn">
          <Row gutter={16}>
            {columns.map((column, columnIndex) => (
              <Col key={columnIndex} span={4}>
                {column.map((chamber) => (
                  <div key={chamber.id}>{chamber.code}</div>
                ))}
              </Col>
            ))}
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportProductModal;
