import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQAQCInfo,
  fetchTaskAndOrder,
  setPage,
  setPageSize,
  updateQA,
} from "../../../redux/Slice/QAQCInfo/qaqcInfoSlice";
import {
  Button,
  Collapse,
  DatePicker,
  Input,
  message,
  Select,
  Space,
  Spin,
} from "antd";
import TableComponent from "../../../components/TableComponent";
import QAQCModal from "./EditQAQCModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const QAQCInfo = () => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
    orderProductId: null,
    taskProductId: null,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [orderOptions, setOrderOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);

  const qaqcInfo = useSelector((state) => state.qaqcInfo.qaqcInfo);
  const currentPage = useSelector((state) => state.qaqcInfo.currentPage);
  const pageSize = useSelector((state) => state.qaqcInfo.pageSize);
  const totalCount = useSelector((state) => state.qaqcInfo.totalCount);
  const status = useSelector((state) => state.qaqcInfo.status);
  const taskAndOrder = useSelector((state) => state.qaqcInfo.taskAndOrder);

  // Fetch data
  useEffect(() => {
    dispatch(fetchQAQCInfo({ index: currentPage, pageSize, ...searchParams }));
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    if (taskAndOrder) {
      setOrderOptions(taskAndOrder.qaOrderModels || []);
      setTaskOptions(taskAndOrder.qaTaskModels || []);
    }
  }, [taskAndOrder]);

  //search
  const handleSearch = () => {
    dispatch(setPage(1));
    dispatch(fetchQAQCInfo({ index: 1, pageSize, ...searchParams }));
  };

//pagination
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
    dispatch(fetchQAQCInfo({ index: page, pageSize, ...searchParams }));
  };

  //reset table
  const handleResetTable = () => {
    setSearchParams({
      codeSearch: "",
      orderProductId: null,
      taskProductId: null,
    });
    setSelectedDate(null);
    setOrderOptions([]);
    setTaskOptions([]);
    dispatch(fetchQAQCInfo({ index: 1, pageSize, codeSearch: "" }));
    setSelectedRowKeys([]);
  };

  //edit
  const handleEdit = () => {
    const selectedData = qaqcInfo.find(
      (item) => item.id === selectedRowKeys[0]
    );
    if (selectedData) {
      setInitialValues({
        code: selectedData.code,
        name: selectedData.name,
        quantityGood: selectedData.quantityGood,
        quantityBad: selectedData.quantityBad,
        dateStart: selectedData.dateStart,
        dateEnd: selectedData.dateEnd,
      });
      setModalVisible(true);
    }
  };
//update
  const handleModalOk = async (values) => {
    setLoading(true);
    const data = {
      code: values.code,
      name: values.name,
      quantityGood: values.quantityGood,
      quantityBad: values.quantityBad,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
    };
    try {
      await dispatch(
        updateQA({ qaProductId: selectedRowKeys[0], data })
      ).unwrap();
      message.success("Cập nhật thành công");
      setModalVisible(false);
      setSelectedRowKeys([]);
      dispatch(
        fetchQAQCInfo({ index: currentPage, pageSize, ...searchParams })
      );
    } catch (error) {
      message.error("Có lỗi xảy ra: " + error.message);
    } finally {
      setLoading(false);
    }
  };


  //date
  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    setSearchParams({
      ...searchParams,
      orderProductId: null,
      taskProductId: null,
    });
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      dispatch(fetchTaskAndOrder({ date: formattedDate }));
    } else {
      setOrderOptions([]);
      setTaskOptions([]);
      dispatch(
        fetchQAQCInfo({
          index: currentPage,
          pageSize,
          codeSearch: searchParams.codeSearch,
        })
      );
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedRowKeys([]);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (text, record, index) => `${index + 1}`,
    },
    {
      title: "Mã QA/QC",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Tên QA/QC",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mã đặt hàng",
      dataIndex: "orderProductCode",
      key: "orderProductCode",
      width: 180,
    },
    {
      title: "Tên đặt hàng",
      dataIndex: "orderProductName",
      key: "orderProductName",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Số lượng tốt",
      dataIndex: "quantityGood",
      key: "quantityGood",
    },
    {
      title: "Số lượng lỗi",
      dataIndex: "quantityBad",
      key: "quantityBad",
    },
    {
      title: "Ngày tạo",
      dataIndex: "dateStart",
      key: "dateStart",
      render: (text) => {
        return new Date(text).toLocaleDateString("vi-VN");
      },
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "dateEnd",
      key: "dateEnd",
      render: (text) => {
        return new Date(text).toLocaleDateString("vi-VN");
      },
    },
    {
      title: "Người tạo",
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  const dataSource = qaqcInfo.map((item, index) => {
    return {
      key: item.id,
      index: index,
      id: item.id,
      code: item.code,
      name: item.name,
      orderProductCode: item.orderProductCode,
      orderProductName: item.orderProductName,
      productCode: item.productCode,
      productName: item.productName,
      quantityGood: item.quantityGood,
      quantityBad: item.quantityBad,
      dateStart: item.dateStart,
      dateEnd: item.dateEnd,
      createdBy: item.createdBy,
    };
  });

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <Collapse defaultActiveKey={["7"]} size="small">
            <Collapse.Panel key="7" header="Tìm kiếm">
              <Space align="center">
                <Input
                  placeholder="Nhập mã QA/QC"
                  value={searchParams.codeSearch}
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      codeSearch: e.target.value,
                    })
                  }
                />
                <DatePicker
                  placeholder="Chọn ngày"
                  style={{ width: 150 }}
                  format="DD-MM-YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  allowClear
                />
                <Select
                  placeholder="Chọn đơn hàng"
                  style={{ width: 350, marginLeft: 10 }}
                  disabled={!selectedDate}
                  onChange={(value) =>
                    setSearchParams({ ...searchParams, orderProductId: value })
                  }
                  value={searchParams.orderProductId}
                  allowClear
                >
                  {orderOptions.map((order) => (
                    <Option key={order.id} value={order.id}>
                      {order.name}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Chọn công việc"
                  style={{ width: 350, marginLeft: 10 }}
                  disabled={!selectedDate}
                  onChange={(value) =>
                    setSearchParams({ ...searchParams, taskProductId: value })
                  }
                  value={searchParams.taskProductId}
                  allowClear
                >
                  {taskOptions.map((task) => (
                    <Option key={task.id} value={task.id}>
                      {task.code}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                >
                  Tìm kiếm
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={handleResetTable}
                  danger
                >
                  Xóa tìm
                </Button>
              </Space>
            </Collapse.Panel>
          </Collapse>
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
            />
          </Spin>
        </div>
        <Space style={{ marginTop: "10px" }}>
          <Button
            type="primary"
            style={{ backgroundColor: "#52c41a" }}
            onClick={handleResetTable}
          >
            Làm mới
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật sản phẩm
          </Button>
        </Space>
      </div>
      <QAQCModal
        visible={modalVisible}
        onCancel={handleCancel}
        onOk={handleModalOk}
        loading={loading}
        initialValues={initialValues}
      />
    </>
  );
};

export default QAQCInfo;
