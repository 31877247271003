// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-logo {
    width: 100%;
    object-fit: cover;
    background-color: white;
    margin: 0;
}

.custom-sidebar-menu {
    position: sticky;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 87vh !important;
    overflow-y: auto;
    font-size: 14px;
}

.custom-sidebar-menu .ant-menu-item.ant-menu-item-only-child,
.custom-sidebar-menu .ant-menu-submenu-title {
    height: auto; /* Adjust height to auto to handle text wrapping */
    line-height: normal; /* Adjust line-height for better text wrapping */
    padding: 8px 16px; /* Adjust padding for better spacing */
    white-space: normal; /* Allow text to wrap */
    font-weight: 600; /* Set font weight to match */
}

.custom-sidebar-menu .ant-menu-sub {
    background: #f0f2f5; /* Set background color for submenus */
}

.ant-layout-sider.ant-layout-sider-dark.sidebar {
    position: sticky;
    top: 0;
    background-color: transparent !important;
}

.sidebar {
    height: 100vh;
}
.fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensures it stays on top of other elements */
    width: 100%;
}

/* Ensure that the content does not overlap with the fixed header */
.ant-layout-content {
    padding-top: 50px; /* Thay đổi giá trị này phù hợp với chiều cao của header */
}

`, "",{"version":3,"sources":["webpack://./src/layouts/Defaultlayout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,eAAe;IACf,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;IAEI,YAAY,EAAE,kDAAkD;IAChE,mBAAmB,EAAE,gDAAgD;IACrE,iBAAiB,EAAE,sCAAsC;IACzD,mBAAmB,EAAE,uBAAuB;IAC5C,gBAAgB,EAAE,6BAA6B;AACnD;;AAEA;IACI,mBAAmB,EAAE,sCAAsC;AAC/D;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,wCAAwC;AAC5C;;AAEA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,MAAM;IACN,aAAa,EAAE,8CAA8C;IAC7D,WAAW;AACf;;AAEA,mEAAmE;AACnE;IACI,iBAAiB,EAAE,0DAA0D;AACjF","sourcesContent":[".primary-logo {\n    width: 100%;\n    object-fit: cover;\n    background-color: white;\n    margin: 0;\n}\n\n.custom-sidebar-menu {\n    position: sticky;\n    top: 0;\n    left: 0;\n    max-width: 100%;\n    height: 87vh !important;\n    overflow-y: auto;\n    font-size: 14px;\n}\n\n.custom-sidebar-menu .ant-menu-item.ant-menu-item-only-child,\n.custom-sidebar-menu .ant-menu-submenu-title {\n    height: auto; /* Adjust height to auto to handle text wrapping */\n    line-height: normal; /* Adjust line-height for better text wrapping */\n    padding: 8px 16px; /* Adjust padding for better spacing */\n    white-space: normal; /* Allow text to wrap */\n    font-weight: 600; /* Set font weight to match */\n}\n\n.custom-sidebar-menu .ant-menu-sub {\n    background: #f0f2f5; /* Set background color for submenus */\n}\n\n.ant-layout-sider.ant-layout-sider-dark.sidebar {\n    position: sticky;\n    top: 0;\n    background-color: transparent !important;\n}\n\n.sidebar {\n    height: 100vh;\n}\n.fixed-header {\n    position: sticky;\n    top: 0;\n    z-index: 1000; /* Ensures it stays on top of other elements */\n    width: 100%;\n}\n\n/* Ensure that the content does not overlap with the fixed header */\n.ant-layout-content {\n    padding-top: 50px; /* Thay đổi giá trị này phù hợp với chiều cao của header */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
