import { Button, Popconfirm, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import SearchComponent from "../../../components/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderProduct,
  fetchOrderProducts,
  postOrderProduct,
  setPage,
  setPageSize,
  updateOrderProduct,
  viewDetail,
} from "../../../redux/Slice/orderProductSlice/orderproductSlice";
import FormComponent from "./QLDHForm";
import moment from "moment-timezone";
import "moment/locale/vi"; // Import locale if you need Vietnamese locale
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import OrderDetailModal from "./OrderDetailModal";

const QuanLyDatHang = () => {
  const dispatch = useDispatch();
  const orderProducts = useSelector(
    (state) => state.quanLyDatHang.orderProducts
  );
  //detail
  const detailOrderProduct = useSelector(
    (state) => state.quanLyDatHang.detailOrderProduct
  );
  const currentPage = useSelector((state) => state.quanLyDatHang.currentPage);
  const pageSize = useSelector((state) => state.quanLyDatHang.pageSize);
  const totalCount = useSelector((state) => state.quanLyDatHang.totalCount);
  const status = useSelector((state) => state.quanLyDatHang.status);

  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [searchKey, setSearchKey] = useState(0);
  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
    productName: "",
    productCode: "",
    orderName: "",
  });

  // Fetch order product list
  useEffect(() => {
    fetchOrderProductList();
  }, [currentPage, pageSize, searchParams, dispatch]);

  const fetchOrderProductList = () => {
    dispatch(
      fetchOrderProducts({
        index: currentPage,
        pageSize,
        codeSearch: searchParams.codeSearch,
        orderName: searchParams.orderName,
        productName: searchParams.productName,
        productCode: searchParams.productCode,
      })
    );
  };

  //Open modal to add new order product
  const handleAdd = () => {
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  //select an order product to edit
  const handleEdit = () => {
    const record = orderProducts.find((item) => item.id === selectedRowKeys[0]);
    setEditingRecord({
      ...record,
      startAct: record.startAct
        ? moment(record.startAct).tz("Asia/Bangkok")
        : null,
      endAct: record.endAct ? moment(record.endAct).tz("Asia/Bangkok") : null,
      timeTransport: record.timeTransport
        ? moment(record.timeTransport).tz("Asia/Bangkok")
        : null,
    });
    setIsModalVisible(true);
  };

  //delete an order product
  const handleDelete = () => {
    dispatch(deleteOrderProduct(selectedRowKeys[0])).then((response) => {
      if (response.error || response.payload.errorCode) {
        message.error(response.payload.errorMessage || "Có lỗi xảy ra!");
      } else {
        message.success("Xóa đơn hàng thành công!");
        fetchOrderProductList();
      }
    });
  };

  // Reset search after add a new order
  const resetSearch = () => {
    setSearchParams({
      codeSearch: "",
      productName: "",
      productCode: "",
      orderName: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  //create or update order product
  const handleCreate = (values) => {
    setLoading(true);
    const formatWithTimezone = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss") + "+07:00" : null;
    };

    const payload = {
      ...values,
      startAct: formatWithTimezone(values.startAct),
      endAct: formatWithTimezone(values.endAct),
      timeTransport: formatWithTimezone(values.timeTransport),
    };

    if (editingRecord) {
      dispatch(
        updateOrderProduct({ id: editingRecord.id, orderProductData: payload })
      )
        .then((response) => {
          if (response.error || response.payload.errorCode) {
            message.error(response.payload.errorMessage || "Có lỗi xảy ra!");
          } else {
            message.success("Cập nhật thành công!");
            setIsModalVisible(false);
            fetchOrderProductList();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(postOrderProduct(payload))
        .then((response) => {
          console.log(response);
          if (response.error || response.payload.errorCode) {
            message.error(response.payload.errorMessage || "Có lỗi xảy ra!");
          } else {
            message.success("Thêm mới thành công!");
            setIsModalVisible(false);
            fetchOrderProductList();
            resetSearch();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  //close modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedRowKeys([]);
  };

  //handle page change
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  //reset table
  const handleResetTable = () => {
    fetchOrderProductList();
    setSelectedRowKeys([]);
  };

  // Format dates before rendering
  const formatDateTime = (dateTime) => {
    return dayjs(dateTime).format("DD/MM/YYYY");
  };

  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };
  //view detail
  const handleViewDetails = (id) => {
    dispatch(viewDetail(id)).then(() => {
      setIsDetailModalVisible(true);
    });
  };

  const handleDetailModalCancel = () => {
    setIsDetailModalVisible(false);
  };

  const dataSource = orderProducts.map((item, index) => ({
    key: item.id,
    id: item.id,
    code: item.code,
    name: item.name,
    description: item.description,
    totalQuantity: item.totalQuantity,
    quantityProduct: item.quantityProduct,
    quantityQA: item.quantityQA,
    productId: item.productId,
    productCode: item.productCode,
    productName: item.productName,
    startAct: formatDateTime(item.startAct),
    endAct: formatDateTime(item.endAct),
    dateStartEnd:
      formatDateTime(item.startAct) + " - " + formatDateTime(item.endAct),
    percentPass: item.percentPass,
    rateByDay: item.rateByDay,
    timeTransport: formatDateTime(item.timeTransport),
    createdBy: item.createdBy,
    createdTime: formatDateTime(item.createdTime),
    stt: index + 1,
  }));
  console.table(dataSource);
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 70,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Space>
            {text}
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => handleViewDetails(record.id)}
            />
          </Space>
        </div>
      ),
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "code",
      key: "code",
      width: 150,
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Tên đơn hàng",
      dataIndex: "name",
      key: "name",
      width: 200,
      // Example of setting CSS for ensuring text on one line only
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "productCode",
      key: "productCode",
      width: 150,
    },
    {
      title: "Sản phẩm",
      dataIndex: "productName",
      key: "productName",
      width: 200,
      // align: "center",
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },

    {
      title: "Số lượng sản phẩm",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      width: 150,
      align: "center",
    },
    {
      title: "Tổng sản lượng",
      dataIndex: "quantityProduct",
      key: "quantityProduct",
      width: 150,
      align: "center",
    },
    {
      title: "Tổng kiểm",
      dataIndex: "quantityQA",
      key: "quantityQA",
      width: 100,
      align: "center",
    },
    {
      title: "Dự kiến",
      dataIndex: "dateStartEnd",
      key: "dateStartEnd",
      width: 200,
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdTime",
      key: "createdTime",
      width: 150,
    },
    {
      title: "Tạo bởi",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 150,
      render: (text) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            key={searchKey}
            onSearch={handleSearch}
            searchFields={[
              { key: "codeSearch", placeholder: "Mã đơn hàng" },
              { key: "orderName", placeholder: "Tên đơn hàng" },
              { key: "productName", placeholder: "Tên sản phẩm" },
              { key: "productCode", placeholder: "Mã sản phẩm" },
            ]}
          />
        </div>

        <div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            marginTop: "5px",
            overflowX: "auto",
          }}
        >
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
              scroll={{ x: 1800 }} // Example width for horizontal scroll
            />
          </Spin>
        </div>

        <Space>
          <Button
            type="primary"
            onClick={handleResetTable}
            style={{ backgroundColor: "#52c41a" }}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Thêm đơn hàng
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa đơn hàng không không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa 
            </Button>
          </Popconfirm>
        </Space>

        {/* Form component */}

        <FormComponent
          visible={isModalVisible}
          onCreate={handleCreate}
          onCancel={handleCancel}
          isEditing={!!editingRecord} // Dùng !! để chuyển đổi thành boolean
          initialValues={editingRecord} // Sử dụng initialValues khi chỉnh sửa
        />
        <OrderDetailModal
          visible={isDetailModalVisible}
          onCancel={handleDetailModalCancel}
          data={detailOrderProduct}
        />
      </div>
    </>
  );
};

export default QuanLyDatHang;
