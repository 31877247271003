import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, message } from "antd";
import { fetchRoles } from "../../redux/Slice/roleSlice";
import { useDispatch } from "react-redux";
import { signUp } from "../../redux/Slice/loginSlice"; // Adjust the path as necessary
import { fetchUsers } from "../../redux/Slice/userSlice";

const { Option } = Select;

const FormComponent = ({
  visible,
  onCreate,
  onCancel,
  initialValues,
  searchParams,
}) => {
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
      setLoading(true);
      fetchRoles()
        .then((data) => {
          setRoles(data);
          setLoading(false);
        })
        .catch(() => {
          message.error("Failed to fetch roles.");
          setLoading(false);
        });
    }
  }, [visible, initialValues, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        dispatch(signUp(values))
          .unwrap()
          .then(() => {
            message.success("Registration successful!");
            dispatch(
              fetchUsers({ pageIndex: 1, pageSize: 10, ...searchParams })
            );
            onCreate(values);
          })
          .catch((err) => {
            message.error(err || "Registration failed. Please try again.");
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Cập nhật người dùng" : "Thêm mới người dùng"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValues}
      >
        <Form.Item
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: true, message: "Vui lòng nhập tên đăng nhập!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: false, message: "Vui lòng nhập email!" },
            { type: "email", message: "Vui lòng nhập email hợp lệ!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="fullname"
          label="Họ và tên"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phoneNumber" // Thêm trường phoneNumber vào form
          label="Số điện thoại"
          rules={[{ required: false, message: "Vui lòng nhập số điện thoại!" }]} // Cập nhật rules nếu cần
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Vai trò"
          rules={[{ required: true, message: "Vui lòng chọn vai trò!" }]}
        >
          <Select
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            placeholder="Chọn vai trò"
            loading={loading}
            allowClear
            showSearch
            options={roles.map((role) => ({
              value: role.normalizedName,
              label: role.fullName,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
