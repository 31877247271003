import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  DatePicker,
  InputNumber,
  Select,
  message,
  Radio,
} from "antd";

import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchModule,
  fetchOrderProducts,
} from "../../redux/Slice/TaskSlice/taskSlice";

dayjs.extend(utc);
dayjs.extend(timezone);

const AddForm = ({ visible, onCreate, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const module = useSelector((state) => state.task.module);
  const orderProducts = useSelector((state) => state.task.orderproducts);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    dispatch(fetchModule({ index: 1, pageSize: 1000 }));
    dispatch(fetchOrderProducts({ index: 1, pageSize: 1000 }));
  }, []);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.dateStart = dayjs(values.dateStart)
          .tz("Asia/Ho_Chi_Minh")
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        values.dateEnd = dayjs(values.dateEnd)
          .tz("Asia/Ho_Chi_Minh")
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      centered
      visible={visible}
      title="Thêm mới công việc"
      okText="Lưu"
      cancelText="Hủy"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="horizontal"
        name="add_form"
        initialValues={{
          dateStart: null,
          dateEnd: null,
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
      >
        <Form.Item
          name="code"
          label="Mã"
          rules={[{ required: true, message: "Xin nhập mã công việc!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Xin nhập tên công việc!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="target"
          label="Mục tiêu"
          rules={[{ required: true, message: "Xin nhập mục tiêu!" }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="owe"
          label="OWE"
          rules={[{ required: true, message: "Xin nhập số lượng thiếu!" }]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="hasBreakTime"
          label="Giờ nghỉ"
          rules={[
            {
              required: true,
              message: "Xin chọn có hoặc không",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Có</Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="dateStart"
          label="Ngày bắt đầu"
          rules={[{ required: true, message: "Xin chọn ngày bắt đầu!" }]}
        >
          <DatePicker format="DD-MM-YYYY | HH:mm:ss" showTime locale={locale} />
        </Form.Item>
        <Form.Item
          name="dateEnd"
          label="Ngày kết thúc"
          rules={[{ required: true, message: "Xin chọn thời gian kết thúc!" }]}
        >
          <DatePicker format="DD-MM-YYYY | HH:mm:ss" showTime locale={locale} />
        </Form.Item>
        <Form.Item
          name="listModuleId"
          label="Mã chuyền"
          rules={[{ required: true, message: "Xin chọn mã chuyền!" }]}
        >
          <Select
            mode="multiple"
            placeholder="Chọn mã chuyền"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={module.map((item) => {
              return { label: item.name, value: item.id };
            })}
          />
        </Form.Item>
        <Form.Item
          name="orderProductId"
          label="Mã đặt hàng"
          rules={[{ required: true, message: "Xin chọn mã sản phẩm!" }]}
        >
          <Select
            showSearch
            placeholder="Chọn mã đặt hàng"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={orderProducts.map((item) => {
              return { label: item.name, value: item.id };
            })}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Ghi chú"
          rules={[{ required: true, message: "Nhập ghi chú công việc này!" }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddForm;
