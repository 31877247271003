import React from "react";
import { Modal, Table } from "antd";

const QLCDetail = ({ visible, onCancel, taskProducts }) => {
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => index + 1,
      width: 60,
      align: "center",
    },
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên công việc",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mục tiêu",
      dataIndex: "target",
      key: "target",
      align: "center",
    },
  ];

  return (
    <Modal
      title="Chi tiết công việc"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Table
        dataSource={taskProducts}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};

export default QLCDetail;
