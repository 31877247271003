import React from "react";
import { Modal, Table, Empty } from "antd";

const CustomEmpty = () => (
  <div>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<p>Sản phẩm không có trong khoang nào</p>}
    />
  </div>
);

const ViewListChamberModal = ({ visible, chambers, onClose }) => {
  const chamberColumns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
    },
    {
      title: "Danh sách kho",
      dataIndex: "chamber",
      key: "chamber",
      render: (text) =>
        text ? text : <Empty description="Không có danh sách kho" />,
    },
  ];

  const dataSource = chambers.map((chamber, index) => ({
    key: index,
    stt: index + 1,
    chamber,
  }));

  return (
    <Modal
      visible={visible}
      title="Danh sách kho"
      onCancel={onClose}
      footer={null}
      afterClose={() => {}}
      centered
    >
      <Table
        dataSource={dataSource}
        columns={chamberColumns}
        pagination={false}
        locale={{ emptyText: <CustomEmpty /> }}
      />
    </Modal>
  );
};

export default ViewListChamberModal;
