// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchFieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Khoảng cách giữa các searchField */
}

.searchField {
  flex: 1 1;
  min-width: 120px;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS,EAAE,qCAAqC;AAClD;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".searchFieldsContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px; /* Khoảng cách giữa các searchField */\n}\n\n.searchField {\n  flex: 1;\n  min-width: 120px;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
