import { Empty, Modal, Table, Input, Button, message, Space } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchTaskDetail,
  updateTaskDetail,
} from "../../redux/Slice/TaskSlice/taskSlice";
import { EditOutlined } from "@ant-design/icons";

const CustomEmpty = () => (
  <div>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<p>Không có chi tiết công việc</p>}
    />
  </div>
);

const ViewDetail = ({ visible, onCancel, data, taskId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({});
  const pageSize = 5;
  const dispatch = useDispatch();

  useEffect(() => {
    if (taskId) {
      dispatch(fetchTaskDetail(taskId));
    }
  }, [taskId, dispatch]);

  const handleEdit = (record) => {
    setIsEditing(record.id);
    setEditData({ ...record });
  };

  const handleCancelEdit = () => {
    setIsEditing(null);
    setEditData({});
    dispatch(fetchTaskDetail(taskId));
  };

  const handleSaveEdit = async () => {
    try {
      await dispatch(
        updateTaskDetail({ taskDetailId: isEditing, data: editData })
      ).unwrap();
      message.success("Cập nhật công việc thành công!");
      setIsEditing(null);
      dispatch(fetchTaskDetail(taskId));
    } catch (error) {
      message.error("Cập nhật công việc thất bại!");
    }
  };

  const handleInputChange = (e, field) => {
    setEditData({ ...editData, [field]: e.target.value });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      render: (text, record) =>
        isEditing === record.id ? (
          <Input
            value={editData.code}
            onChange={(e) => handleInputChange(e, "code")}
          />
        ) : (
          text
        ),
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        isEditing === record.id ? (
          <Input
            value={editData.name}
            onChange={(e) => handleInputChange(e, "name")}
          />
        ) : (
          text
        ),
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
      render: (text, record) =>
        isEditing === record.id ? (
          <Input
            value={editData.description}
            onChange={(e) => handleInputChange(e, "description")}
          />
        ) : (
          text
        ),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) =>
        isEditing === record.id ? (
          <Input
            value={editData.quantity}
            onChange={(e) => handleInputChange(e, "quantity")}
          />
        ) : (
          text
        ),
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "dateStart",
      key: "dateStart",
      render: (text) => dayjs(text).format("DD-MM-YYYY | HH:mm:ss"),
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "dateEnd",
      key: "dateEnd",
      render: (text) => dayjs(text).format("DD-MM-YYYY | HH:mm:ss"),
    },
    {
      title: "Cập nhật",
      key: "action",
      align: "center",
      render: (text, record) => {
        const editable = isEditing === record.id;
        return editable ? (
          <Space>
            <Button type="primary" onClick={handleSaveEdit}>
              Lưu
            </Button>
            <Button danger onClick={handleCancelEdit}>
              Huỷ
            </Button>
          </Space>
        ) : (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Cập nhật
          </Button>
        );
      },
    },
  ];

  const dataSource = Array.isArray(data)
    ? data.map((item, index) => ({
        stt: index + 1,
        id: item.id,
        key: item.id,
        code: item.code,
        name: item.name,
        description: item.description,
        quantity: item.quantity,
        dateStart: item.dateStart,
        dateEnd: item.dateEnd,
      }))
    : [];

  return (
    <Modal
      title="Chi tiết công việc"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={1500}
    >
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
        locale={{ emptyText: <CustomEmpty /> }}
      />
    </Modal>
  );
};

export default ViewDetail;
