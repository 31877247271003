import React from "react";
import { Modal, Form, Input, InputNumber } from "antd";

const ExportModal = ({ visible, onCancel, product, onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      visible={visible}
      title="Xuất kho"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.submit();
      }}
      cancelText="Hủy"
      okText="Xác nhận"
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={onSubmit}
        initialValues={{
          code: "",
          name: "",
          description: "",
          productName: product?.name,
          itemPerBox: product?.itemPerBox,
          quantity: "",
        }}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        labelAlign="left"
      >
        <Form.Item
          label="Mã xuất kho"
          name="code"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập mã xuất kho",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên xuất kho"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên xuất kho",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Tên sản phẩm" name="productName">
          <Input defaultValue={product?.name} disabled />
        </Form.Item>
        <Form.Item name="itemPerBox" label="Số sản phẩm mỗi thùng">
          <InputNumber value={product?.itemPerBox} disabled />
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Số lượng"
          rules={[{ required: true, message: "Vui lòng nhập số lượng" }]}
        >
          <InputNumber min={1} style={{ width: "50%" }} />
        </Form.Item>
        <Form.Item label="Mô tả" name="description">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportModal;
