import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Space, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "../../../components/SearchComponent";
import TableComponent from "../../../components/TableComponent";
import FormComponent from "./DSSPForm";
import {
  createProduct,
  deleteProduct,
  fetchProducts,
  fetchCategories,
  setPage,
  setPageSize,
  updateProduct,
} from "../../../redux/Slice/productSlice/productSlice";
import ViewListChamberModal from "./ViewListChamberModal";
import { EyeOutlined } from "@ant-design/icons";

const QuanLySanPham = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.quanLySanPham.products);
  const currentPage = useSelector((state) => state.quanLySanPham.currentPage);
  const pageSize = useSelector((state) => state.quanLySanPham.pageSize);
  const totalCount = useSelector((state) => state.quanLySanPham.totalCount);
  const status = useSelector((state) => state.quanLySanPham.status);
  const categories = useSelector((state) => state.quanLySanPham.categories);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState(0);
  const [isChamberModalVisible, setIsChamberModalVisible] = useState(false);
  const [chamberList, setChamberList] = useState([]);

  const [searchParams, setSearchParams] = useState({
    searchByCode: "",
    searchByName: "",
    searchByCategoryId: "",
  });

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    fetchProductList();
  }, [dispatch, currentPage, pageSize, searchParams]);

  // Fetch product list
  const fetchProductList = () => {
    dispatch(fetchProducts({ index: currentPage, pageSize, ...searchParams }));
  };

  // Open modal to add a new product
  const handleAdd = () => {
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  // Select a product to edit
  const handleEdit = () => {
    const record = products.find((item) => item.id === selectedRowKeys[0]);
    if (!record) {
      message.error("Sản phẩm đã chọn không tồn tại!");
      return;
    }
    setEditingRecord(record);
    setIsModalVisible(true);
  };

  // Delete a product
  const handleDelete = () => {
    dispatch(deleteProduct(selectedRowKeys[0]))
      .unwrap()
      .then(() => {
        message.success("Xóa sản phẩm thành công!");
        fetchProductList();
      })
      .catch(() => {});
  };

  // Create or update product
  const handleCreateOrUpdate = (values, operationType) => {
    setLoading(true);
    values.sam = Number(values.sam); // Ensure `sam` is a number

    if (operationType === "edit" && editingRecord) {
      dispatch(updateProduct({ id: editingRecord.id, productData: values }))
        .unwrap()
        .then(() => {
          message.success("Cập nhật sản phẩm thành công!");
          closeModal();
          fetchProductList();
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(createProduct(values))
        .unwrap()
        .then(() => {
          message.success("Thêm sản phẩm thành công!");
          closeModal();
          fetchProductList();
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Close modal
  const closeModal = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedRowKeys([]);
  };

  // Search product
  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };

  // Handle page change
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  // Reset table
  const handleResetTable = () => {
    fetchProductList();
    setSelectedRowKeys([]);
  };

  // Reset search parameters and fetch all products after create new
  const resetSearch = () => {
    setSearchParams({
      searchByCode: "",
      searchByName: "",
      searchByCategoryId: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  //Opan modal to view chambers
  const handleViewChambers = (chambers) => {
    setChamberList(chambers);
    setIsChamberModalVisible(true);
  };

  const dataSource = products
    .map((item, index) => {
      if (!item || !item.id) {
        console.error("Invalid product item", item);
        return null;
      }
      return {
        key: item.id,
        id: item.id,
        code: item.code,
        name: item.name,
        sam: item.sam,
        categoryName: item.categoryName,
        description: item.description,
        totalProductInChambers: item.totalProductInChambers,
        listCodeChamber: item.listCodeChamber,
        stt: index + 1,
      };
    })
    .filter((item) => item !== null);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 50,
      align: "center",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "code",
      key: "code",
      width: 150,
      align: "center",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sam",
      dataIndex: "sam",
      key: "sam",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tổng sản phẩm trong kho",
      dataIndex: "totalProductInChambers",
      key: "totalProductInChambers",
      align: "center",
      width: 200,
    },
    {
      title: "Danh sách kho",
      dataIndex: "listCodeChamber",
      align: "center",
      key: "listCodeChamber",
      render: (text, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => handleViewChambers(record.listCodeChamber)}
        />
      ),
    },
    {
      title: "Danh mục",
      dataIndex: "categoryName",
      key: "categoryName",
    },
  ];

  const categoryOptions = categories.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            key={searchKey}
            onSearch={handleSearch}
            searchFields={[
              { key: "searchByCode", placeholder: "Mã sản phẩm" },
              { key: "searchByName", placeholder: "Tên sản phẩm" },
              {
                key: "searchByCategoryId",
                placeholder: "Chọn danh mục",
                type: "select",
                options: categoryOptions,
              },
            ]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <Spin spinning={loading || status === "loading"}>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePageChange,
              }}
            />
          </Spin>
        </div>
        <Space style={{ marginTop: "10px" }}>
          <Button
            type="primary"
            style={{ backgroundColor: "#52c41a" }}
            onClick={handleResetTable}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Thêm mới
          </Button>
          <Button
            type="default"
            disabled={selectedRowKeys.length === 0}
            onClick={handleEdit}
          >
            Cập nhật sản phẩm
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa sản phẩm không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa sản phẩm
            </Button>
          </Popconfirm>
        </Space>
        {/* Modal form to create or update product */}
        <FormComponent
          visible={isModalVisible}
          onCreate={(values) =>
            handleCreateOrUpdate(values, editingRecord ? "edit" : "create")
          }
          onCancel={closeModal}
          initialValues={editingRecord}
          key={editingRecord ? editingRecord.id : "new"}
        />
      </div>
      <ViewListChamberModal
        visible={isChamberModalVisible}
        chambers={chamberList}
        onClose={() => setIsChamberModalVisible(false)}
      />
    </>
  );
};

export default QuanLySanPham;
