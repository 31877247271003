import {
  getRequestParams,
  deleteRequest,
  putRequest,
  postRequest,
} from "../../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchTaskProducts = createAsyncThunk(
  "taskSlice/fetchTaskProducts",
  async ({ index, pageSize, codeSearch }) => {
    const params = { index, pageSize, codeSearch };
    const response = await getRequestParams("/taskproduct", params);
    return response.data;
  }
);

export const createTaskProduct = createAsyncThunk(
  "taskSlice/createTaskProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest("/taskproduct", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTaskProduct = createAsyncThunk(
  "taskSlice/deleteTaskProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`/taskproduct?id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaskProduct = createAsyncThunk(
  " taskSlice/updateTaskProduct",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/taskproduct?id=${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTaskDetail = createAsyncThunk(
  "taskSlice/fetchTaskDetail",
  async (id) => {
    try {
      const response = await getRequestParams(`/taskproduct/${id}/taskdetail`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch task details");
    }
  }
);

export const updateTaskDetail = createAsyncThunk(
  "taskSlice/updateTaskDetail",
  async ({ taskDetailId, data }, { rejectWithValue }) => {
    try {
      const response = await putRequest(`/taskproduct${taskDetailId}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//fetch module
export const fetchModule = createAsyncThunk(
  "taskSlice/fetchModule",
  async ({ index, pageSize }) => {
    const params = { index, pageSize };
    const response = await getRequestParams("/modules", params);
    return response.data;
  }
);

//fetch product
export const fetchOrderProducts = createAsyncThunk(
  "taskSlice/fetchOrderProducts",
  async ({ index, pageSize }) => {
    const params = { index, pageSize };
    const response = await getRequestParams("/orderproducts", params);
    return response.data;
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState: {
    taskProducts: [],
    taskDetail: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    orderproducts: [],
    module: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch task products
      .addCase(fetchTaskProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskProducts = action.payload.data.result.items;
        state.totalCount = action.payload.data.result.totalCount;
      })
      .addCase(fetchTaskProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create task product
      .addCase(createTaskProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTaskProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskProducts.push(action.payload);
      })
      .addCase(createTaskProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete task product

      .addCase(deleteTaskProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTaskProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.taskProducts.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.taskProducts.splice(index, 1);
        }
      })
      .addCase(deleteTaskProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update task product
      .addCase(updateTaskProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTaskProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.taskProducts.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.taskProducts[index] = action.payload;
        }
      })
      .addCase(updateTaskProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch task details
      .addCase(fetchTaskDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskDetail = action.payload.data;
      })
      .addCase(fetchTaskDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //update task detail
      .addCase(updateTaskDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTaskDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskDetail = action.payload;
      })
      .addCase(updateTaskDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch module
      .addCase(fetchModule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.module = action.payload.data.items;
      })
      .addCase(fetchModule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Fetch product
      .addCase(fetchOrderProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderproducts = action.payload.data.items;
      })
      .addCase(fetchOrderProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { setPage, setPageSize } = taskSlice.actions;
export default taskSlice.reducer;
