import { Modal, Table, Tabs, Empty, Button, message, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInventoryHistory,
  clearHistory,
  deleteHistory,
  setPage,
  setPageSize,
} from "../../redux/Slice/Warehouse/viewInventorySlice";
import SearchComponent from "../../components/SearchComponent";

const ViewInventory = ({ visible, onCancel, idChamber }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("2");
  const [searchParams, setSearchParams] = useState({
    idSearch: "",
    codeSearch: "",
  });

  const {
    status,
    importHistory,
    exportHistory,
    currentPage,
    pageSize,
    totalCount,
  } = useSelector((state) => state.viewInventory);

  // Lấy dữ liệu lịch sử nhập kho và xuất kho
  useEffect(() => {
    if (visible) {
      const historyType = activeTab === "2" ? 2 : 3;
      dispatch(
        fetchInventoryHistory({
          index: currentPage,
          pageSize,
          codeSearch: "",
          history: historyType,
          idChamber: idChamber.id,
        })
      );
    } else {
      dispatch(clearHistory()); // Clear lịch sử khi modal bị đóng
    }
  }, [
    visible,
    dispatch,
    idChamber,
    activeTab,
    currentPage,
    pageSize,
    searchParams.codeSearch,
  ]);

  // Xử lý khi chuyển tab
  const handleTabChange = (key) => {
    setActiveTab(key);
    const historyType = key === "2" ? 2 : 3;
    dispatch(
      fetchInventoryHistory({
        index: 1,
        pageSize,
        codeSearch: "",
        history: historyType,
        idChamber: idChamber.id,
      })
    );
    dispatch(setPage(1));
  };

  const handleSearch = (values) => {
    setSearchParams(values);
    const historyType = activeTab === "2" ? 2 : 3;
    dispatch(
      fetchInventoryHistory({
        index: 1,
        pageSize: 100,
        codeSearch: values.codeSearch,
        history: historyType,
        idChamber: idChamber.id,
      })
    );
    dispatch(setPage(1));
  };

  //delete history
  const handleDelete = (record) => {
    dispatch(
      deleteHistory({ chamberId: record.chamberId, historyId: record.id })
    )
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          message.success(
            `Huỷ ${activeTab === "2" ? "nhập" : "xuất"} kho thành công!`
          );
          handleTabChange(activeTab); // Refresh data
        } else {
          message.error(
            `Huỷ ${activeTab === "2" ? "nhập" : "xuất"} kho thất bại!`
          );
        }
      })
      .catch(() => {
        message.error(
          `Huỷ ${activeTab === "2" ? "nhập" : "xuất"} kho thất bại!`
        );
      });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
      align: "center",
    },
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      width: 120,
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
    },
    {
      title: "Số sản phẩm mỗi thùng	",
      align: "center",
      dataIndex: "itemPerBox",
      key: "itemPerBox",
      render: (text) => {
        return (
          <>
            <p>{text} cái/thùng</p>
          </>
        );
      },
    },
    {
      title: "Nội dung",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (text) => {
        const formattedDate = new Date(text).toLocaleDateString("vi-VN");
        return formattedDate;
      },
    },
    {
      title: "Huỷ",
      align: "center",
      render: (record) => (
        <Popconfirm
          title={`Bạn có chắc chắn muốn huỷ ${
            activeTab === "2" ? "nhập" : "xuất"
          } kho với mã ${record.code}? 
           `}
          description={
            <>
              <p>- Tên sản phẩm: {record.productName}</p>
              <p>- Số lượng: {record.quantity}</p>
            </>
          }
          onConfirm={() => handleDelete(record)}
          okText="Xác nhận"
          cancelText="Không"
        >
          <Button type="primary" danger>
            {activeTab === "2" ? "Huỷ nhập kho" : "Huỷ xuất kho"}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  // Format dữ liệu để hiển thị lịch sử nhập kho
  const dataImport = importHistory.map((item, index) => ({
    key: item.id,
    id: item.id,
    stt: index + 1,
    code: item.code,
    name: item.name,
    productName: item.productName,
    quantity: item.quantity,
    description: item.description,
    itemPerBox: item.itemPerBox,
    createdTime: item.createdTime,
    chamberId: item.chamberId,
  }));

  // Format dữ liệu xuất kho
  const dataExport = exportHistory.map((item, index) => ({
    key: item.id,
    id: item.id,
    stt: index + 1,
    code: item.code,
    name: item.name,
    productName: item.productName,
    quantity: item.quantity,
    description: item.description,
    itemPerBox: item.itemPerBox,
    createdTime: item.createdTime,
    chamberId: item.chamberId,
  }));

  // Render table khi không có data sẽ hiển thị thông báo "Không có lịch sử"
  const renderTable = (data) => {
    if (data.length === 0) {
      return <Empty description={<p>Không có lịch sử xuất / nhập kho </p>} />;
    }
    return (
      <Table
        dataSource={data}
        columns={columns}
        loading={status === "loading"}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        scroll={{ y: 400 }}
      />
    );
  };

  const items = [
    {
      key: "2",
      label: "Nhập kho",
      children: (
        <>
          <SearchComponent
            searchFields={[{ key: "codeSearch", placeholder: "Mã nhập kho" }]}
            onSearch={handleSearch}
          />
          {renderTable(dataImport)}
        </>
      ),
    },
    {
      key: "3",
      label: "Xuất kho",
      children: (
        <>
          <SearchComponent
            searchFields={[{ key: "codeSearch", placeholder: "Mã xuất kho" }]}
            onSearch={handleSearch}
          />
          {renderTable(dataExport)}
        </>
      ),
    },
  ];

  //
  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
    const historyType = activeTab === "2" ? 2 : 3;
    dispatch(
      fetchInventoryHistory({
        index: page,
        pageSize,
        codeSearch: searchParams.codeSearch,
        history: historyType,
        idChamber: idChamber.id,
      })
    );
  };

  return (
    <div>
      <Modal
        centered
        visible={visible}
        footer={null}
        onCancel={onCancel}
        width={1500}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
      </Modal>
    </div>
  );
};

export default ViewInventory;
