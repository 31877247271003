import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../redux/Slice/productSlice/productSlice";

const FormComponent = ({ visible, onCreate, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.quanLySanPham.categories);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [visible, initialValues, form]);

  useEffect(() => {
    if (visible) {
      dispatch(fetchCategories());
    }
  }, [dispatch, visible]);

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật loại sản phẩm" : "Thêm mới loại sản phẩm"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={initialValues}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name="code"
          label="Mã loại sản phẩm"
          rules={[{ required: true, message: "Vui lòng nhập mã sản phẩm!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên loại sản phẩm"
          rules={[{ required: true, message: "Vui lòng nhập tên sản phẩm!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả"
          rules={[{ required: true, message: "Vui lòng nhập mô tả!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
