import React, { useState, useEffect } from "react";
import {
  Steps,
  Button,
  Space,
  message,
  Pagination,
  Dropdown,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import ListCard from "./ListCard";
import ModalForm from "./ModalForm";
import {
  deleteLineProduct,
  fetchLineProducts,
  postLineProduct,
  setPage,
  setPageSize,
  updateLineProduct,
} from "../../redux/Slice/Warehouse/lineProductSlice";
import {
  deleteFloorProduct,
  fetchFloorProducts,
  postFloorProduct,
  updateFloorProduct,
  setPageFloor,
  setPageSizeFoor,
  clearFloorProducts,
} from "../../redux/Slice/Warehouse/floorProductSlice";
import {
  deleteChamber,
  fetchChambers,
  postChamber,
  updateChamber,
  setPageChamber,
  setPageSizeChamber,
  clearChambers,
} from "../../redux/Slice/Warehouse/chambersSlice";
import SearchComponent from "../../components/SearchComponent";
import ProductFormModal from "./AddProductToWare";
import { RollbackOutlined } from "@ant-design/icons";
import ExportProduct from "./ExportProduct";
import ViewInventory from "./ViewInventory";
import ExcelExportDropdown from "./ExportExcel";

const { Step } = Steps;

const QuanLyKho = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [productModalVisible, setProductModalVisible] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [inventoryModalVisible, setInventoryModalVisible] = useState(false);

  const lineProducts = useSelector((state) => state.lineProducts.lineProducts);
  const floorProducts = useSelector(
    (state) => state.floorProducts.floorProducts
  );
  const chambers = useSelector((state) => state.chambers.chambers);

  const lineProductPagination = useSelector((state) => ({
    currentPage: state.lineProducts.currentPage,
    pageSize: state.lineProducts.pageSize,
    totalPages: state.lineProducts.totalPages,
    loading: state.lineProducts.loading,
    totalCount: state.lineProducts.totalCount,
  }));

  const floorProductPagination = useSelector((state) => ({
    currentPage: state.floorProducts.currentPage,
    pageSize: state.floorProducts.pageSize,
    totalCount: state.floorProducts.totalCount,
    loading: state.floorProducts.loading,
  }));

  const chamberPagination = useSelector((state) => ({
    currentPage: state.chambers.currentPage,
    pageSize: state.chambers.pageSize,
    totalCount: state.chambers.totalCount,
    loading: state.chambers.loading,
  }));

  useEffect(() => {
    if (currentStep === 0) {
      dispatch(
        fetchLineProducts({
          index: lineProductPagination.currentPage,
          pageSize: lineProductPagination.pageSize,
          codeSearch: "",
        })
      );
    } else if (currentStep === 1 && selectedId) {
      dispatch(
        fetchFloorProducts({
          index: floorProductPagination.currentPage,
          pageSize: floorProductPagination.pageSize,
          codeSearch: "",
          idLineProduct: selectedId,
        })
      );
      dispatch(clearFloorProducts());
    } else if (currentStep === 2 && selectedId) {
      dispatch(
        fetchChambers({
          index: chamberPagination.currentPage,
          pageSize: chamberPagination.pageSize,
          codeSearch: "",
          idFloor: selectedId,
        })
      );
      dispatch(clearChambers());
    }
  }, [
    currentStep,
    selectedId,
    dispatch,
    lineProductPagination.currentPage,
    floorProductPagination.currentPage,
    chamberPagination.currentPage,
    lineProductPagination.pageSize,
    floorProductPagination.pageSize,
    chamberPagination.pageSize,
  ]);

  //func truyền id để xem step tiếp theo
  const handleViewDetails = (id) => {
    setSelectedId(id);
    setCurrentStep((prevStep) => prevStep + 1);
    setSelectedItem(null);
  };

  //Func đang ở step 1 hoặc hoặc  sẽ back về
  const handleBack = () => {
    setSelectedId(null);
    setSelectedItem(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  //search
  const handleSearch = (values) => {
    if (currentStep === 0) {
      dispatch(
        fetchLineProducts({ index: 1, pageSize: 10, codeSearch: values.code })
      );
    } else if (currentStep === 1) {
      dispatch(
        fetchFloorProducts({
          index: 1,
          pageSize: 10,
          codeSearch: values.code,
          idLineProduct: selectedId,
        })
      );
    } else if (currentStep === 2) {
      dispatch(
        fetchChambers({
          index: 1,
          pageSize: 10,
          codeSearch: values.code,
          nameProduct: values.nameProduct,
          idFloor: selectedId,
        })
      );
    }
  };

  //func mở moda tạo mới item
  const handleAdd = () => {
    setEditItem(null);
    setModalVisible(true);
  };

  //func mở modal sửa 1 item
  const handleEdit = () => {
    if (!selectedItem) {
      message.warning("Vui lòng chọn một mục để sửa");
      return;
    }
    setEditItem(selectedItem);
    setModalVisible(true);
  };

  //Func xử lý khi xóa một mục
  const handleDelete = () => {
    if (!selectedItem) {
      message.warning("Vui lòng chọn một mục để xóa");
      return;
    }
    //Nếu đang ở step 0 thì xóa lineProduct
    if (currentStep === 0) {
      dispatch(deleteLineProduct(selectedItem.id))
        .unwrap()
        .then(() => {
          message.success("Xoá thành công");
          dispatch(
            fetchLineProducts({ index: 1, pageSize: 10, codeSearch: "" })
          );
        })
        .catch((error) => {});
      //Nếu đang ở step 1 thì xoá floorProduct
    } else if (currentStep === 1) {
      dispatch(deleteFloorProduct(selectedItem.id))
        .unwrap()
        .then(() => {
          message.success("Xoá thành công");
          dispatch(
            fetchFloorProducts({
              index: 1,
              pageSize: 10,
              codeSearch: "",
              idLineProduct: selectedId,
            })
          );
        })
        .catch((error) => {});
      //Nếu đang ở step 2 thì xoá chamber
    } else if (currentStep === 2) {
      dispatch(deleteChamber(selectedItem.id))
        .unwrap()
        .then(() => {
          dispatch(
            fetchChambers({
              index: 1,
              pageSize: 10,
              codeSearch: "",
              idFloor: selectedId,
            })
          );
          message.success("Xóa thành công");
        })
        .catch((error) => {});
    }
    setSelectedItem(null);
    selectedId && setSelectedId(null);
    setEditItem(null);
  };

  //Func xử lý khi submit form modal
  const handleModalSubmit = (values) => {
    //Nếu có editItem tức là đang sửa
    if (editItem) {
      //nếu đang ở step 0 thì update lineProduct
      if (currentStep === 0) {
        dispatch(
          updateLineProduct({ idLineProduct: editItem.id, data: values })
        )
          .unwrap()
          .then(() => {
            message.success("Cập nhật dãy thành công");
            dispatch(
              fetchLineProducts({ index: 1, pageSize: 10, codeSearch: "" })
            );
          })
          .catch((error) => {});
        //nếu đang ở step 1 thì update floorProduct
      } else if (currentStep === 1) {
        dispatch(
          updateFloorProduct({
            idFloorProduct: editItem.id,
            data: { ...values, lineProductId: selectedId },
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchFloorProducts({
                index: 1,
                pageSize: 10,
                codeSearch: "",
                idLineProduct: selectedId,
              })
            );
            message.success("Cập nhật tầng thành công");
          })
          .catch((error) => {});
        //nếu đang ở step 2 thì update chamber
      } else if (currentStep === 2) {
        dispatch(
          updateChamber({
            idChamber: editItem.id,
            data: { ...values, floorStockId: selectedId },
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchChambers({
                index: 1,
                pageSize: 10,
                codeSearch: "",
                idFloor: selectedId,
              })
            );
            message.success("Cập nhật khoang thành công");
          })
          .catch((error) => {});
      }
      //Nếu không có editItem tức là đang thêm mới
    } else {
      //nếu đang ở step 0 thì thêm mới lineProduct
      if (currentStep === 0) {
        dispatch(postLineProduct(values))
          .unwrap()
          .then(() => {
            dispatch(
              fetchLineProducts({ index: 1, pageSize: 10, codeSearch: "" })
            );
            message.success("Thêm mới dãy thành công");
          })
          .catch((error) => {});
        //nếu đang ở step 1 thì thêm mới floorProduct
      } else if (currentStep === 1) {
        dispatch(postFloorProduct({ ...values, lineProductId: selectedId }))
          .unwrap()
          .then(() => {
            dispatch(
              fetchFloorProducts({
                index: 1,
                pageSize: 10,
                codeSearch: "",
                idLineProduct: selectedId,
              })
            );
            message.success("Thêm mới tầng thành công");
          })
          .catch((error) => {});
        //nếu đang ở step 2 thì thêm mới chamber
      } else if (currentStep === 2) {
        dispatch(postChamber({ ...values, floorStockId: selectedId }))
          .unwrap()
          .then(() => {
            dispatch(
              fetchChambers({
                index: 1,
                pageSize: 10,
                codeSearch: "",
                idFloor: selectedId,
              })
            );
            message.success("Thêm mới khoang thành công");
          })
          .catch((error) => {});
      }
    }
    setModalVisible(false);
    setSelectedItem(null);
  };

  //Func phân trang
  const handlePaginationChange = (page, pageSize) => {
    console.log("Pagination Change: page", page, "pageSize", pageSize);
    if (currentStep === 0) {
      dispatch(setPage(page));
      dispatch(setPageSize(pageSize));
    } else if (currentStep === 1) {
      dispatch(setPageFloor(page));
      dispatch(setPageSizeFoor(pageSize));
    } else if (currentStep === 2) {
      dispatch(setPageSizeChamber(pageSize));
      dispatch(setPageChamber(page));
    }
  };

  //Func render phân trang
  const renderPagination = () => {
    const pagination =
      currentStep === 0
        ? lineProductPagination
        : currentStep === 1
        ? floorProductPagination
        : chamberPagination;
    return (
      <Pagination
        current={pagination.currentPage}
        pageSize={pagination.pageSize}
        total={pagination.totalCount}
        onChange={handlePaginationChange}
        style={{ marginTop: 20, textAlign: "center" }}
      />
    );
  };

  //Func lấy tên cha để hiển thị ở form modal khi thêm mới
  const getParentName = () => {
    if (currentStep === 1) {
      const lineProduct = lineProducts.find((item) => item.id === selectedId);
      return lineProduct ? lineProduct.name : "";
    }
    if (currentStep === 2) {
      const floorProduct = floorProducts.find((item) => item.id === selectedId);
      return floorProduct ? floorProduct.name : "";
    }
    return "";
  };

  //Func xử lý khi submit form nhập sản phẩm vào kho
  const handleProductFormSubmit = () => {
    setProductModalVisible(false);
    setSelectedItem(null);
    dispatch(
      fetchChambers({
        index: 1,
        pageSize: 10,
        codeSearch: "",
        idFloor: selectedId,
      })
    );
  };

  //Func mở modal nhập sản phẩm vào kho
  const handleProductModalOpen = () => {
    setProductModalVisible(true);
  };

  //Func xử lý khi submit form xuất kho
  const handleExportSubmit = () => {
    setExportModalVisible(false);
    setSelectedItem(null);
    dispatch(
      fetchChambers({
        index: 1,
        pageSize: 10,
        codeSearch: "",
        idFloor: selectedId,
      })
    );
  };

  //Func mở modal xuất kho
  const handleExportModalOpen = () => {
    setExportModalVisible(true);
  };

  //Func mở modal xem lịch sử nhập xuất kho
  const handleInventoryModalOpen = () => {
    setInventoryModalVisible(true);
  };

  //func đóng xem chi tiết chamber fetch lại chamber
  const handleChamberClose = () => {
    if (currentStep === 2) {
      dispatch(
        fetchChambers({
          index: chamberPagination.currentPage,
          pageSize: chamberPagination.pageSize,
          codeSearch: "",
          idFloor: selectedId,
        })
      );
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", height: "84vh" }}>
        <div style={{ flexShrink: 0 }}>
          <Steps style={{ marginBottom: "25px" }} current={currentStep}>
            <Step title="Dãy" />
            <Step title="Tầng" />
            <Step title="Khoang" />
          </Steps>
          <SearchComponent
            searchFields={[
              { key: "code", placeholder: "Nhập mã" },
              ...(currentStep === 2
                ? [{ key: "nameProduct", placeholder: "Nhập tên sản phẩm" }]
                : []),
            ]}
            onSearch={handleSearch}
          />
        </div>

        <div style={{ flexGrow: 1, overflow: "auto", padding: "10px" }}>
          {currentStep > 0 && (
            <Button
              style={{ marginBottom: "10px" }}
              onClick={handleBack}
              icon={<RollbackOutlined />}
            >
              Quay lại
            </Button>
          )}
          <ListCard
            data={
              currentStep === 0
                ? lineProducts
                : currentStep === 1
                ? floorProducts
                : chambers
            }
            onViewDetails={handleViewDetails}
            onSelect={(item) =>
              setSelectedItem(selectedItem?.id === item.id ? null : item)
            }
            currentStep={currentStep}
            selectedItem={selectedItem}
            loading={
              currentStep === 0
                ? lineProductPagination.loading
                : currentStep === 1
                ? floorProductPagination.loading
                : chamberPagination.loading
            }
            onCloseModal={handleChamberClose}
            idFloor={currentStep === 2 ? selectedId : null}
          />
          {renderPagination()}
        </div>

        <div style={{ flexShrink: 0 }}>
          <Space style={{ marginTop: "16px" }}>
            <Button type="primary" onClick={handleAdd}>
              Thêm mới
            </Button>
            <Button onClick={handleEdit} disabled={!selectedItem}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa mã lỗi không?"
              onConfirm={handleDelete}
              okText="Có"
              cancelText="Không"
            >
              <Button danger type="primary" disabled={!selectedItem}>
                Xóa
              </Button>
            </Popconfirm>

            <ExcelExportDropdown />
            {currentStep === 2 && (
              <>
                <Button type="primary" onClick={handleProductModalOpen}>
                  Nhập sản phẩm vào kho
                </Button>
                {/* <Button
                  className="green-button"
                  type="primary"
                  onClick={handleExportModalOpen}
                  disabled={!selectedItem}
                >
                  Xuất kho
                </Button> */}
                <Button
                  className="yellow-button"
                  type="primary"
                  onClick={handleInventoryModalOpen}
                  disabled={!selectedItem}
                >
                  Xem xuất/nhập kho
                </Button>
              </>
            )}
          </Space>
        </div>
      </div>
      <ModalForm
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSubmit={handleModalSubmit}
        initialValues={editItem}
        currentStep={currentStep}
        parentName={getParentName()}
      />
      <ProductFormModal
        visible={productModalVisible}
        onCancel={() => setProductModalVisible(false)}
        onSubmit={handleProductFormSubmit}
        idFloor={selectedId}
      />

      <ExportProduct
        visible={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        onSubmit={handleExportSubmit}
        idFloor={selectedId}
        chamberId={selectedItem?.id}
        chamberName={selectedItem?.name}
      />

      <ViewInventory
        visible={inventoryModalVisible}
        onCancel={() => {
          setInventoryModalVisible(false);
          handleChamberClose();
        }}
        idChamber={selectedItem}
        // chamber={selectedChamber}
      />
    </>
  );
};

export default QuanLyKho;
