import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const transformData = (data) => {
  const labels = data?.details?.map((detail) => detail.time);
  const percentGoodData = data?.details?.map((detail) => {
    const percent = parseFloat(detail.percentGood?.toFixed(1));
    return {
      value: percent,
      backgroundColor: percent < 96 ? "#FFC925" : "#4CFF60",
    };
  });
  const percentBadData = data?.details?.map((detail) =>
    parseFloat(detail.percentBad?.toFixed(1))
  );

  return {
    labels,
    datasets: [
      {
        label: "Sản phẩm đạt",
        data: percentGoodData.map((item) => item.value),
        backgroundColor: percentGoodData.map((item) => item.backgroundColor),
      },
      {
        label: "Sản phẩm chưa đạt",
        data: percentBadData,
        backgroundColor: "#FF0000",
      },
    ],
  };
};

const transformData2 = (data) => {
  const labels = data?.details?.map((detail) => detail.time);

  const quantityGoodData = data?.details?.map((detail) =>
    detail.quantityGood !== 0
      ? parseFloat(detail.quantityGood?.toFixed(1))
      : null
  );

  const quantityBadData = data?.details?.map((detail) =>
    detail.quantityBad !== 0 ? parseFloat(detail.quantityBad?.toFixed(1)) : null
  );

  const percentageGoodData = quantityGoodData?.map((value) =>
    value !== null ? `${value}` : null
  );
  const percentageBadData = quantityBadData?.map((value) =>
    value !== null ? `${value}` : null
  );

  return {
    labels,
    datasets: [
      {
        label: "Sản phẩm đạt",
        data: percentageGoodData,
        backgroundColor: "#4CFF60",
      },
      {
        label: "Sản phẩm chưa đạt",
        data: percentageBadData,
        backgroundColor: "#FF0000",
      },
    ],
  };
};

export const options = {
  plugins: {
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.raw}%`;
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        callback: function (value) {
          return value;
        },
      },
    },
  },
};

const percentagePlugin = {
  id: "percentagePlugin",
  afterDatasetsDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { chartData2 } = options;

    ctx.save();
    chartData2.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        const dataValue = dataset.data[index];
        if (
          dataValue !== null &&
          dataValue !== undefined &&
          dataValue !== "null"
        ) {
          ctx.fillText(`${dataValue}`, bar.x + 15, bar.y + 25);
        }
      });
    });
    ctx.restore();
  },
};

const referenceLinePlugin = {
  id: "referenceLinePlugin",
  afterDraw: (chart) => {
    const { ctx, scales } = chart;
    const yValue = 96;

    ctx.save();
    ctx.strokeStyle = "black";
    ctx.setLineDash([2, 2]);
    ctx.lineWidth = 2;

    const yAxis = scales.y;
    if (yAxis) {
      const yPixel = yAxis.getPixelForValue(yValue);
      ctx.beginPath();
      ctx.moveTo(chart.chartArea.left, yPixel);
      ctx.lineTo(chart.chartArea.right, yPixel);
      ctx.stroke();

      ctx.fillStyle = "black";
      ctx.textAlign = "right";
      ctx.font = "bold 15px Arial";
      ctx.fillText(
        `Mục tiêu: ${yValue}%`,
        chart.chartArea.right - 10,
        yPixel - 5
      );
    }

    ctx.restore();
  },
};

export function StackedChart({ dataQualityChart }) {
  const chartData = transformData(dataQualityChart);
  const chartData2 = transformData2(dataQualityChart);

  return (
    <Bar
      options={{
        ...options,
        plugins: {
          ...options.plugins,
          referenceLinePlugin: referenceLinePlugin,
          percentagePlugin: { chartData2 },
        },
      }}
      data={chartData}
      plugins={[percentagePlugin, referenceLinePlugin]}
    />
  );
}
