import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./Slice/sidebarMenuSlice";
import loginReducer from "./Slice/loginSlice";
import quanLyChuyenSlice from "./Slice/modulesSlice/quanLyChuyenSlice";
import productSlice from "./Slice/productSlice/productSlice";
import maLoiTrenSanPhamSlice from "./Slice/maLoiTrenSanPhamSlice/maLoiTrenSanPhamSlice";
import danhSachLoiSlice from "./Slice/danhSachLoiSlice/danhSachLoiSlice";
import userReducer from "./Slice/userSlice"; // Import reducer for users
import quanLyDatHangReducer from "./Slice/orderProductSlice/orderproductSlice";
import dashboardTongSlice from "./Slice/dashboardTong/dashboardTongSlice";
import dashBoardChuyenSlice from "./Slice/dashBoardChuyen/dashBoardChuyenSlice";
import dashBoardTVSlice from "./Slice/dashBoardTV/dashBoardTVSlice";
import dashboardLoiSlice from "./Slice/dashboardLoi/dashboardLoiSlice";
import lineProductSlice from "./Slice/Warehouse/lineProductSlice";
import floorProductSlice from "./Slice/Warehouse/floorProductSlice";
import chambersSlice from "./Slice/Warehouse/chambersSlice";
import nhapSanPhamVaoKhoSlice from "./Slice/Warehouse/nhapSanPhamVaoKhoSlice";
import xuatKhoSlice from "./Slice/Warehouse/xuatKhoSlice";
import viewInventorySlice from "./Slice/Warehouse/viewInventorySlice";
import viewProductDetailSlice from "./Slice/Warehouse/viewProductDetailSlice";
import warehousev2Slice from "./Slice/Warehousev2/warehousev2Slice";
import qaqcInfoSlice from "./Slice/QAQCInfo/qaqcInfoSlice";
import ExportExcelSlice from "./Slice/Warehouse/ExportExcelSlice";
import taskSlice from "./Slice/TaskSlice/taskSlice";

export const store = configureStore({
  reducer: {
    sidebarSlice: sidebarSlice.reducer,
    user: loginReducer,
    quanLyChuyen: quanLyChuyenSlice,
    quanLySanPham: productSlice,
    maLoiTrenSanPham: maLoiTrenSanPhamSlice,
    danhSachLoi: danhSachLoiSlice,
    users: userReducer,
    quanLyDatHang: quanLyDatHangReducer,
    dashboardTong: dashboardTongSlice,
    dashBoardChuyen: dashBoardChuyenSlice,
    dashBoardTV: dashBoardTVSlice,
    dashboardLoi: dashboardLoiSlice,
    lineProducts: lineProductSlice,
    floorProducts: floorProductSlice,
    chambers: chambersSlice,
    nhapSanPhamVaoKho: nhapSanPhamVaoKhoSlice,
    xuatKho: xuatKhoSlice,
    viewInventory: viewInventorySlice,
    viewProductDetail: viewProductDetailSlice,
    warehousev2: warehousev2Slice,
    qaqcInfo: qaqcInfoSlice,
    exportExcel: ExportExcelSlice,
    task: taskSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
