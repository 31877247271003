import { Empty, Modal, Table } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

const CustomEmpty = () => (
  <div>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<p>Không có chi tiết đơn hàng</p>}
    />
  </div>
);

const OrderDetailModal = ({ visible, onCancel, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: 50,
      align: "center",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên đơn hàng ",
      dataIndex: "name",
      key: "name",
    },

    { title: "Mã sản phẩm", dataIndex: "productCode", key: "productCode" },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
    },

    {
      title: "Số lượng sản phẩm",
      dataIndex: "quantityProduct",
      key: "quantityProduct",
      align: "center",
      width: 150,
    },
    {
      title: "Tổng sản lượng",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      width: 150,
    },
    {
      title: "Tổng kiểm",
      dataIndex: "quantityQA",
      key: "quantityQA",
      align: "center",
    },
    {
      title: "Phần trăm đạt",
      dataIndex: "percentGood",
      key: "percentGood",
      align: "center",
      render: (text) => text + "%",
    },
    {
      title: "Dự kiến",
      dataIndex: "dateStartEnd",
      key: "dateStartEnd",
      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (text) => {
        const date = new Date(text);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      },
    },
    {
      title: "Tạo bởi",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => {
        return (
          <ul>
            {text.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        );
      },
    },
  ];

  const formatDateTime = (dateTime) => {
    return dayjs(dateTime).format("DD/MM/YYYY");
  };

  const dataSource = [
    {
      key: 1,
      code: data.code,
      name: data.name,
      quantityQA: data.quantityQA,
      productCode: data.productCode,
      productName: data.productName,
      totalQuantity: data.totalQuantity,
      quantityProduct: data.quantityProduct,
      percentGood: data.percentGood,
      startAct: data.startAct,
      endAct: data.endAct,
      dateStartEnd:
        formatDateTime(data.startAct) + " - " + formatDateTime(data.endAct),
      description: data.description,
      createdBy: data.createdBy,
      createdTime: data.createdTime,
    },
  ];

  return (
    <Modal
      title="Chi Tiết Đơn Hàng"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={1800}
    >
      <Table
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey="key"
        scroll={{ x: 2000 }}
        pagination={{
          pageSize: pageSize,
          onChange: (page) => setCurrentPage(page),
        }}
        locale={{ emptyText: <CustomEmpty /> }}
      />
    </Modal>
  );
};

export default OrderDetailModal;
