import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequestParams, getRequestParams } from "../../../services/api";

//fetch inventory history

export const fetchInventoryHistory = createAsyncThunk(
  "viewInventory/fetchInventoryHistory",
  async (
    { index, pageSize, history, codeSearch, idChamber },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        index,
        pageSize,
        History: history,
        codeSearch,
      };
      const response = await getRequestParams(
        `/warehouses/${idChamber}/history`,
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete history ( huỷ xuất nhập kho)

export const deleteHistory = createAsyncThunk(
  "viewInventory/deleteHistory",
  async ({ chamberId, historyId }, { rejectWithValue }) => {
    try {
      const params = { chamberId, historyId };
      const response = await deleteRequestParams(`/warehouses/history`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const viewInventorySlice = createSlice({
  name: "viewInventory",
  initialState: {
    importHistory: [],
    exportHistory: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    clearHistory: (state) => {
      state.importHistory = [];
      state.exportHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInventoryHistory.fulfilled, (state, action) => {
        state.status = "success";
        const history = action.meta.arg.history;
        if (history === 2) {
          state.importHistory = action.payload.data.items;
        } else if (history === 3) {
          state.exportHistory = action.payload.data.items;
        }
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(fetchInventoryHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //delete history
      .addCase(deleteHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteHistory.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(deleteHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setPage, setPageSize, clearHistory } =
  viewInventorySlice.actions;

export default viewInventorySlice.reducer;
