import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../redux/Slice/modulesSlice/quanLyChuyenSlice";

const FormComponent = ({ visible, onCreate, onCancel, initialValues, form }) => {
  const dispatch = useDispatch();
  const usersEnum3 = useSelector((state) => state.quanLyChuyen.usersEnum3);
  const usersEnum4 = useSelector((state) => state.quanLyChuyen.usersEnum4);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
      dispatch(fetchUsers(3));
      dispatch(fetchUsers(4));
    }
  }, [visible, initialValues, form, dispatch]);

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật chuyền" : "Thêm mới chuyền"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      width={700} // Chỉnh kích thước của modal
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        labelCol={{ span: 6 }} // Điều chỉnh độ rộng của tiêu đề
        wrapperCol={{ span: 18 }} // Điều chỉnh độ rộng của input
        labelAlign="left" // Căn chỉnh tiêu đề về bên trái
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="code"
              label="Mã chuyền"
              rules={[{ required: true, message: "Vui lòng nhập mã chuyền!" }]}
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Tên chuyền"
              rules={[{ required: true, message: "Vui lòng nhập tên chuyền!" }]}
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Mô tả"
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="numOfStaff"
              label="Số lượng nhân viên"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số lượng nhân viên!",
                },
              ]}
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="leadModuleId"
              label="Chuyền trưởng"
              rules={[
                { required: true, message: "Vui lòng nhập ID chuyền trưởng!" },
              ]}
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Select
                showSearch
                placeholder="Chọn chuyền trưởng"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={usersEnum3.map((user) => {
                  return {
                    label: `${user.userName}`,
                    value: user.id,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="qcLeadId"
              label="Trưởng QC"
              rules={[
                { required: true, message: "Vui lòng nhập ID trưởng QC!" },
              ]}
              labelAlign="right" // Căn chỉnh tiêu đề về bên phải
            >
              <Select
                showSearch
                placeholder="Chọn Trưởng QC"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={usersEnum4.map((user) => {
                  return {
                    label: `${user.userName}`,
                    value: user.id,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormComponent;
