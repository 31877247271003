import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ReferenceLine,
  LabelList,
  Label,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Doughnut } from "react-chartjs-2";
import { Button, Collapse, Space, Select, DatePicker, TimePicker } from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/vi_VN";
import * as XLSX from "xlsx";
import "dayjs/locale/vi";
import "../AdminDashboard.css";
import "./TVDashboard.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend as ChartLegend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAverageProductivity,
  fetchQualityChart,
  fetchProductivity,
} from "../../../redux/Slice/dashBoardChuyen/dashBoardChuyenSlice";
import {
  fetchCategories,
  fetchModules,
} from "../../../redux/Slice/dashboardTong/dashboardTongSlice";
import { StackedChart } from "../../../components/StackedChart/StackedChart";
import moment from "moment";
import { fetchtasks } from "../../../redux/Slice/dashboardTong/dashboardTongSlice";
import {
  fetchProductivityTivi,
  fetchQualityChartTivi,
  fetchOWETivi,
  fetchDailyDefectCodePercent,
  fetchHourHasDefectProduct,
} from "../../../redux/Slice/dashBoardTV/dashBoardTVSlice";
ChartJS.register(ArcElement, Tooltip, ChartLegend, ChartDataLabels);
const useAutoFetchAPIs = ({ searchParams, time }) => {
  const dispatch = useDispatch();
  const [currentIdChuyenIndex, setCurrentIdChuyenIndex] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    console.log("Current time", time);
    if (
      searchParams.idChuyen &&
      searchParams.idChuyen.length > 0 &&
      !isFetching
    ) {
      const fetchAPIs = async () => {
        setIsFetching(true);
        const currentIdChuyen = searchParams.idChuyen[currentIdChuyenIndex];

        const params = { ...searchParams, idChuyen: [currentIdChuyen], time };

        await Promise.all([
          dispatch(fetchOWETivi(params)),
          dispatch(fetchQualityChartTivi(params)),
          dispatch(fetchProductivityTivi(params)),
          dispatch(fetchDailyDefectCodePercent(params)),
        ]);

        setCurrentIdChuyenIndex((prevIndex) =>
          prevIndex + 1 < searchParams.idChuyen.length ? prevIndex + 1 : 0
        );

        setIsFetching(false);
      };

      const timer = setTimeout(fetchAPIs, 60000);
      return () => clearTimeout(timer);
    }
  }, [searchParams, currentIdChuyenIndex, dispatch, isFetching, time]);
};
const CustomLegend = ({ legendData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "20px",
      }}
    >
      {legendData.map((item, index) => (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
          key={index}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: item.color,
              marginRight: "10px",
            }}
          ></div>
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

const { Option } = Select;

const TVDashboard = () => {
  const dataProductivityChart2 = useSelector(
    (state) => state.dashBoardChuyen.dataProductivityChart
  );

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#000",
        formatter: (value, context) => {
          return value !== 0 ? `${value?.toFixed(0)}%` : "";
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              label = `${context.parsed}%`;
            }
            return `${label}`;
          },
        },
      },
    },
  };

  const [searchParams, setSearchParams] = useState({
    idChuyen: null,
    idProduct: null,
    dateValue: null,
    chartValue: null,
  });
  const [idChuyenError, setIdChuyenError] = useState(null);
  const [time, setTime] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isChuyenSelected, setIsChuyenSelected] = useState(false);

  const categories = useSelector((state) => state.dashboardTong.categories);
  const modules = useSelector((state) => state.dashboardTong.modules);
  const dataOWEChartTV = useSelector(
    (state) => state.dashBoardTV.dataOWEChartTV
  );
  const dataProductivityChart = useSelector(
    (state) => state.dashBoardTV.dataProductivityChart
  );
  const productivityData = useSelector(
    (state) => state.dashBoardTV.dataFetchProductivity
  );
  const dataDailyDefectCodePercent = useSelector(
    (state) => state.dashBoardTV.dataDailyDefectCodePercent
  );

  const dataQualityChart = useSelector(
    (state) => state.dashBoardTV.dataQualityChart || []
  );

  const dataHourHasDefectProduct = useSelector(
    (state) => state.dashBoardTV.dataHourHasDefectProduct
  );
  const dispatch = useDispatch();
  const getBarColor = (entry) =>
    entry.quantity >= productivityData[0]?.taskProductsDetail[0]?.target
      ? "green"
      : "red";

  const getBarColor2 = (entry) =>
    entry.percentQuantity >=
    productivityData[0]?.taskProductsDetail[0]?.percentTarget
      ? "green"
      : "red";

  const legendData = dataDailyDefectCodePercent.map((item) => ({
    color: item.backGroundColor || "#FFE477",
    label: `${item.name}: ${item.count}`,
  }));
  const data1 = {
    labels: dataDailyDefectCodePercent.map((item) =>
      item?.subDefects?.map((i) => `${i.name}: ${i.percent}%`).join("\n")
    ),
    datasets: [
      {
        data: dataDailyDefectCodePercent.map((item) => item.percent),
        backgroundColor: dataDailyDefectCodePercent.map(
          (item) => item.backGroundColor || "#FFE477"
        ),
        hoverBackgroundColor: dataDailyDefectCodePercent.map(
          (item) => item.backGroundColor || "#FFE477"
        ),
      },
    ],
  };

  // const convertOweFrameToProductionData = (oweFrame) => {
  //   const targetValue = dataOWEChartTV[0]?.owe;

  //   return oweFrame?.map((item) => {
  //     const hour = item.time;
  //     const resultOWE = parseFloat(item?.resultOWE?.toFixed(2));
  //     const percentage = `${resultOWE.toFixed(2)}%`;
  //     return {
  //       name: `${hour}`,
  //       value: resultOWE,
  //       target: 800,
  //       fill: resultOWE >= targetValue ? "green" : "red",
  //       percentage,
  //     };
  //   });
  // };
  const convertOweFrameToProductionData = (oweFrame) => {
    const targetValue = dataOWEChartTV[0]?.taskProductsDetail[0]?.owe;

    return oweFrame.map((item) => {
      const hour = item.time;
      let resultOWE;
      let percentage;

      if (item.resultOWE === 0) {
        resultOWE = 0;
        percentage = "0";
      } else {
        resultOWE = parseFloat(item.resultOWE.toFixed(2));
        percentage = `${resultOWE.toFixed(2)}%`;
      }

      return {
        name: `${hour}`,
        value: resultOWE,
        target: 800,
        fill: resultOWE >= targetValue ? "green" : "red",
        percentage,
      };
    });
  };
  const handleChangeTime = (time) => {
    console.log("Setting time", time);
    setTime(time);
  };
  useAutoFetchAPIs({ searchParams, time });

  const onSearch = () => {
    console.log("searchParams", searchParams);

    if (searchParams.idChuyen === null && searchParams.dateValue === null) {
      const params = {
        idProduct: categories[0].id,
        idChuyen: modules[0].id,
        dateValue: null,
        time: time,
      };
      console.log("Hello", params);
      dispatch(fetchOWETivi(params));
    } else {
      console.log("SearchParams", searchParams.idChuyen[0]);

      const params = {
        idChuyen: searchParams.idChuyen[0],
        dateValue: searchParams.dateValue,
        idProduct: searchParams.idProduct,
        time: time,
      };
      dispatch(fetchOWETivi(params));

      dispatch(fetchQualityChartTivi(params));
      dispatch(fetchProductivityTivi(params));
      dispatch(fetchDailyDefectCodePercent(params));
    }
  };
  const allProductionData =
    dataOWEChartTV?.map((item) => ({
      moduleName: item.moduleName,
      productionData: convertOweFrameToProductionData(item.oweFrame),
      taskProductsDetail: item.taskProductsDetail,
      // chartCols: dataChartCol,
    })) || [];
  console.log(allProductionData);
  const onClear = () => {
    setSearchParams({
      idChuyen: null,
      idProduct: null,
      dateValue: null,
      chartValue: null,
    });
  };

  const handleChange = (key, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  useEffect(() => {
    dispatch(fetchModules({ index: 1, pageSize: 10000 }));
    dispatch(fetchCategories());
    dispatch(fetchtasks());
  }, [dispatch]);

  const getMaxYAxisDomain = (data) => {
    const maxValue = Math.max(...data.map((d) => d.value));
    return Math.ceil(maxValue / 10) * 10 + 20;
  };
  
  const getMaxQuantityYAxisDomain = (data) => {
    const maxQuantity = Math.max(...data.map((d) => d.quantity));
    return Math.ceil(maxQuantity / 10) * 10 + 40;
  };
  

  const productivityMaxYAxisDomain = getMaxQuantityYAxisDomain(
    productivityData[0]?.details || []
  );
  const oweMaxYAxisDomain = allProductionData.length > 0 && allProductionData[0]?.productionData 
  ? getMaxYAxisDomain(allProductionData[0].productionData)
  : 0; // Hoặc giá trị mặc định nào đó bạn muốn sử dụng khi productionData không có



  const handleDateChange = (date) => {
    handleChange("dateValue", date);
    setIsDateSelected(!!date);
    const params = {
      date: date,
      idChuyen: searchParams.idChuyen[0],
    };
    dispatch(fetchHourHasDefectProduct(params));
  };

  const handleChuyenChange = (value) => {
    handleChange("idChuyen", value);
    setIsChuyenSelected(value.length > 0);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Collapse defaultActiveKey={["7"]} size="small">
        <Collapse.Panel key="7" header="Tìm kiếm">
          <Space align="center">
            <Select
              mode="multiple"
              placeholder="Chọn Chuyền"
              style={{ width: 400 }}
              value={searchParams.idChuyen}
              onChange={handleChuyenChange}
            >
              {modules.map((module) => (
                <Option key={module.id} value={module.id}>
                  {module.name}
                </Option>
              ))}
            </Select>
            <DatePicker
              placeholder="Chọn ngày"
              style={{ width: 150 }}
              value={searchParams.dateValue}
              locale={locale}
              format="DD-MM-YYYY"
              onChange={handleDateChange}
              disabled={!isChuyenSelected}
            />
            {/* <TimePicker
              placeholder="Chọn Giờ"
              value={time}
              onChange={handleChangeTime}
              format="HH:mm"
              showNow={false}
            /> */}

            <Select
              placeholder="Chọn Giờ"
              value={time}
              onChange={handleChangeTime}
              disabled={!isDateSelected}
            >
              {dataHourHasDefectProduct.map((time, index) => (
                <Option key={index} value={time.hour}>
                  {time.hour} giờ
                </Option>
              ))}
            </Select>

            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => onSearch(searchParams)}
            >
              Hiển thị
            </Button>
            <Button
              type="default"
              danger
              icon={<CloseOutlined />}
              onClick={onClear}
            >
              Hủy bỏ
            </Button>
          </Space>
        </Collapse.Panel>
      </Collapse>
      <div
        className="chart-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {dataOWEChartTV?.length > 0 && (
  <div
    style={{
      width: "50%",
      height: "80%",
      textAlign: "center",
    }}
  >
    <h3>Biểu đồ OWE {allProductionData[0].moduleName}</h3>
    <div className="chart-legend">
      <div className="legend-item">
        <div
          className="legend-color"
          style={{ backgroundColor: "green" }}
        ></div>
        <span>Đã đạt</span>
      </div>
      <div className="legend-item">
        <div
          className="legend-color"
          style={{ backgroundColor: "red" }}
        ></div>
        <span>Chưa đạt</span>
      </div>
    </div>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={allProductionData[0].productionData}
        margin={{ top: 5, bottom: 5, left: 75 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, oweMaxYAxisDomain]} />
        <RechartsTooltip />
        {allProductionData[0].taskProductsDetail?.map(
          (item, index) => (
            <ReferenceLine
              key={index}
              y={item.owe}
              stroke="black"
              strokeDasharray="2 2"
              strokeWidth={1}
            >
              <Label
                value={`Mục tiêu: ${item.owe}`}
                position={index === 0 ? "left" : "right"}
                fill={index === 0 ? "#18857D" : "black"}
                fontWeight="bold"
                dy={-10}
                dx={index === 0 ? -25 : 3}
              />
              <Label
                value={`(${item.dateStart} - ${item.dateEnd})`}
                position={index === 0 ? "left" : "right"}
                fill={index === 0 ? "#18857D" : "black"}
                fontWeight="bold"
                dy={10}
                dx={index === 0 ? -25 : 3}
              />
            </ReferenceLine>
          )
        )}

        <Bar
          dataKey="value"
          fill={(entry) => entry.fill}
          barSize={40}
        >
          <LabelList dataKey="percentage" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
)}
          {dataQualityChart?.length > 0 && (
            <div
              style={{
                width: "50%",
                textAlign: "center",
              }}
            >
              <h3>Biểu đồ chất lượng sản phẩm</h3>
              <StackedChart dataQualityChart={dataQualityChart[0]} />
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          {productivityData?.length > 0 && (
            <div
              style={{
                width: "50%",
                textAlign: "center",
                height: "80%",
              }}
            >
              <div className="chart-legend">
                <div className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: "green" }}
                  ></div>
                  <span>Đã đạt target</span>
                </div>
                <div className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: "red" }}
                  ></div>
                  <span>Chưa đạt target</span>
                </div>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={productivityData[0]?.details}
                  margin={{ top: 6, bottom: 5, left: 75 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis domain={[0, productivityMaxYAxisDomain]} />
                  <Tooltip />
                  {productivityData[0].taskProductsDetail.map((item, index) => (
                    <ReferenceLine
                      key={index}
                      y={item?.target}
                      stroke="black"
                      strokeDasharray="2 2"
                      strokeWidth={1}
                    >
                      <Label
                        value={`Mục tiêu: ${item.target}`}
                        position={index === 0 ? "left" : "right"}
                        fill={index === 0 ? "#18857D" : "black"}
                        fontWeight="bold"
                        dy={-10}
                        dx={index === 0 ? -25 : 3}
                      />
                      <Label
                        value={`(${item.dateStart} - ${item.dateEnd})`}
                        position={index === 0 ? "left" : "right"}
                        fill={index === 0 ? "#18857D" : "black"}
                        fontWeight="bold"
                        dy={10}
                        dx={index === 0 ? -25 : 3}
                      />
                    </ReferenceLine>
                  ))}
                  <RechartsTooltip />
                  <Bar dataKey="quantity" fill="#8884d8" barSize={40}>
                    {productivityData[0]?.details?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getBarColor(entry)} />
                    ))}
                    <LabelList
                      position="top"
                      formatter={(value) => `${value}`}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <h3>Biểu đồ năng suất</h3>
            </div>
          )}
          <div
            style={{
              width: "50%",
              textAlign: "center",
            }}
          >
            {dataDailyDefectCodePercent?.length > 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "400px", height: "400px" }}>
                    <Doughnut data={data1} options={options} />
                  </div>
                  <CustomLegend legendData={legendData} />
                </div>
                <h3>Biểu đồ lỗi</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVDashboard;
