import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button, Collapse, Space, Select, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchModules,
  getDailyDefectCodePercent,
  getHourlyDefectCodePercent,
  getKPIDefectCode,
} from "../../../redux/Slice/dashboardLoi/dashboardLoiSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const { Option } = Select;
const { RangePicker, MonthPicker } = DatePicker;

const DashboardLoi = () => {
  const dispatch = useDispatch();
  const {
    modules,
    kpiDefectCode,
    dailyDefectCodePercent,
    hourlyDefectCodePercent,
    status,
    error,
  } = useSelector((state) => state.dashboardLoi);

  const [selectedModule, setSelectedModule] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [monthYear, setMonthYear] = useState(null);

  //lấy 1 số sau dấu chấm của percent
  const processPercent = (percent) => parseFloat(percent.toFixed(1));

  //lấy dữ liệu từ API
  useEffect(() => {
    dispatch(fetchModules({ index: 1, pageSize: 1000 }));
  }, [dispatch]);
  // Tìm kiếm
  const onSearch = () => {
    const params = {};

    if (selectedModule) {
      params.moduleId = selectedModule;
    }
    if (dateRange) {
      const [dateStart, dateEnd] = dateRange.map((date) =>
        date.format("YYYY-MM-DD")
      );
      params.dateStart = dateStart;
      params.dateEnd = dateEnd;
    }
    if (monthYear) {
      const formattedMonthYear = monthYear.format("MM/YYYY");
      params.monthYear = formattedMonthYear;
    }

    dispatch(getKPIDefectCode(params));
    dispatch(getDailyDefectCodePercent(params));
    dispatch(getHourlyDefectCodePercent(params));
  };

  // Biểu đồ cột
  const processBarData = (data = []) => {
    const rawPercentages = data.map((item) => item.percent || 0);
    const uniqueValues = [...new Set(rawPercentages)]
      .sort((a, b) => b - a)
      .slice(0, 3);
    const maxValue = Math.max(...uniqueValues) + 1;

    return {
      labels: data.map((item) => item.name),
      datasets: [
        {
          data: data.map((item) => processPercent(item.percent || 0)),
          backgroundColor: data.map((item) =>
            uniqueValues.includes(item.percent || 0) ? "red" : "green"
          ),
        },
      ],
      maxValue,
    };
  };

  const { datasets, labels, maxValue } = processBarData(kpiDefectCode);
  const barData = { datasets, labels };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        onClick: null,
        display: false,
      },
      title: {
        display: true,
        text: "Dashboard Lỗi",
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => value + "%",
        color: "black",
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: processPercent(maxValue),
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  const processPieData = (data = []) => ({
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => processPercent(item.percent || 0)),
        backgroundColor: data.map(
          (item) => item.textColor || "rgba(75, 192, 192, 0.6)"
        ),
        subDefects: data.map((item) => item.subDefects),
      },
    ],
  });

  const pieData1 = processPieData(dailyDefectCodePercent);
  const pieData2 = processPieData(hourlyDefectCodePercent);

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex;
            const subDefects = context.dataset.subDefects[dataIndex];
            if (subDefects && subDefects.length > 0) {
              return [
                `${context.label}: ${context.raw}%`,
                ...subDefects.map(
                  (subDefect) =>
                    `- ${subDefect.name}: ${processPercent(subDefect.percent)}%`
                ),
              ];
            }
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
      datalabels: {
        color: "black",
        font: {
          weight: "bold",
        },
      },
    },
  };

  const onReset = () => {
    setSelectedModule(null);
    setDateRange(null);
    setMonthYear(null);
  };
  return (
    <div>
      <Collapse
        defaultActiveKey={["7"]}
        size="small"
        items={[
          {
            key: "7",
            label: "Tìm kiếm",
            children: (
              <Space align="center">
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Chọn module"
                  optionFilterProp="children"
                  value={selectedModule}
                  onChange={setSelectedModule}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {modules.map((module) => (
                    <Option key={module.id} value={module.id}>
                      {module.name}
                    </Option>
                  ))}
                </Select>
                <RangePicker
                  placeholder={["Chọn ngày bắt đầu", "Chọn ngày kết thúc"]}
                  style={{ width: 300 }}
                  value={dateRange}
                  locale={locale}
                  format="DD-MM-YYYY"
                  onChange={(dates) => setDateRange(dates)}
                />
                <MonthPicker
                  placeholder="Chọn tháng/năm"
                  style={{ width: 150 }}
                  value={monthYear}
                  locale={locale}
                  format="MM-YYYY"
                  onChange={(date) => setMonthYear(date)}
                />

                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={onSearch}
                >
                  Hiển thị
                </Button>
                <Button
                  type="default"
                  icon={<RedoOutlined />}
                  onClick={onReset}
                >
                  Reset
                </Button>
                {/* <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={onRefresh}
                >
                  Refresh
                </Button> */}
              </Space>
            ),
          },
        ]}
      />

      {barData && barData.labels.length > 0 && (
        <Bar data={barData} options={barOptions} />
      )}
      {(pieData1.labels.length > 0 || pieData2.labels.length > 0) && (
        <div
          style={{
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {pieData1.labels.length > 0 && (
            <div style={{ width: "25%" }}>
              <h3>Biểu đồ tỷ lệ lỗi trong ngày</h3>
              <Pie data={pieData1} options={pieOptions} />
            </div>
          )}
          {pieData2.labels.length > 0 && (
            <div style={{ width: "25%" }}>
              <h3>Lỗi giờ hiện tại</h3>
              <Pie data={pieData2} options={pieOptions} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardLoi;
