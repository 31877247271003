import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest, getRequestParams } from "../../../services/api";
import axios from "axios";
import moment from "moment";
// OWE chart
export const fetchOWEChart = createAsyncThunk(
  "dashBoardChuyen/fetchOWEChart",
  async (searchParams) => {
    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;
    const currentDate = moment().format("YYYY/MM/DD");
    let url = `${API_URL}dashboards/getowe?`;

    try {
      const currentDate = moment().format("YYYY/MM/DD");

      const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
      const moduleIdParams = searchParams.idChuyen
        .map((id) => `moduleId=${id}`)
        .join("&");

      const url = `${API_URL}dashboards/getowe?${moduleIdParams}${
        formattedDate ? `&date=${formattedDate}` : ""
      }${
        searchParams.taskValue ? `&taskProductId=${searchParams.taskValue}` : ""
      }`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching OWE chart data:", error);
      throw error;
    }
  }
);

// Quality Chart
export const fetchQualityChart = createAsyncThunk(
  "dashBoardChuyen/fetchQualityChart",
  async (searchParams) => {
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    const moduleIdParams = searchParams.idChuyen
      .map((id) => `moduleId=${id}`)
      .join("&");

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const url = `${API_URL}dashboards/getquality?${moduleIdParams}${
        formattedDate ? `&date=${formattedDate}` : ""
      }${
        searchParams.taskValue ? `&taskProductId=${searchParams.taskValue}` : ""
      }`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Quality Chart data:", error);
      throw error;
    }
  }
);

// Productivity
export const fetchProductivity = createAsyncThunk(
  "dashBoardChuyen/fetchProductivity",
  async (searchParams) => {
    const formattedDate = searchParams?.dateValue?.format("YYYY-MM-DD");
    const moduleIdParams = Array.isArray(searchParams.idChuyen)
      ? searchParams.idChuyen.map((id) => `moduleId=${id}`).join("&")
      : "";

    const accessToken = localStorage.getItem("accessToken");
    const API_URL = process.env.REACT_APP_BASE_URL;

    try {
      const url = `${API_URL}dashboards/getproductivity?${moduleIdParams}${
        formattedDate ? `&date=${formattedDate}` : ""
      }${
        searchParams.taskValue ? `&taskProductId=${searchParams.taskValue}` : ""
      }`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching Productivity data:", error);
      throw error;
    }
  }
);

//get taskProduct by day

export const fetchTaskProductByDayandModule = createAsyncThunk(
  "dashBoardChuyen/fetchTaskProductByDayandModule",
  async ({ date, moduleId }, { rejectWithValue }) => {
    try {
      const params = `moduleId=${moduleId}&date=${date}`;
      const response = await getRequestParams(
        `/taskproduct/getdaytaskproduct?${params}`
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errorMessage || "Failed to fetch task products.";
      return rejectWithValue(errorMessage);
    }
  }
);

const dashBoardChuyenSlice = createSlice({
  name: "dashBoardChuyen",
  initialState: {
    status: "idle",
    error: null,
    dataOWEChart: [],

    statusQualityChart: "idle",
    errorQualityChart: null,
    dataQualityChart: [],
    statusFetchProductivity: "idle",
    errorFetchProductivity: null,
    dataFetchProductivity: [],
    taskProductByDay: [],
    statusTaskProductByDay: "idle",
    errorTaskProductByDay: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch OWE chart
      .addCase(fetchOWEChart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOWEChart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dataOWEChart = action.payload.data;
      })
      .addCase(fetchOWEChart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // fetch productivity chart
      .addCase(fetchProductivity.pending, (state) => {
        state.statusFetchProductivity = "loading";
      })
      .addCase(fetchProductivity.fulfilled, (state, action) => {
        state.statusFetchProductivity = "succeeded";
        state.dataFetchProductivity = action.payload.data;
      })
      .addCase(fetchProductivity.rejected, (state, action) => {
        state.statusFetchProductivity = "failed";
        state.errorFetchProductivity = action.error.message;
      })

      //fetch task product by day
      .addCase(fetchTaskProductByDayandModule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskProductByDayandModule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskProductByDay = action.payload.data;
      })
      .addCase(fetchTaskProductByDayandModule.rejected, (state, action) => {
        state.statusTaskProductByDay = "failed";
        state.errorTaskProductByDay = action.payload;
      });
  },
});

export default dashBoardChuyenSlice.reducer;
