import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Popconfirm, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../redux/Slice/modulesSlice/quanLyChuyenSlice";
import SearchComponent from "../../components/SearchComponent";
import TableComponent from "../../components/TableComponent";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import {
  fetchUsers,
  deleteUser,
  updateUser,
  changePassword,
} from "../../redux/Slice/userSlice";

const QuanLyUser = () => {
  const [form] = Form.useForm()
  const pageSize = 15;
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.users.currentPage);
  const dataSource = useSelector((state) => state.users.data) || {
    data: [],
    totalPages: 0,
  };
  const loading = useSelector((state) => state.users.loading);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchParams, setSearchParams] = useState({
    usernameSearch: "",
    emailSearch: "",
    roleSearch: "",
    idSearch: "",
    codeSearch: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    setPagination((prev) => ({ ...prev, current: 1 }));
    dispatch(setPage(1));
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      })
    )
      .then((result) => {
        console.log("Fetched users:", result);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [pagination, searchParams, dispatch]);

  console.log(dataSource);

  const columns = [
    { title: "Tên người dùng", dataIndex: "username", key: "username" },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) =>
        text ? text : <span style={{ color: "gray" }}>(Chưa có)</span>,
    },
    {
      title: "Họ và tên",
      dataIndex: "fullName",
      key: "fullName",
      render: (text) =>
        text ? text : <span style={{ color: "gray" }}>(Chưa có)</span>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) =>
        text ? text : <span style={{ color: "gray" }}>(Chưa có)</span>,
    },
    { title: "Vai trò", dataIndex: "role", key: "role" },
  ];

  const handleEdit = () => {
    const record = dataSource.items.find(
      (item) => item.id === selectedRowKeys[0]
    ); // Ensure you are using the correct key
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      for (const userId of selectedRowKeys) {
        await dispatch(deleteUser(userId)).unwrap();
      }
      message.success("Xóa người dùng thành công!");
      setSelectedRowKeys([]);
      dispatch(
        fetchUsers({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          ...searchParams,
        })
      );
    } catch (error) {
      console.error("Error deleting users:", error);
      console.error("Error detail:", error.message);
      message.error("Xóa người dùng thất bại!");
    }
  };

  const handleUpdate = async (values) => {
    try {
      await dispatch(updateUser({ id: editingRecord.id, ...values })).unwrap();
      message.success("Cập nhật người dùng thành công!");
      setIsEditModalVisible(false);
      dispatch(
        fetchUsers({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          ...searchParams,
        })
      );
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("Cập nhật người dùng thất bại!");
    }
  };

  const handleRefresh = async () => {
    try {
      // Gọi API để làm mới dữ liệu
      await dispatch(
        fetchUsers({ pageIndex: currentPage, pageSize, ...searchParams })
      );
      message.success("Dữ liệu đã được làm mới thành công!");
    } catch (error) {
      console.error("Lỗi khi làm mới dữ liệu:", error);
      message.error("Đã có lỗi xảy ra khi làm mới dữ liệu!");
    }
  };
  const handleChangePassword =  (values) => {
    values.userId = selectedRowKeys[0]
    dispatch(changePassword(values))
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize,
    });
  };
  const validateConfirmPassword = (_, value) => {
    const { newPassword } = form.getFieldsValue();
    if (value && value !== newPassword) {
      return Promise.reject(new Error('Passwords do not match!'));
    }
    return Promise.resolve();
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div>
          <SearchComponent
            onSearch={handleSearch}
            searchFields={[
              { key: "idSearch", placeholder: "Tên đăng nhập" },
              { key: "codeSearch", placeholder: "Họ và tên" },
            ]}
          />
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
          <TableComponent
            dataSource={Array.isArray(dataSource.items) ? dataSource.items : []}
            rowKey="id"
            columns={columns}
            loading={loading}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: dataSource.totalCount,
              onChange: handlePageChange,
            }}
          />
        </div>
        <Space>
          <Button
            style={{
              backgroundColor: "#52c41a",
              borderColor: "#52c41a",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#389e0d",
                borderColor: "#389e0d",
                color: "#fff",
              },
            }}
            onClick={handleRefresh}
          >
            Làm mới
          </Button>
          <Button type="primary" onClick={() => setIsAddModalVisible(true)}>
            Thêm mới
          </Button>
          <Button
            type="default"
            onClick={handleEdit}
            disabled={selectedRowKeys.length !== 1}
          >
            Chỉnh sửa
          </Button>
          <Popconfirm
            title="Bạn có muốn xóa người dùng không?"
            onConfirm={handleDelete}
            okText="Có"
            cancelText="Không"
          >
            <Button
              type="primary"
              danger
              disabled={selectedRowKeys.length === 0}
            >
              Xóa người dùng
            </Button>
          </Popconfirm>
          <Button style={{backgroundColor:"orange"}} disabled={selectedRowKeys.length === 0} type="primary" onClick={()=>{setIsChangePassword(true)}}>
            Đổi mật khẩu
          </Button>
        </Space>
        <EditForm
          visible={isEditModalVisible}
          onCreate={handleUpdate}
          onCancel={() => setIsEditModalVisible(false)}
          initialValues={editingRecord}
        />
        <AddForm
          searchParams={searchParams}
          visible={isAddModalVisible}
          onCreate={() => setIsAddModalVisible(false)}
          onCancel={() => setIsAddModalVisible(false)}
        />
        <Modal
        open={isChangePassword}
        title="Đổi mật khẩu"
        footer={null}
        onCancel={()=>{
          setIsChangePassword(false)
          form.resetFields()
        }}
        >
          <Form
          form={form}
          onFinish={handleChangePassword}
          >
                <Form.Item
        name="newPassword"
        label="New Password"
        rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        rules={[
          { required: true, message: 'Please confirm your password!' },
          { validator: validateConfirmPassword }
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button style={{backgroundColor:"orange"}} type="primary" htmlType="submit">
          Xác nhận
        </Button>
      </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default QuanLyUser;
