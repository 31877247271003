import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  message,
  Modal,
  Table,
  Spin,
  Form,
  Popconfirm,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import SearchComponent from "../../components/SearchComponent";
import TableComponent from "../../components/TableComponent";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTaskProducts,
  deleteTaskProduct,
  setPageSize,
  setPage,
  fetchTaskDetail,
  createTaskProduct,
  updateTaskProduct,
} from "../../redux/Slice/TaskSlice/taskSlice";
import dayjs from "dayjs";
import ViewDetail from "./ViewDetail";

const QuanLyCongViec = () => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);

  const [recordModalVisible, setRecordModalVisible] = useState(false);

  const [searchKey, setSearchKey] = useState(0);

  const [searchParams, setSearchParams] = useState({
    codeSearch: "",
  });
  const [loading, setLoading] = useState(false);

  const taskProducts = useSelector((state) => state.task.taskProducts);
  const taskDetail = useSelector((state) => state.task.taskDetail);
  const currentPage = useSelector((state) => state.task.currentPage);
  const pageSize = useSelector((state) => state.task.pageSize);
  const totalCount = useSelector((state) => state.task.totalCount);

  const handleSearch = (searchValues) => {
    setSearchParams(searchValues);
    dispatch(setPage(1));
  };

  const fetchTaskList = () => {
    dispatch(
      fetchTaskProducts({ index: currentPage, pageSize, ...searchParams })
    );
  };

  useEffect(() => {
    fetchTaskList();
  }, [dispatch, currentPage, pageSize, searchParams]);

  const handlePageChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  const dataSources = taskProducts.map((item, index) => {
    return {
      stt: index + 1,
      key: item.id,
      id: item.id,
      code: item.code,
      name: item.name,
      moduleName: item.moduleName,
      orderProductName: item.orderProductName,
      target: item.target,
      quantity: item.quantity,
      owe: item.owe,
      hasBreakTime: item.hasBreakTime,
      description: item.description,
      dateStart: dayjs(item.dateStart),
      dateEnd: dayjs(item.dateEnd),
      moduleId: item.moduleId,
      orderProductId: item.orderProductId,
    };
  });
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 100,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </span>
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record.id)}
            style={{ marginRight: 20 }}
          />
        </div>
      ),
    },
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mã chuyền",
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: "Tên đơn hàng",
      dataIndex: "orderProductName",
      key: "orderProductName",
    },
    {
      title: "Mục tiêu",
      dataIndex: "target",
      key: "target",
    },
    {
      title: "Sản lượng thực tế",
      dataIndex: "quantity",
      key: "quantity",
      algin: "center",
      width: 150,
    },
    {
      title: "OWE",
      dataIndex: "owe",
      key: "owe",
      render: (text) => `${text}%`,
    },
    {
      title: "Giờ nghỉ",
      dataIndex: "hasBreakTime",
      key: "hasBreakTime",
      render: (text) => (text ? "Có" : "Không"),
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ngày làm",
      dataIndex: "dateStart",
      key: "dateStart",
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Thời gian làm",
      key: "dateStartEnd",
      width: 150,
      render: (text, record) => {
        return `${record.dateStart.format(
          "HH:mm:ss"
        )} - ${record.dateEnd.format("HH:mm:ss")}`;
      },
    },
  ];

  // Add
  const handleAddModal = () => {
    setEditingRecord(null);
    setIsAddModalVisible(true);
  };

  const handleAddFormSubmit = (values) => {
    setLoading(true);
    dispatch(createTaskProduct(values))
      .unwrap()
      .then(() => {
        message.success("Tạo công việc thành công!");
        setIsAddModalVisible(false);
        fetchTaskList();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditModal = () => {
    const record = dataSources.find((item) => item.id === selectedRowKeys[0]);
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleUpdate = (values) => {
    setLoading(true);

    dispatch(updateTaskProduct({ id: editingRecord.id, data: values }))
      .unwrap()
      .then(() => {
        message.success("Cập nhật công việc thành công!");
        setIsEditModalVisible(false);
        fetchTaskList();
        setSelectedRowKeys([]);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedRowKeys([]);
  };

  //delete task
  const handleDelete = () => {
    dispatch(deleteTaskProduct(selectedRowKeys[0]))
      .unwrap()
      .then(() => {
        message.success("Xóa công việc thành công!");
        fetchTaskList();
      })
      .catch(() => {});
  };

  //reset search
  const resetSearch = () => {
    setSearchParams({
      codeSearch: "",
    });
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(setPage(1));
  };

  //reset table
  const handleResetTable = () => {
    fetchTaskList();
    setSelectedRowKeys([]);
  };

  //view detail
  const handleViewDetails = (id) => {
    dispatch(fetchTaskDetail(id)).then(() => {
      setRecordModalVisible(true);
    });
  };

  const handleDetailModalCancel = () => {
    setRecordModalVisible(false);
    setSelectedRowKeys([]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <div>
        <SearchComponent
          key={searchKey}
          onSearch={handleSearch}
          searchFields={[
            { key: "codeSearch", placeholder: "Tìm Kiếm Mã Công việc" },
          ]}
        />
      </div>
      <div style={{ flexGrow: 1, overflowY: "auto", marginTop: "5px" }}>
        <Spin spinning={loading}>
          <TableComponent
            dataSource={dataSources}
            columns={columns}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: totalCount,
              onChange: handlePageChange,
            }}
          />
        </Spin>
      </div>
      <Space>
        <Button
          style={{
            backgroundColor: "#52c41a",
            borderColor: "#52c41a",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#389e0d",
              borderColor: "#389e0d",
              color: "#fff",
            },
          }}
          onClick={handleResetTable}
        >
          Làm mới
        </Button>

        <Button type="primary" onClick={handleAddModal}>
          Thêm mới
        </Button>
        <Button
          type="default"
          disabled={selectedRowKeys.length === 0}
          onClick={handleEditModal}
        >
          Cập nhật
        </Button>
        <Popconfirm
          title="Bạn có muốn xóa công việc không?"
          onConfirm={handleDelete}
          okText="Có"
          cancelText="Không"
        >
          <Button type="primary" danger disabled={selectedRowKeys.length === 0}>
            Xóa
          </Button>
        </Popconfirm>
      </Space>
      <ViewDetail
        visible={recordModalVisible}
        onCancel={handleDetailModalCancel}
        data={taskDetail}
        taskId={selectedRowKeys[0]}
      />
      <AddForm
        visible={isAddModalVisible}
        onCreate={handleAddFormSubmit}
        onCancel={handleAddModalCancel}
      />
      <EditForm
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        onUpdate={handleUpdate}
        initialValues={editingRecord}
      />
    </div>
  );
};

export default QuanLyCongViec;
