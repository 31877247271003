export const constants = {
  // menu 1
  DASHBOARD: {
    label: "Biểu đồ",
    key: "DASHBOARD",
  },
  DASHBOARD_ADMIN: {
    label: "Biểu đồ tổng",
    key: "DASHBOARD_ADMIN",
  },
  DASHBOARD_TV: {
    label: "Biểu đồ cho TV",
    key: "DASHBOARD_TV",
  },
  DASHBOARD_CHUYEN: {
    label: "Biểu đồ Chuyền",
    key: "DASHBOARD_CHUYEN",
  },
  DASHBOARD_LOI: {
    label: "Biểu đồ Lỗi",
    key: "DASHBOARD_LOI",
  },
  SYSTEM_MANAGEMENT: {
    label: "Quản trị hệ thống",
    key: "SYSTEM_MANAGEMENT",
  },
  PRODUCT_MANAGEMENT: {
    label: "Quản lý hệ thống",
    key: "PRODUCT_MANAGEMENT",
  },
  PRODUCT_LIST: {
    label: "Quản lý sản phẩm",
    key: "PRODUCT_LIST",
  },
  CATEGORY_LIST: {
    label: "Quản lý danh mục",
    key: "CATEGORY_LIST",
  },
  PRODUCT_ERROR_CODE: {
    label: "Quản lý mã lỗi",
    key: "PRODUCT_ERROR_CODE",
  },
  ERROR_LIST: {
    label: "Quản lý sản phẩm lỗi",
    key: "ERROR_LIST",
  },
  WORK_MANAGEMENT: {
    label: "Quản lý công việc",
    key: "WORK_MANAGEMENT",
  },
  PASS_MANAGEMENT: {
    label: "Quản lý chuyền",
    key: "PASS_MANAGEMENT",
  },
  STORAGE_MANAGEMENT: {
    label: "Quản lý kho",
    key: "STORAGE_MANAGEMENT",
  },
  STORE_MANAGEMENTv2: {
    label: "Quản lý kho v2",
    key: "STORE_MANAGEMENTv2",
  },
  USER_MANAGEMENT: {
    label: "Quản lý người dùng",
    key: "USER_MANAGEMENT",
  },
  PRODUCT_ORDER: {
    label: "Quản lý đơn hàng",
    key: "PRODUCT_ORDER",
  },
  QAQC_INFO: {
    label: "Thông tin QA/QC",
    key: "QAQC_INFO",
  },
};
