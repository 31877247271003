import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequestParams,
  postRequest,
  putRequest,
} from "../../../services/api";

//fetching floor products

export const fetchFloorProducts = createAsyncThunk(
  "floorProducts/fetchFloorProducts",
  async ({ index, pageSize, codeSearch, idLineProduct }) => {
    const params = { index, pageSize, codeSearch, idLineProduct };
    const response = await getRequestParams(
      "/warehouse-management/floorproducts",
      params
    );
    return response.data;
  }
);

//post floor product
export const postFloorProduct = createAsyncThunk(
  "floorProducts/postFloorProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequest(
        "/warehouse-management/floorproducts",
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update floor product
export const updateFloorProduct = createAsyncThunk(
  "floorProducts/updateFloorProduct",
  async ({ idFloorProduct, data }, { rejectWithValue }) => {
    try {
      const response = await putRequest(
        `/warehouse-management/floorproducts/${idFloorProduct}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete floor product

export const deleteFloorProduct = createAsyncThunk(
  "floorProducts/deleteFloorProduct",
  async (idFloorProduct, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(
        `/warehouse-management/floorproducts/${idFloorProduct}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const floorProduct = createSlice({
  name: "floorProducts",
  initialState: {
    floorProducts: [],
    loading: false,
    error: null,
    currentPage: 1,
    pageSize: 15,
    totalCount: 0,
  },
  reducers: {
    setPageFloor: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSizeFoor: (state, action) => {
      state.pageSize = action.payload;
    },
    clearFloorProducts: (state) => {
      state.floorProducts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFloorProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFloorProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.floorProducts = action.payload.data.items;
        state.totalCount = action.payload.data.totalCount;
        state.totalPages = action.payload.data.totalPages;
      })
      .addCase(fetchFloorProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //post floor product
      .addCase(postFloorProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(postFloorProduct.fulfilled, (state, action) => {
        state.floorProducts.push(action.payload);
      })
      .addCase(postFloorProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //update floor product
      .addCase(updateFloorProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFloorProduct.fulfilled, (state, action) => {
        const index = state.floorProducts.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.floorProducts[index] = action.payload;
        }
      })
      .addCase(updateFloorProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //delete floor product
      .addCase(deleteFloorProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFloorProduct.fulfilled, (state, action) => {
        state.floorProducts = state.floorProducts.filter(
          (product) => product.id !== action.payload
        );
      })
      .addCase(deleteFloorProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setPageFloor, setPageSizeFoor, clearFloorProducts } =
  floorProduct.actions;

export default floorProduct.reducer;
