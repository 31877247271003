import { ColorPicker, Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../redux/Slice/productSlice/productSlice";

const FormComponent = ({ visible, onCreate, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.quanLySanPham.categories);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [visible, initialValues, form]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <Modal
      centered
      visible={visible}
      title={initialValues ? "Cập nhật sản phẩm" : "Thêm mới sản phẩm"}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name="code"
          label="Mã sản phẩm"
          rules={[{ required: true, message: "Vui lòng nhập mã sản phẩm!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên sản phẩm"
          rules={[{ required: true, message: "Vui lòng nhập tên sản phẩm!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả"
          rules={[{ required: true, message: "Vui lòng nhập mô tả!" }]}
          labelAlign="right"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sam"
          label="Sam"
          rules={[{ required: true, message: "Vui lòng nhập số lượng Sam!" }]}
          labelAlign="right"
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Danh mục"
          rules={[{ required: true, message: "Vui lòng nhập danh mục!" }]}
          labelAlign="right"
        >
          <Select
            showSearch
            placeholder="Chọn danh mục"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={categories.map((cat) => {
              return {
                label: `${cat.name} `,
                value: cat.id,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormComponent;
